import { VArmorEvents } from './vArmorEvents'

class VArmorContract {
  constructor(service) {
    this.service = service
  }

  shouldDispatch(payload) {
    // change IbcoEvents to the contracts Events (ie: PlanManagerEvents)
    return this.service.shouldDispatch(VArmorEvents, payload)
  }

  async dispatch(payload) {
    switch (payload.type) {
      case VArmorEvents.GetConversions:
        await this.getConversions()
        break
      case VArmorEvents.Deposit:
        await this.deposit(payload)
        break
      case VArmorEvents.GetBalance:
        await this.getBalance(payload)
        break
      case VArmorEvents.GetManagedAssets:
        await this.getManagedAssets(payload)
        break
      case VArmorEvents.FinalizeWithdraw:
        await this.finalizeWithdraw()
        break
      case VArmorEvents.RequestWithdrawal:
        await this.requestWithdrawal(payload)
        break
      case VArmorEvents.GetWithdrawRequests:
        await this.getWithdrawRequests()
        break
      case VArmorEvents.GetDelegate:
        await this.getDelegate()
        break
      case VArmorEvents.RequestUndelegate:
        await this.requestUndelegate()
        break
      default:
    }
  }

  async getConversions() {
    const account = this.service.getAccount()

    let response = await this._getConversions(account).catch((err) => {
      this.service.emitError(err)
      return '0'
    })

    this.service.setStore({
      [`VArmor_Conversions`]: response,
    })
    this.service.emit(VArmorEvents.ConversionsReturned, response)

    return response
  }
  async _getConversions(account) {
    const web3 = await this.service.getWeb3()
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.service.makeContract(
          web3,
          this.service.config.vArmor.abi,
          this.service.config.vArmor.address
        )

        let atv = await contract.methods
          .armorToVArmor(web3.utils.toWei('1'))
          .call({ from: account ? account.address : '' })

        let vta = await contract.methods
          .vArmorToArmor(web3.utils.toWei('1'))
          .call({ from: account ? account.address : '' })

        resolve({
          armorToVArmor: web3.utils.fromWei(atv, 'ether'),
          vArmorToArmor: web3.utils.fromWei(vta, 'ether'),
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  async deposit(payload) {
    try {
      const account = this.service.getAccount()
      const web3 = await this.service.getWeb3()
      if (!account || !account.address) {
        return false
      }
      const { amount } = payload.content

      let amountInWei = web3.utils.toWei(amount.toString(), 'ether')
      let balance = await this.service.contracts['armorToken']
        .getBalance({
          content: {
            address: account.address,
          },
        })
        .catch((err) => console.error(err))

      let balanceInWei = web3.utils.toWei(balance.toString(), 'ether')
      if (parseFloat(amountInWei) > parseFloat(balanceInWei)) {
        amountInWei = balanceInWei
      }

      let allowance = await this.service.contracts['armorToken']
        .getAllowance(account)
        .catch((err) => {
          this.service.emitError(err)
          throw err
        })

      if (parseInt(allowance) < parseInt(amountInWei)) {
        await this.service.contracts['armorToken'].approve({
          content: {
            toAddress: this.service.config.vArmor.address,
            amount: amountInWei,
          },
        })
      }

      this.service.emit(VArmorEvents.ApprovalCompleted, [])

      const _data = await this._handleDeposit(account, amountInWei)

      if (typeof _data === 'object' && _data !== null) {
        this.service.emit(VArmorEvents.DepositCompleted, _data)
      } else {
        this.service.emitError('Something went wrong!')
      }
    } catch (e) {
      this.service.emitError(e)
    }
  }
  async _handleDeposit(account, amount) {
    return new Promise(async (resolve, reject) => {
      try {
        const web3 = await this.service.getWeb3()
        const contract = this.service.makeContract(
          web3,
          this.service.config.vArmor.abi,
          this.service.config.vArmor.address
        )

        contract.methods
          .deposit(amount)
          .send({
            from: account.address,
          })
          .once('transactionHash', (hash) =>
            this.service.handleTransactionHash(hash)
          )
          .once('receipt', (receipt) => this.service.handleReceipt(receipt))
          .on('confirmation', (confirmationNumber, receipt) =>
            this.service.handleConfirmation(confirmationNumber, receipt)
          )
          .on('error', (error) => {
            this.service.handleError(error)
            reject(error)
          })
          .then((deposit) => {
            resolve(deposit)
          })
      } catch (e) {
        return reject(e)
      }
    })
  }

  async getManagedAssets(payload) {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return 0
    }
    const { address } = payload.content

    let response = await this._handleGetManagedAssets(account, address).catch(
      (err) => {
        this.service.emitError(err)
        return 0
      }
    )

    this.service.setStore({ VArmorEvents_ManagedAssets: response })
    this.service.emit(VArmorEvents.ManagedAssetsReturned)

    return response
  }
  async _handleGetManagedAssets(account, address) {
    const web3 = await this.service.getWeb3()
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.service.makeContract(
          web3,
          this.service.config.vArmor.abi,
          this.service.config.vArmor.address
        )

        let balance = await contract.methods
          .balanceOf(address)
          .call({ from: account.address })

        resolve(web3.utils.fromWei(balance, 'ether'))
      } catch (e) {
        reject(e)
      }
    })
  }

  async getBalance() {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return 0
    }

    let response = await this._handleGetBalance(account).catch((err) => {
      this.service.emitError(err)
      return 0
    })
    this.service.setStore({ VArmorEvents_Balance: response })
    this.service.emit(VArmorEvents.BalanceReturned)

    return response
  }
  async _handleGetBalance(account) {
    const web3 = await this.service.getWeb3()
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.service.makeContract(
          web3,
          this.service.config.vArmor.abi,
          this.service.config.vArmor.address
        )

        let balance = await contract.methods
          .balanceOf(account.address)
          .call({ from: account.address })

        resolve(web3.utils.fromWei(balance, 'ether'))
      } catch (e) {
        reject(e)
      }
    })
  }

  async getDelegate() {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return 0
    }

    let response = await this._handleGetDelegate(account).catch((err) => {
      this.service.emitError(err)
      return 0
    })

    this.service.setStore({
      VArmorEvents_delegate:
        response !== '0x0000000000000000000000000000000000000000',
    })
    this.service.emit(VArmorEvents.DelegateReturned)

    return response
  }

  async _handleGetDelegate(account) {
    const web3 = await this.service.getWeb3()
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.service.makeContract(
          web3,
          this.service.config.vArmor.abi,
          this.service.config.vArmor.address
        )

        let address = await contract.methods
          .delegates(account.address)
          .call({ from: account.address })

        resolve(address)
      } catch (e) {
        reject(e)
      }
    })
  }

  async requestUndelegate() {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return false
    }

    await this._requestUndelegate(account)
      .then(() => this.service.emit(VArmorEvents.RequestUndelegateCompleted))
      .catch((err) => this.service.emitError(err))
  }

  async _requestUndelegate(account) {
    return new Promise(async (resolve, reject) => {
      try {
        const web3 = await this.service.getWeb3()
        const contract = this.service.makeContract(
          web3,
          this.service.config.vArmor.abi,
          this.service.config.vArmor.address
        )

        await contract.methods
          .delegate('0x0000000000000000000000000000000000000000')
          .send({ from: account.address })
          .once('transactionHash', (hash) =>
            this.service.handleTransactionHash(hash)
          )
          .once('receipt', (receipt) => this.service.handleReceipt(receipt))
          .on('confirmation', (confirmationNumber, receipt) =>
            this.service.handleConfirmation(confirmationNumber, receipt)
          )
          .on('error', (error) => {
            this.service.handleError(error)
            reject(error)
          })
          .then((response) => {
            resolve(response)
          })
      } catch (e) {
        return reject(e)
      }
    })
  }

  async requestWithdrawal(payload) {
    const account = this.service.getAccount()
    const web3 = await this.service.getWeb3()
    if (!account || !account.address) {
      return false
    }
    const { amount } = payload.content

    let amountInWei = web3.utils.toWei(amount.toString(), 'ether')
    let balance = await this.getBalance({
      content: {
        address: account.address,
      },
    }).catch((err) => console.error(err))

    let balanceInWei = web3.utils.toWei(balance.toString(), 'ether')

    if (parseFloat(amountInWei) > parseFloat(balanceInWei)) {
      amountInWei = balanceInWei
    }

    await this._requestWithdrawal(account, amountInWei)
      .then((data) =>
        this.service.emit(VArmorEvents.RequestWithdrawalCompleted, data)
      )
      .catch((err) => this.service.emitError(err))
  }
  async _requestWithdrawal(account, amount) {
    return new Promise(async (resolve, reject) => {
      try {
        const web3 = await this.service.getWeb3()
        const contract = this.service.makeContract(
          web3,
          this.service.config.vArmor.abi,
          this.service.config.vArmor.address
        )

        await contract.methods
          .requestWithdrawal(amount)
          .send({
            from: account.address,
          })
          .once('transactionHash', (hash) =>
            this.service.handleTransactionHash(hash)
          )
          .once('receipt', (receipt) => this.service.handleReceipt(receipt))
          .on('confirmation', (confirmationNumber, receipt) =>
            this.service.handleConfirmation(confirmationNumber, receipt)
          )
          .on('error', (error) => {
            this.service.handleError(error)
            reject(error)
          })
          .then((response) => {
            resolve(response)
          })
      } catch (e) {
        return reject(e)
      }
    })
  }

  async finalizeWithdraw() {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return false
    }

    await this._handleFinalizeWithdraw(account)
      .then((data) =>
        this.service.emit(VArmorEvents.FinalizeWithdrawCompleted, data)
      )
      .catch((err) => this.service.emitError(err))
  }
  async _handleFinalizeWithdraw(account) {
    return new Promise(async (resolve, reject) => {
      try {
        const web3 = await this.service.getWeb3()
        const contract = this.service.makeContract(
          web3,
          this.service.config.vArmor.abi,
          this.service.config.vArmor.address
        )

        await contract.methods
          .finalizeWithdrawal()
          .send({
            from: account.address,
          })
          .once('transactionHash', (hash) =>
            this.service.handleTransactionHash(hash)
          )
          .once('receipt', (receipt) => this.service.handleReceipt(receipt))
          .on('confirmation', (confirmationNumber, receipt) =>
            this.service.handleConfirmation(confirmationNumber, receipt)
          )
          .on('error', (error) => {
            this.service.handleError(error)
            reject(error)
          })
          .then((response) => {
            resolve(response)
          })
      } catch (e) {
        return reject(e)
      }
    })
  }

  async getWithdrawRequests() {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return '0'
    }

    let response = await this._getWithdrawRequests(account).catch((err) => {
      this.service.emitError(err)
      return '0'
    })

    this.service.setStore({
      [`VArmor_WithdrawRequests`]: response,
    })
    this.service.emit(VArmorEvents.WithdrawRequestsReturned, response)

    return response
  }
  async _getWithdrawRequests(account) {
    const web3 = await this.service.getWeb3()
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.service.makeContract(
          web3,
          this.service.config.vArmor.abi,
          this.service.config.vArmor.address
        )

        let delay = await contract.methods
          .withdrawDelay()
          .call({ from: account.address })

        let request = await contract.methods
          .withdrawRequests(account.address)
          .call({ from: account.address })

        resolve({
          ...request,
          delay: delay,
        })
      } catch (e) {
        reject(e)
      }
    })
  }
}

export default VArmorContract
