import React from 'react'
import { ListBalanceButton, BalanceListItem, ListBalance } from './styled'

const ArShieldBalance = ({ token, balances }) => {
  const getShieldBalance = () => {
    if (balances) {
      let i = balances.tokens.findIndex(
        (b) => b.toLowerCase() === token.shieldAddress.toLowerCase()
      )
      if (i >= 0) {
        return balances.balances[i]
      }
    }

    return 0
  }

  return (
    <BalanceListItem>
      <ListBalance>
        {getShieldBalance()} {token.symbol}
      </ListBalance>
      <ListBalanceButton disabled={true} onClick={() => {}}>
        WITHDRAW
      </ListBalanceButton>
    </BalanceListItem>
  )
}

export default ArShieldBalance
