import React from 'react'

const MCircleIcon = ({ color }) => {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
      fill={color}
    >
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path
            fill={color}
            d="M4565.1,4991.8c-1668.4-150.2-3166.9-1162.4-3924-2648.9C20.4,1121.2-68.6-250.8,386.1-1541.6c92.9-259,310.4-709.7,458.6-944.9C1503-3534.2,2544.8-4305.2,3734.8-4625.5c1128.8-302.5,2326.7-187.8,3392.2,326.2c531.8,255,907.4,521.9,1332.4,948.9c427,425,693.8,800.6,948.9,1332.4c575.3,1192,648.4,2518.5,205.6,3777.7c-170,486.3-506.1,1071.4-856,1492.5C7747.8,4469.9,6146.5,5134.1,4565.1,4991.8z M5672.1,4408.6c919.2-144.3,1743.6-563.4,2403.8-1223.7c666.2-668.2,1079.4-1484.6,1227.6-2433.5c47.4-300.5,47.4-984.5,0-1284.9c-148.3-948.9-561.4-1765.3-1227.6-2433.5c-668.2-666.2-1484.6-1079.4-2433.5-1227.6c-300.5-47.4-984.5-47.4-1284.9,0c-948.9,148.3-1765.3,561.4-2433.5,1227.6C1259.8-2300.7,844.7-1482.3,696.4-541.3C647-231,647,443.1,696.4,759.4c191.8,1229.6,861.9,2281.3,1905.7,2983c539.7,365.7,1251.3,618.8,1943.2,693.9C4786.5,4464,5427,4446.2,5672.1,4408.6z"
          />
          <path
            fill={color}
            d="M2712.8,2334.9c-35.6-15.8-83-59.3-104.8-94.9c-39.5-65.2-39.5-94.9-39.5-2172.5c0-1773.2,4-2111.2,27.7-2142.9c39.5-53.4,401.3-69.2,512-23.7l73.1,31.6l4,1957.1l5.9,1957.1l751.2-1959l751.2-1959l69.2-29.7c89-37.6,403.3-25.7,442.8,17.8c15.8,15.8,369.7,877.7,788.8,1915.5c419.1,1037.8,776.9,1919.5,792.7,1957.1c27.7,63.3,29.6-114.6,31.6-1880c0-1648.7,3.9-1953.1,27.7-1986.7c23.7-31.6,57.3-37.5,233.3-43.5c154.2-5.9,225.4,0,278.7,23.7l73.1,29.6V53.7c0,2069.7-2,2123.1-39.5,2184.4c-63.3,106.7-124.6,124.5-411.2,124.5c-140.4,0-282.7-5.9-316.3-11.9c-81.1-15.8-207.6-126.5-253-219.4c-19.8-41.5-340-812.5-711.7-1715.9c-369.7-901.4-682-1638.8-691.9-1636.8c-7.9,2-310.4,743.3-670.1,1650.6c-359.8,905.4-676.1,1686.2-701.8,1733.7c-35.6,65.2-77.1,102.8-148.3,138.4c-90.9,47.4-118.6,51.4-405.2,55.4C2849.2,2360.6,2760.2,2354.7,2712.8,2334.9z"
          />
        </g>
      </g>
    </svg>
  )
}

export default MCircleIcon
