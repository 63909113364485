import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import { CoverContainer } from '../../components/common/CoverContainer'
import { InputField } from '../../components/common/Input'
import { ButtonStyled } from '../../components/common/Button'
import { BorderGradientContainer } from '../../components/common/BorderGradientContainer'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  left: 0;
  top: 0;
  margin-left: -235px;

  @media screen and (max-width: 1150px) {
    margin-left: 0;
  }
`
export const Container = styled.div`
  flex: 1;
  max-width: 725px;
  width: 100%;
  padding: 16px 15px 48px;
  margin: 0 auto;

  @media screen and (max-width: 1500px) {
    padding-top: 50px;
  }
  @media screen and (max-width: 1300px) {
    padding-top: 80px;
  }
`
export const Title = styled.h2`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: ${(p) => p.theme.colors.defaultLightActive};
`
export const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${(p) => p.theme.colors.secondary};
  margin-top: 38px;
  @media screen and (max-width: 548px) {
    margin-top: 30px;
  }
`
export const Box = styled(CoverContainer)`
  border: 1px solid ${(p) => p.theme.colors.primaryLightTrue};
  margin-top: 20px;
  @media screen and (max-width: 548px) {
    margin-top: 20px;
  }
`
export const ModalTitle = styled.h4`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: ${(p) => p.theme.colors.primaryDefault};
`
export const SubTitle = styled.h4`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${(p) => p.theme.colors.primaryDefault};
`
export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    margin-top: 0;
    flex-direction: column;
  }
`
export const Input = styled(InputField)`
  max-width: ${(p) => (p.address ? '325px' : '120px')};
  width: 100%;
  max-height: 28px;
  & .MuiOutlinedInput-input {
    padding: 7px 14px;
  }
  & .MuiOutlinedInput-root {
    box-shadow: ${(p) =>
      p.error ? `0px 0px 14px ${p.theme.colors.error}` : 'none'};
    background: ${(p) => p.theme.colors._default};
    border: ${(p) =>
      p.error ? `1px solid ${p.theme.colors.error}` : '1px solid transparent'};
  }
  & .MuiFormHelperText-root {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: ${(p) => p.theme.colors.error};
    margin-top: 5px;
  }

  @media screen and (max-width: 600px) {
    margin-top: ${(p) => (p.address ? '15px' : '0')};
    max-width: 325px;
    margin-right: ${(p) => (p.address ? '0' : '15px')};
  }
`
export const InputInfo = styled(InputAdornment)`
  & p {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;
    color: ${(p) => p.theme.colors.primary};
  }
`
export const ActiveBtn = styled(ButtonStyled)`
  padding: 7px;
  height: unset;
  box-shadow: none;
  max-width: fit-content;
  width: 100%;
  display: block;
  &.MuiButton-contained.Mui-disabled {
    background: ${(p) => p.theme.colors.disabledText};
  }

  @media screen and (max-width: 600px) {
    margin-top: ${(p) => (p.addbutton ? '0' : '20px')};
  }
`
export const ActiveBtnText = styled.h4`
  color: ${(p) => p.theme.colors.secondary};
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
`
export const ContentContainer = styled.div`
  padding: 21px 15px 15px;
`
export const MainInfo = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  @media screen and (max-width: 600px) {
    padding-bottom: 0;
  }
`
export const FooterInfo = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.disabledText};
  & svg {
    max-width: 24px;
    height: 24px;
    width: 100%;
    margin-right: 10px;
  }
`
export const AnalyzingBox = styled(BorderGradientContainer)`
  & svg {
    max-width: 24px;
    width: 100%;
    height: auto;
    min-width: 24px;
  }
  position: relative;
  overflow: hidden;

  &::after {
    -webkit-animation: analyzing 2s ease-in-out infinite both;
    animation: analyzing 2s ease-in-out infinite both;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @-webkit-keyframes analyzing {
      0% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 0;
      }
      50% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 1;
      }
      100% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 0;
      }
    }
    @keyframes analyzing {
      0% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 0;
      }
      50% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 1;
      }
      100% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 0;
      }
    }
  }
`
export const AnalizingText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.secondary};
  margin-left: 8px;
`
export const CenterContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
export const TableContainerStyled = styled(TableContainer)`
  overflow-x: unset;
  border-radius: unset;
  box-shadow: none;
  background: transparent;
  & td {
    border: none;
  }
  & th {
    border: none;
  }

  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }
`
export const TableStyledWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
export const TableStyled = styled(Table)`
  @media screen and (max-width: 600px) {
    width: 500px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media screen and (max-width: 448px) {
    width: 470px;
  }
`
export const TableTitle = styled(TableCell)`
  font-size: 14px;
  line-height: 19px;
  color: ${(p) => p.theme.colors.secondary};
  padding: 15px;
  font-family: 'Open Sans', sans-serif;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 17px;
    padding: 8px 10px;
  }
`
export const Row = styled(TableRow)`
  min-height: 51px;
  background: ${(p) =>
    p.checked ? p.theme.colors.selectedCoveredRow : 'transparent'};

  @media screen and (max-width: 600px) {
    min-height: unset;
  }
`
export const Cell = styled(TableCell)`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  padding: 8px 15px;
  color: ${(p) =>
    p.address ? p.theme.colors.active : p.theme.colors.secondary};
  cursor: ${(p) => (p.address ? 'pointer' : 'text')};

  @media screen and (max-width: 600px) {
    padding: 6px 14px;
  }
  @media screen and (max-width: 448px) {
    padding: 0 14px;
  }
`
export const TableWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 20px auto 0;
`
export const CoverButton = styled(Button)`
  border: 1px solid
    ${(p) => (p.delete ? p.theme.colors.error : p.theme.colors.buttonActiveBg)};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px;
  min-width: unset;
  margin-left: ${(p) => (p.delete ? '10px' : '0')};
  & svg {
    width: 15px;
    height: 15px;
  }
`
export const StepFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  @media screen and (max-width: 600px) {
    padding-bottom: 15px;
  }
`
export const DropdownStyled = styled(TextField)`
  max-width: ${(p) => (p.large ? '160px' : '125px')};
  margin: 20px;
  height: 36px;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  border-radius: 6px;
  background: ${(p) => p.theme.colors.defaultArrow};
  &:first-of-type {
    margin-left: 0;
  }
  /* &:last-of-type {
    margin-right: 0;
  } */

  & .MuiSelect-select:focus {
    border-radius: 6px;
  }
  & .MuiInput-underline {
    &:before {
      display: none;
      height: 0px;
      border-bottom: none;
    }
    &:after {
      display: none;
      height: 0px;
      border-bottom: none;
    }
    &:hover:not($disabled):before {
      display: none;
      height: 0px;
      border-bottom: none;
    }
  }
  & .MuiSelect-select {
    height: 36px;
    padding: 0 24px 0 10px;
    display: flex;
    align-items: center;
  }
  & .MuiInput-root {
    display: flex;
    align-items: center;
    height: 100%;
  }
  & .MuiSvgIcon-root {
    right: 7px;
  }
  & .MuiSelect-icon {
    color: ${(p) => p.theme.colors.primaryDefault};
  }

  @media screen and (max-width: 548px) {
    &:first-of-type {
      margin-left: 10px;
    }
    &:last-of-type {
      margin-right: 10px;
    }
    width: 220px;
    max-width: unset;
  }
`
export const RightSpace = styled.div`
  margin-right: 15px;
`
export const TopSpace = styled.div`
  margin-top: 15px;
`
