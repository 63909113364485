import React from 'react'

const GithubIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9906 1.78821C6.19453 1.78587 1.5 6.47805 1.5 12.2695C1.5 16.8491 4.43672 20.7421 8.52656 22.1718C9.07734 22.3101 8.99297 21.9187 8.99297 21.6515V19.8351C5.8125 20.2077 5.68359 18.1031 5.47031 17.7515C5.03906 17.0156 4.01953 16.8281 4.32422 16.4765C5.04844 16.1038 5.78672 16.5702 6.64219 17.8335C7.26094 18.7499 8.46797 18.5952 9.07969 18.4429C9.21328 17.8921 9.49922 17.3999 9.89297 17.0179C6.59766 16.4273 5.22422 14.4163 5.22422 12.0257C5.22422 10.8656 5.60625 9.79915 6.35625 8.93899C5.87812 7.52102 6.40078 6.30696 6.47109 6.12649C7.83281 6.00461 9.24844 7.10149 9.35859 7.18821C10.132 6.97961 11.0156 6.86946 12.0047 6.86946C12.9984 6.86946 13.8844 6.9843 14.6648 7.19524C14.9297 6.99368 16.2422 6.05149 17.5078 6.16633C17.5758 6.3468 18.0867 7.53274 17.6367 8.93196C18.3961 9.79446 18.7828 10.8702 18.7828 12.0327C18.7828 14.4281 17.4 16.4413 14.0953 17.0226C14.3784 17.3009 14.6031 17.6329 14.7564 17.9991C14.9098 18.3653 14.9886 18.7584 14.9883 19.1554V21.7921C15.007 22.003 14.9883 22.2116 15.3398 22.2116C19.4906 20.8124 22.4789 16.8913 22.4789 12.2718C22.4789 6.47805 17.782 1.78821 11.9906 1.78821Z"
        fill={color}
      />
    </svg>
  )
}

export default GithubIcon
