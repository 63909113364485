import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { ModalTitle } from './styled'
import { SelectMenu, SelectName } from '../rewards/styled'
import { DropdownStyled, CenterContent } from './styled'

const Step1 = ({ t, currentStep, handleChange }) => {
  const [exchange, setExchange] = useState('select')

  const handleChangeExchange = (e) => {
    const val = e.target.value
    setExchange(val)
    handleChange({ name: 'protocol', value: val === 'select' ? '' : val })
  }

  return currentStep === 1 ? (
    <>
      <ModalTitle bold>{t('ProofOfLoss.Step.1')}</ModalTitle>
      <CenterContent>
        <DropdownStyled
          select
          large="true"
          name="exchange"
          value={exchange}
          onChange={handleChangeExchange}
          SelectProps={{ native: false }}
        >
          <SelectMenu value="select">
            <SelectName>Select protocol</SelectName>
          </SelectMenu>
          <SelectMenu value="cream">
            <SelectName>C.R.E.A.M. Oct. 27th</SelectName>
          </SelectMenu>
        </DropdownStyled>
      </CenterContent>
    </>
  ) : null
}

export default withTranslation()(Step1)
