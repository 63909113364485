import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Store from '../../../stores/store'
import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  ERROR,
} from '../../../constants'
import {
  commas,
  countDecimals,
  removeEmitterListeners,
  turnOnEmitterListeners,
  twoDigitFormatter,
  disableTooltip,
} from '../../../helpers'
import Tooltip from '@material-ui/core/Tooltip'
import { TooltipSpan } from '../../../components/arNxm/styled'
import AboutInfoIcon from '../../../components/icons/AboutInfoIcon'
import { PanelBox, PanelContainer } from './PanelBox'
import {
  ActionTitle,
  Button,
  ButtonText,
  ButtonWithTooltip,
  InputTitle,
  MaxButton,
  TextField,
  Value,
} from '../../arNxmVault/components/arNxmWrapAndStake/styled'
import ActionModal from '../../../components/common/actionModal/ActionModal'
import { VArmorEvents } from '../../../stores/contracts/vArmor/vArmorEvents'
import StickyModal from '../../../components/common/modal/StickyModal'
import { ActionContainer } from '../../../components/common/actionModal/styled'
import UndelegateModal from './UndelegateModal'
import WithdrawConfirmationModal from './WithdrawConfirmationModal'
import { SubTitle } from '../../../components/common/SubTitle'
import moment from 'moment'
import { fromWei } from 'web3-utils'

const store = Store.store
const emitter = Store.emitter
const dispatcher = Store.dispatcher

const SwapVArmorToArmor = ({
  theme,
  network,
  t,
  vArmorBalance,
  conversions,
  withdrawRequest,
  vArmorDelegate,
}) => {
  const { colors } = theme
  const [isLoading, setIsLoading] = useState(false)
  const [account, setAccount] = useState(null)
  const [amount, setAmount] = useState('')
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isWithdrawOptionsModalOpen, setIsWithdrawOptionsModalOpen] =
    useState(false)
  const [isUndelegateModalOpen, setIsUndelegateModalOpen] = useState(false)
  const [isDelegate = vArmorDelegate, setIsDelegate] = useState()

  useEffect(() => {
    const _account = store.getStore('account')
    setAccount(_account)

    const connectionConnected = async () => {
      const _account = store.getStore('account')
      setAccount(_account)
      if (_account && _account.address) {
      }
    }

    const connectionDisconnected = () => setAccount(null)

    const errorReturned = () => {
      setIsLoading(false)
      setIsModalOpened(false)
    }

    let events = [
      [ERROR, errorReturned],
      [CONNECTION_CONNECTED, connectionConnected],
      [CONNECTION_DISCONNECTED, connectionDisconnected],
      [VArmorEvents.RequestWithdrawalCompleted, requestWithdrawalCompleted],
      [VArmorEvents.FinalizeWithdrawCompleted, finalizeWithdrawCompleted],
      [VArmorEvents.RequestUndelegateCompleted, requestUndelegateCompleted],
    ]
    turnOnEmitterListeners(emitter, events)

    return () => {
      removeEmitterListeners(emitter, events)
    }
  }, [network, account])

  const handleChangeAmount = (e) => {
    setAmount(e.target.value)
  }

  const isReadyToWithdraw = () => {
    return typeof withdrawRequest === 'object' && withdrawRequest.time !== '0'
  }

  const requestWithdrawalCompleted = () => {
    setAmount('0')
    setIsLoading(false)
    setIsModalOpened(false)
  }
  const finalizeWithdrawCompleted = () => {
    setAmount('0')
    setIsLoading(false)
  }

  const onRequestWithdrawal = () => {
    setIsWithdrawOptionsModalOpen(false)
    setIsLoading(true)

    dispatcher.dispatch({
      type: VArmorEvents.RequestWithdrawal,
      content: {
        amount: amount.toString(),
      },
    })
  }

  const onUndelegate = () => {
    setIsUndelegateModalOpen(false)
    setIsModalOpened(true)
    dispatcher.dispatch({
      type: VArmorEvents.RequestUndelegate,
      content: {},
    })
  }

  const requestUndelegateCompleted = () => {
    setIsDelegate(false)
    setIsModalOpened(false)
    setIsWithdrawOptionsModalOpen(true)
  }

  const onFinalizeWithdraw = () => {
    setIsWithdrawOptionsModalOpen(false)
    setIsLoading(true)

    dispatcher.dispatch({
      type: VArmorEvents.FinalizeWithdraw,
      content: {
        amount: amount.toString(),
      },
    })
  }

  const openWithdrawModal = () => {
    if (parseFloat(vArmorBalance) < parseFloat(amount)) {
      setAmount(vArmorBalance)
    }

    if (isDelegate) {
      setIsUndelegateModalOpen(true)
    } else {
      setIsWithdrawOptionsModalOpen(true)
    }
  }

  const handleMaxClick = () => {
    if (
      vArmorBalance.toString() === 'false' ||
      vArmorBalance.toString() === '0'
    ) {
      return
    }

    let _value = parseFloat(vArmorBalance)
    if (countDecimals(_value) >= 5) {
      _value -= 0.000042
    }

    setAmount(_value.toString())
  }

  const renderUnstakeHelp = () => {
    return (
      <div>
        Enter the amount of vArmor tokens you want to unstake and click the blue
        button. Once the transaction is confirmed it will take 7 days before you
        can actually withdraw your Armor.
      </div>
    )
  }

  const endTime = moment
    .unix(parseInt(withdrawRequest.time) + parseInt(withdrawRequest.delay))
    .utc()
  const duration = moment.duration(endTime.diff(moment.utc()))
  const hours = parseInt(duration.asHours())
  const minutes = parseInt(duration.asMinutes()) % 60
  const readyWithdraw = endTime < moment.utc()

  if (isReadyToWithdraw()) {
    const vArmor = fromWei(withdrawRequest.amount)
    return (
      <>
        <PanelContainer>
          <PanelBox>
            <ActionTitle>
              <InputTitle>
                {readyWithdraw ? (
                  <span style={{ color: 'white' }}>
                    Your requested Armor is now ready to be withdrawn, please
                    use the button below to finalize the transaction.
                  </span>
                ) : (
                  <span style={{ color: 'white' }}>
                    {`Your pending withdrawal of ${commas(2).format(
                      vArmor
                    )} vArmor (${twoDigitFormatter.format(
                      vArmor * conversions.vArmorToArmor
                    )} Armor), which will be ready in`}
                    <b>{hours}</b> hrs and<b>{minutes}</b> minutes (
                    {endTime.format('MMM Do - HH:mm [UTC]')}).
                  </span>
                )}
              </InputTitle>
            </ActionTitle>
          </PanelBox>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading || !readyWithdraw}
            onClick={() => onFinalizeWithdraw()}
          >
            <ButtonText>FINALIZE WITHDRAWAL</ButtonText>
          </Button>
        </PanelContainer>
      </>
    )
  }

  return (
    <>
      <PanelContainer>
        <SubTitle placement="left" bottom="15">
          {t('Varmor.Unstake')}
          <Tooltip
            arrow
            placement="top"
            enterTouchDelay={50}
            title={renderUnstakeHelp()}
          >
            <TooltipSpan ml>
              <AboutInfoIcon color={colors.defaultLightActive} />
            </TooltipSpan>
          </Tooltip>
        </SubTitle>
        <PanelBox>
          <ActionTitle>
            <InputTitle>
              <span> {t('ArNxm.Balance')}: </span>
              <b>{commas(2).format(vArmorBalance)} vArmor</b>
            </InputTitle>
            <MaxButton onClick={handleMaxClick}>MAX</MaxButton>
          </ActionTitle>
          <TextField
            fullWidth
            placeholder={'0'}
            variant="outlined"
            type="text"
            onChange={handleChangeAmount}
            value={amount}
            disabled={false}
          />
        </PanelBox>
        <Value>
          {(amount &&
            conversions.vArmorToArmor &&
            +conversions.vArmorToArmor > 0 &&
            `You will receive ${twoDigitFormatter.format(
              amount * conversions.vArmorToArmor
            )}`) ||
            '0'}{' '}
          Armor
        </Value>
        <ButtonWithTooltip>
          <Tooltip
            title={t('Varmor.DisabledButtonTooltip')}
            placement="bottom"
            disableFocusListener={!disableTooltip(amount)}
            disableHoverListener={!disableTooltip(amount)}
            disableTouchListener={!disableTooltip(amount)}
            enterTouchDelay={50}
            arrow
          >
            <div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  parseFloat(amount) > parseFloat(vArmorBalance) ||
                  disableTooltip(amount) ||
                  isLoading
                }
                onClick={() => openWithdrawModal()}
              >
                <ButtonText>Swap VARMOR for ARMOR</ButtonText>
              </Button>
            </div>
          </Tooltip>
        </ButtonWithTooltip>
        <ActionModal closeModal={false} isModalOpened={isModalOpened} />
        <StickyModal
          closeModal={() => setIsWithdrawOptionsModalOpen(false)}
          isModalOpened={isWithdrawOptionsModalOpen}
          width={650}
        >
          <ActionContainer>
            <WithdrawConfirmationModal
              theme={theme}
              isPendingWithdraw={false}
              amountToWithdraw={amount}
              onCancelModal={() => setIsWithdrawOptionsModalOpen(false)}
              onWithdraw={() => onRequestWithdrawal()}
              onFinalizeWithdrawal={() => onFinalizeWithdraw()}
              withdrawRequest={withdrawRequest}
            />
          </ActionContainer>
        </StickyModal>
        <StickyModal
          closeModal={() => setIsUndelegateModalOpen(false)}
          isModalOpened={isUndelegateModalOpen}
          width={650}
        >
          <ActionContainer>
            <UndelegateModal
              theme={theme}
              isPendingWithdraw={false}
              amountToWithdraw={amount}
              onCancelModal={() => setIsUndelegateModalOpen(false)}
              onUndelegate={() => onUndelegate()}
              onFinalizeWithdrawal={() => onFinalizeWithdraw()}
              withdrawRequest={withdrawRequest}
            />
          </ActionContainer>
        </StickyModal>
      </PanelContainer>
    </>
  )
}

export default withTranslation()(withRouter(SwapVArmorToArmor))
