import React from 'react'
import { withTheme } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { ModalTitle, SubTitle, MainInfo, Row, Cell, TopSpace } from './styled'
import ExternalIcon from '../../components/icons/ExternalIcon'

const Step5 = ({ theme, t, currentStep, state }) => {
  const { colors } = theme

  return currentStep === 5 ? (
    <>
      <ModalTitle bold>{t('ProofOfLoss.Step.5')}</ModalTitle>
      <MainInfo>
        <SubTitle>1. Protocol: {state.protocol}</SubTitle>
        <TopSpace />
        <SubTitle>
          2. Affected Addresses and the amounts lost (in Ether)
        </SubTitle>
        {state.list.map(({ address, amount }, i) => (
          <Row key={i}>
            <Cell>{i + 1}</Cell>
            <Cell
              address="true"
              onClick={() =>
                window.open(`https://etherscan.io/address/${address}`, '_blank')
              }
            >
              {address} <ExternalIcon color={colors.active} />
            </Cell>
            <Cell>{amount} ETH</Cell>
          </Row>
        ))}
        <TopSpace />
        <SubTitle>
          3. Armor Address:{' '}
          <Cell
            address="true"
            onClick={() =>
              window.open(
                `https://etherscan.io/address/${state.armorAddress}`,
                '_blank'
              )
            }
          >
            {state.armorAddress} <ExternalIcon color={colors.active} />
          </Cell>
        </SubTitle>
      </MainInfo>
    </>
  ) : null
}

export default withTranslation()(withTheme(Step5))
