import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { withTranslation } from 'react-i18next'
import {
  ModalTitle,
  ActionContainer,
  ActionText,
  Spinner,
  CheckMark,
} from './styled'
import StickyModal from '../modal/StickyModal'
import checkMark from '../../../assets/logos/check_circle.svg'
import { CancelButton } from '../cancelButton'

const ActionModal = ({
  theme,
  closeModal,
  isModalOpened,
  actionText,
  isCompleted,
}) => {
  const { colors } = theme

  return (
    <StickyModal closeModal={closeModal} isModalOpened={isModalOpened}>
      <ModalTitle bold>{!isCompleted ? 'Working...' : 'Finished'}</ModalTitle>
      <ActionContainer>
        {isCompleted ? (
          <CheckMark src={checkMark} alt="check mark" />
        ) : (
          <Spinner fill={colors.active} />
        )}
        <ActionText>{actionText}</ActionText>
        {isCompleted && <CancelButton onClick={closeModal}>CLOSE</CancelButton>}
      </ActionContainer>
    </StickyModal>
  )
}

export default withTranslation()(withRouter(withTheme(ActionModal)))
