import { useState } from 'react'

export const useModalWithText = (opened, text, completed) => {
  const [isModalOpened, setIsModalOpened] = useState(opened)
  const [modalText, setModalText] = useState(text)
  const [isCompleted, setIsCompleted] = useState(completed)

  return [
    modalText,
    isModalOpened,
    isCompleted,
    setModalText,
    setIsModalOpened,
    setIsCompleted,
  ]
}
