import React from 'react'
import { Logo, Title, TitleWrapper } from './styled'

function ShieldGroupTitle({ title, logo }) {
  return (
    <TitleWrapper>
      <Logo src={require(`../../../../assets/${logo}`)} alt={`${title} logo`} />
      <Title>{title}</Title>
    </TitleWrapper>
  )
}

export default ShieldGroupTitle
