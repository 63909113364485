export const ArmorTokenEvents = {
  GetBalance: 'ArmorTokenEvents.GetBalance',
  BalanceReturned: 'ArmorTokenEvents.BalanceReturned',

  GetVArmorManagedAssets: 'ArmorTokenEvents.GetVArmorManagedAssets',
  VArmorManagedAssetsReturned: 'ArmorTokenEvents.VArmorManagedAssetsReturned',

  Approve: 'ArmorTokenEvents.Approve',
  ApproveCompleted: 'ArmorTokenEvents.ApproveCompleted',

  GetAllowance: 'ArmorTokenEvents.GetAllowance',
  AllowanceReturned: 'ArmorTokenEvents.AllowanceReturned',
}
