import styled from 'styled-components'
import { ButtonStyled } from '../../../components/common/Button'
import TableRow from '@material-ui/core/TableRow'

export const ActiveBtn = styled(ButtonStyled)`
  padding: 6px;
  height: unset;
  box-shadow: none;
  margin: 23px auto 0;
  max-width: max-content;
  background: ${(p) => p.theme.colors.buttonActiveBg};
  width: 100%;
  display: block;
  &.MuiButton-contained.Mui-disabled {
    background: ${(p) => p.theme.colors.strongDefault};
    & h4 {
      color: ${(p) => p.theme.colors.secondary};
    }
  }
`
export const ContentContainer = styled.div`
  padding: 21px 15px 20px;
`
export const DescriptionText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${(p) => p.theme.colors.secondary};
  max-width: 465px;
  width: 100%;
  margin: 22px auto 0;
`
export const CancelActiveBtn = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.active};
  padding: 6px;
  margin: 18px auto -5px;
  max-width: max-content;
  width: 100%;
  display: block;
`
export const Row = styled(TableRow)`
  min-height: 51px;
  background: ${(p) =>
    p.checked ? p.theme.colors.selectedCoveredRow : 'transparent'};

  @media screen and (max-width: 600px) {
    min-height: unset;
  }
`
export const AssetTableWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
`
