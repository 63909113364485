export const VArmorEvents = {
  GetConversions: 'VArmorEvents.GetConversions',
  ConversionsReturned: 'VArmorEvents.GetConversions',

  Deposit: 'VArmorEvents.Deposit',
  DepositCompleted: 'VArmorEvents.DepositCompleted',

  FinalizeWithdraw: 'VArmorEvents.FinalizeWithdraw',
  FinalizeWithdrawCompleted: 'VArmorEvents.FinalizeWithdrawCompleted',

  RequestWithdrawal: 'VArmorEvents.RequestWithdrawal',
  RequestWithdrawalCompleted: 'VArmorEvents.RequestWithdrawalCompleted',

  GetWithdrawRequests: 'VArmorEvents.GetWithdrawRequests',
  WithdrawRequestsReturned: 'VArmorEvents.WithdrawRequestsReturned',

  ApprovalCompleted: 'VArmorEvents.ApprovalCompleted',

  GetBalance: 'VArmorEvents.GetBalance',
  BalanceReturned: 'VArmorEvents.BalanceReturned',

  GetManagedAssets: 'VArmorEvents.GetManagedAssets',
  ManagedAssetsReturned: 'VArmorEvents.ManagedAssetsReturned',

  GetDelegate: 'VArmorEvents.GetDelegate',
  DelegateReturned: 'VArmorEvents.DelegateReturned',

  RequestUndelegate: 'VArmorEvents.RequestUndelegate',
  RequestUndelegateCompleted: 'VArmorEvents.RequestUndelegateCompleted',
}
