import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import Button from '../../shared/button/Button'
import { Container } from '../styled'
import moment from 'moment'
import { fromWei } from 'web3-utils'
import { commas } from '../../../helpers'

const WithdrawPending = ({
  t,
  withdrawalRequest,
  conversion,
  delayedUntil,
  onWithdraw,
}) => {
  useEffect(() => {
    isWithdrawalingWithDelay()
  }, [withdrawalRequest])

  const isWithdrawalingWithDelay = () => {
    return withdrawalRequest != null && withdrawalRequest.withdrawalTime > 0
  }

  const isAvailableToWithdraw = () => {
    if (withdrawalRequest == null || withdrawalRequest.request == null) {
      return false
    }
    if (withdrawalRequest.withdrawalTime === 0) {
      return false
    }
    return withdrawalRequest.withdrawalTime < moment.utc().unix()
  }
  const withdrawalLockAmount = () => {
    if (withdrawalRequest == null || withdrawalRequest.request == null) {
      return 0
    }

    return (
      parseFloat(fromWei(withdrawalRequest.request['arAmount'], 'ether')) *
      conversion
    )
  }

  return (
    isWithdrawalingWithDelay() && (
      <Container>
        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
          Attention!
          <br />{' '}
          {isAvailableToWithdraw() ? (
            <span>You can now withdraw your wNXM</span>
          ) : (
            <span>You have a pending withdrawal</span>
          )}
        </div>
        {isAvailableToWithdraw() && (
          <Button
            content={`${t('ArNXMPendingWithdraw.Withdraw')} ${commas(4).format(
              withdrawalLockAmount()
            )} wNXM`}
            disabled={!isAvailableToWithdraw(delayedUntil)}
            onClick={onWithdraw}
          />
        )}
        {!isAvailableToWithdraw() && (
          <div style={{ alignSelf: 'center' }}>
            You'll be able to withdraw your{' '}
            <strong>
              {commas(4).format(withdrawalLockAmount())} wNXM{' '}
              {moment.unix(withdrawalRequest.withdrawalTime).fromNow()}
            </strong>
          </div>
        )}
      </Container>
    )
  )
}

export default withTranslation()(withTheme(WithdrawPending))
