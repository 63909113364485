import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { withTranslation } from 'react-i18next'
import {
  ModalTitle,
  ActionContainer,
  ActionsWrapper,
  ActiveBtn,
  ActiveBtnText,
  ModalText,
} from './styled'
import StickyModal from '../modal/StickyModal'

const ConfirmationModal = ({
  closeModal,
  handleConfirm,
  handleClose,
  t,
  isModalOpened,
  actionText,
  yesText,
  noText,
}) => {
  const onConfirm = async () => {
    await handleConfirm()
    closeModal()
  }

  const onCancel = async () => {
    handleClose && handleClose()
    closeModal()
  }

  const noButtonText = () => {
    if (noText == null) {
      return t('Stake.UnstakeModal.No')
    }
    return noText
  }

  const yesButtonText = () => {
    if (yesText == null) {
      return t('Stake.UnstakeModal.Yes')
    }
    return yesText
  }

  return (
    <StickyModal closeModal={closeModal} isModalOpened={isModalOpened}>
      <ModalTitle bold>{t('Stake.UnstakeModal.Title')}</ModalTitle>
      <ActionContainer>
        <ModalText>{actionText}</ModalText>
        <ActionsWrapper>
          <ActiveBtn variant="contained" color="primary" onClick={onConfirm}>
            <ActiveBtnText>{yesButtonText()}</ActiveBtnText>
          </ActiveBtn>
          <ActiveBtn variant="contained" color="primary" onClick={onCancel}>
            <ActiveBtnText>{noButtonText()}</ActiveBtnText>
          </ActiveBtn>
        </ActionsWrapper>
      </ActionContainer>
    </StickyModal>
  )
}

export default withTranslation()(withRouter(withTheme(ConfirmationModal)))
