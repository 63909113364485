import React from 'react'
import { withTheme } from 'styled-components'
import { Box, Header, ComingSoonText } from './styled'
import ShieldGroupTitle from './ShieldGroupTitle'

const ShieldGroupComingSoon = ({ theme, title, logo }) => {
  return (
    <Box>
      <Header>
        <ShieldGroupTitle title={title} logo={logo} theme={theme} />
      </Header>

      <ComingSoonText>Coming Soon</ComingSoonText>
    </Box>
  )
}

export default withTheme(ShieldGroupComingSoon)
