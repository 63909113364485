import React from 'react'

const RefreshIcon = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 3C2.05228 3 2.5 3.44772 2.5 4V9H7.5C8.05228 9 8.5 9.44772 8.5 10C8.5 10.5523 8.05228 11 7.5 11H1.5C0.947715 11 0.5 10.5523 0.5 10V4C0.5 3.44772 0.947715 3 1.5 3Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 14C16.5 13.4477 16.9477 13 17.5 13H23.5C24.0523 13 24.5 13.4477 24.5 14V20C24.5 20.5523 24.0523 21 23.5 21C22.9477 21 22.5 20.5523 22.5 20V15H17.5C16.9477 15 16.5 14.5523 16.5 14Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.72695 2.39623C11.3497 1.92686 13.0648 1.8762 14.7124 2.24899C16.36 2.62177 17.8863 3.40585 19.1489 4.52806C20.4115 5.65026 21.3692 7.07402 21.9327 8.66648C22.117 9.18713 21.8442 9.75855 21.3236 9.94279C20.803 10.127 20.2315 9.85432 20.0473 9.33367C19.5965 8.0597 18.8303 6.9207 17.8202 6.02293C16.8101 5.12517 15.5891 4.49791 14.271 4.19968C12.953 3.90145 11.5808 3.94198 10.2827 4.31747C8.98451 4.69297 7.80262 5.3912 6.84729 6.34701C6.8399 6.3544 6.8324 6.36167 6.82478 6.36883L2.18478 10.7288C1.7823 11.107 1.14944 11.0873 0.77125 10.6849C0.393058 10.2824 0.412747 9.64951 0.815226 9.27132L5.4441 4.92178C6.63627 3.73285 8.1094 2.86411 9.72695 2.39623ZM24.2288 13.3153C24.6069 13.7178 24.5873 14.3506 24.1848 14.7288L19.5559 19.0784C18.3637 20.2673 16.8906 21.136 15.2731 21.6039C13.6503 22.0733 11.9352 22.1239 10.2876 21.7512C8.64003 21.3784 7.11373 20.5943 5.85114 19.4721C4.58855 18.3499 3.63081 16.9261 3.06729 15.3337C2.88305 14.813 3.15576 14.2416 3.67641 14.0574C4.19705 13.8731 4.76848 14.1458 4.95272 14.6665C5.40353 15.9404 6.16973 17.0795 7.1798 17.9772C8.18988 18.875 9.41091 19.5022 10.729 19.8005C12.047 20.0987 13.4192 20.0582 14.7173 19.6827C16.0155 19.3072 17.1974 18.609 18.1527 17.6531C18.1572 17.6487 18.1616 17.6443 18.1662 17.6399C18.1692 17.637 18.1722 17.6342 18.1752 17.6313L22.8152 13.2713C23.2177 12.8931 23.8506 12.9128 24.2288 13.3153Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath>
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RefreshIcon
