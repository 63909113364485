import React, { useEffect, useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Store from '../../../stores/store'
import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  ERROR,
} from '../../../constants'
import {
  commas,
  countDecimals,
  removeEmitterListeners,
  turnOnEmitterListeners,
  twoDigitFormatter,
  disableTooltip,
} from '../../../helpers'
import Tooltip from '@material-ui/core/Tooltip'
import { TooltipSpan } from '../../../components/arNxm/styled'
import AboutInfoIcon from '../../../components/icons/AboutInfoIcon'
import { PanelBox, PanelContainer } from './PanelBox'
import {
  ActionTitle,
  Button,
  ButtonText,
  ButtonWithTooltip,
  InputTitle,
  MaxButton,
  TextField,
  Value,
} from '../../arNxmVault/components/arNxmWrapAndStake/styled'
import ActionModal from '../../../components/common/actionModal/ActionModal'
import { useModalWithText } from '../../../hooks/useModalWithText'
import { VArmorEvents } from '../../../stores/contracts/vArmor/vArmorEvents'
import { ArmorTokenEvents } from '../../../stores/contracts/armorToken/armorTokenEvents'
import { SubTitle } from '../../../components/common/SubTitle'
const store = Store.store
const emitter = Store.emitter
const dispatcher = Store.dispatcher

const SwapArmorToVArmor = ({
  theme,
  network,
  t,
  conversions,
  armorBalance,
  onDepositCompleted,
}) => {
  const { colors } = theme
  const [account, setAccount] = useState(null)
  const [amount, setAmount] = useState('')
  const txtRef = useRef()
  const [
    modalText,
    isModalOpened,
    isCompleted,
    setModalText,
    setIsModalOpened,
    setIsCompleted,
  ] = useModalWithText(false, '', false)

  useEffect(() => {
    const _account = store.getStore('account')
    setAccount(_account)

    const connectionConnected = async () => {
      const _account = store.getStore('account')
      setAccount(_account)
      if (_account && _account.address) {
      }
    }

    let events = [
      [ERROR, errorReturned],
      [CONNECTION_CONNECTED, connectionConnected],
      [CONNECTION_DISCONNECTED, connectionDisconnected],
      [ArmorTokenEvents.ApproveCompleted, approveCompleted],
      [VArmorEvents.DepositCompleted, depositCompleted],
    ]
    turnOnEmitterListeners(emitter, events)

    return () => {
      removeEmitterListeners(emitter, events)
    }
  }, [network, account])

  const connectionDisconnected = () => setAccount(null)

  const errorReturned = (error) => {
    setIsModalOpened(false)
  }

  const approveCompleted = () => {
    setModalText('Swapping Armor for vArmor... This may take a few minutes.')
  }

  const depositCompleted = () => {
    setModalText(
      `You successfully swapped ${commas(2).format(
        txtRef.current.value[0]
      )} Armor for ${twoDigitFormatter.format(txtRef.current.value[1])} vArmor`
    )
    setIsCompleted(true)
    setAmount('')
    onDepositCompleted()
  }

  const handleModalClose = () => {
    setIsCompleted(false)
    setIsModalOpened(false)
  }

  const handleChangeAmount = (e) => {
    const value = e.target.value
    setAmount(value)
    txtRef.current.value = [value, value * conversions.armorToVArmor]
  }

  const renderHelp = () => {
    return (
      <div>
        <h5>{t('ArNxm.Help.Title')}</h5>
        <ul>
          <li>{t('ArNxm.Help.1')}</li>
          <li>{t('ArNxm.Help.2')}</li>
          <li>{t('ArNxm.Help.3')}</li>
          <li>{t('ArNxm.Help.4')}</li>
          <li>{t('ArNxm.Help.5')}</li>
        </ul>
      </div>
    )
  }

  const handleMaxClick = () => {
    if (
      armorBalance.toString() === 'false' ||
      armorBalance.toString() === '0'
    ) {
      return
    }

    let _value = parseFloat(armorBalance)
    if (countDecimals(_value) >= 5) {
      _value -= 0.000042
    }

    setAmount(_value.toString())
    txtRef.current.value = [_value, _value * conversions.armorToVArmor]
  }

  const handleSwap = () => {
    setModalText('Waiting on Approval. This may take a few minutes.')
    setIsModalOpened(true)

    dispatcher.dispatch({
      type: VArmorEvents.Deposit,
      content: {
        amount: amount.toString(),
      },
    })
  }

  const renderStakeHelp = () => {
    return (
      <div>
        Enter the amount of Armor tokens you want to stake and click the blue
        button. You will need to approve the staking of your Armor first,
        followed by a separate staking transaction.
      </div>
    )
  }

  return (
    <>
      <PanelContainer>
        <SubTitle placement="left" bottom="15">
          {t('Varmor.Stake')}
          <Tooltip
            arrow
            placement="top"
            enterTouchDelay={50}
            title={renderStakeHelp()}
          >
            <TooltipSpan ml>
              <AboutInfoIcon color={colors.defaultLightActive} />
            </TooltipSpan>
          </Tooltip>
        </SubTitle>
        <PanelBox>
          <ActionTitle>
            <InputTitle>
              <Tooltip
                arrow
                placement="top"
                enterTouchDelay={50}
                title={renderHelp()}
              >
                <TooltipSpan mr>
                  <AboutInfoIcon color={colors._default} />
                </TooltipSpan>
              </Tooltip>
              <span>{t('ArNxm.Balance')}: </span>
              <b>{commas(2).format(armorBalance)} Armor</b>
            </InputTitle>
            <MaxButton onClick={handleMaxClick}>MAX</MaxButton>
          </ActionTitle>
          <TextField
            ref={txtRef}
            fullWidth
            placeholder={'0'}
            variant="outlined"
            type="text"
            onChange={handleChangeAmount}
            value={amount}
            disabled={false}
          />
        </PanelBox>
        <Value>
          {(amount &&
            conversions.armorToVArmor &&
            +conversions.armorToVArmor > 0 &&
            `You will receive ${twoDigitFormatter.format(
              amount * conversions.armorToVArmor
            )}`) ||
            '0'}{' '}
          vArmor
        </Value>
        <ButtonWithTooltip>
          <Tooltip
            title={t('Varmor.DisabledButtonTooltip')}
            placement="bottom"
            disableFocusListener={!disableTooltip(amount)}
            disableHoverListener={!disableTooltip(amount)}
            disableTouchListener={!disableTooltip(amount)}
            enterTouchDelay={50}
            arrow
          >
            <div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  parseFloat(amount) > parseFloat(armorBalance) ||
                  disableTooltip(amount)
                }
                onClick={handleSwap}
              >
                <ButtonText>Swap Armor for Varmor</ButtonText>
              </Button>
            </div>
          </Tooltip>
        </ButtonWithTooltip>
        <ActionModal
          closeModal={handleModalClose}
          actionText={modalText}
          isModalOpened={isModalOpened}
          isCompleted={isCompleted}
        />
      </PanelContainer>
    </>
  )
}

export default withTranslation()(withRouter(SwapArmorToVArmor))
