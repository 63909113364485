import { RewardManagerV2Events } from './rewardManagerV2Events'

class RewardManagerV2Contract {
  constructor(service) {
    this.service = service
  }

  shouldDispatch(payload) {
    return this.service.shouldDispatch(RewardManagerV2Events, payload)
  }

  async dispatch(payload) {
    switch (payload.type) {
      case RewardManagerV2Events.GetTotalPendingRewards:
        await this.getTotalPendingRewards(payload)
        break
      case RewardManagerV2Events.ClaimRewardInBatch:
        await this.claimRewardInBatch(payload)
        break
      // no default
    }
  }

  async getTotalPendingRewards(payload) {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return '0'
    }

    const { protocols } = payload.content

    let response = await this._getTotalPendingRewards(account, protocols).catch(
      (err) => {
        this.service.emitError(err)
        return '0'
      }
    )

    this.service.setStore({
      [`RewardManagerV2_TotalPendingRewards`]: response,
    })
    this.service.emit(
      RewardManagerV2Events.TotalPendingRewardsReturned,
      response
    )

    return response
  }
  async _getTotalPendingRewards(account, protocols) {
    const web3 = await this.service.getWeb3()
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.service.makeContract(
          web3,
          this.service.config.rewardManagerV2.abi,
          this.service.config.rewardManagerV2.address
        )

        let balance = await contract.methods
          .getTotalPendingReward(account.address, protocols)
          .call({ from: account.address })

        resolve(web3.utils.fromWei(balance, 'ether'))
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  async claimRewardInBatch(payload) {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return false
    }
    const { protocols } = payload.content

    await this._claimRewardInBatch(account, protocols)
      .then((data) => {
        this.service.emit(
          RewardManagerV2Events.ClaimRewardInBatchCompleted,
          data
        )
      })
      .catch((err) => this.service.emitError(err))
  }

  async _claimRewardInBatch(account, protocols) {
    return new Promise(async (resolve, reject) => {
      try {
        const web3 = await this.service.getWeb3()
        const contract = this.service.makeContract(
          web3,
          this.service.config.rewardManagerV2.abi,
          this.service.config.rewardManagerV2.address
        )

        await contract.methods
          .claimRewardInBatch(protocols)
          .send({
            from: account.address,
          })
          .once('transactionHash', (hash) =>
            this.service.handleTransactionHash(hash)
          )
          .once('receipt', (receipt) => this.service.handleReceipt(receipt))
          .on('confirmation', (confirmationNumber, receipt) =>
            this.service.handleConfirmation(confirmationNumber, receipt)
          )
          .on('error', (error) => {
            this.service.handleError(error)
            reject(error)
          })
          .then((data) => resolve(data))
      } catch (e) {
        reject(e)
      }
    })
  }
}

export default RewardManagerV2Contract
