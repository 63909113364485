import React from 'react'

const CheckCircleIcon = ({ color }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.663 3.77329C13.8902 2.9834 11.9096 2.78771 10.0166 3.21542C8.12351 3.64314 6.41942 4.67133 5.15845 6.14666C3.89749 7.62199 3.14721 9.4654 3.01951 11.402C2.89181 13.3386 3.39354 15.2645 4.44987 16.8927C5.50619 18.5208 7.06051 19.7638 8.88102 20.4364C10.7015 21.109 12.6907 21.1751 14.5518 20.6248C16.413 20.0745 18.0464 18.9374 19.2084 17.3829C20.3705 15.8285 20.9989 13.9401 21 11.9993V11.0799C21 10.5276 21.4477 10.0799 22 10.0799C22.5523 10.0799 23 10.5276 23 11.0799V11.9999C22.9986 14.3719 22.2306 16.6806 20.8103 18.5804C19.39 20.4803 17.3936 21.8702 15.1189 22.5427C12.8442 23.2153 10.413 23.1345 8.18792 22.3125C5.96285 21.4904 4.06312 19.9711 2.77206 17.9812C1.48099 15.9913 0.86777 13.6373 1.02384 11.2704C1.17992 8.90346 2.09693 6.6504 3.63811 4.84722C5.17929 3.04404 7.26206 1.78736 9.57581 1.2646C11.8896 0.741837 14.3103 0.981007 16.477 1.94644C16.9815 2.17122 17.2082 2.76239 16.9834 3.26687C16.7587 3.77134 16.1675 3.99807 15.663 3.77329Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7068 3.29254C23.0975 3.68287 23.0978 4.31603 22.7075 4.70675L12.7075 14.7168C12.52 14.9044 12.2656 15.0099 12.0002 15.01C11.7349 15.0101 11.4805 14.9047 11.2929 14.7171L8.29289 11.7171C7.90237 11.3266 7.90237 10.6934 8.29289 10.3029C8.68342 9.91237 9.31658 9.91237 9.70711 10.3029L11.9996 12.5954L21.2925 3.29325C21.6829 2.90253 22.316 2.90221 22.7068 3.29254Z"
        fill={color}
      />
    </svg>
  )
}

export default CheckCircleIcon
