import styled, { css } from 'styled-components'

export const Socials = styled.div`
  display: flex;
  background: rgba(145, 255, 189, 0.1);
  padding: 7px 12px;
  border-radius: 20px;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);

  ${(p) =>
    p.noaccount
      ? css`
          @media screen and (max-width: 1200px) {
            margin-top: 70px;
          }
        `
      : css``}

  @media screen and (max-width: 900px) {
    position: relative;
    top: unset;
    left: unset;
    transform: none;
    margin-top: ${(p) => (p.noaccount ? '80px' : '15px')};
  }

  @media screen and (max-width: 670px) {
    margin-top: 90px;
  }

  @media screen and (max-width: 550px) {
    margin-top: ${(p) => (p.noaccount ? '120px' : '90px')};
  }
`
export const SocialItem = styled.div`
  cursor: pointer;
  margin-left: 15px;
  display: flex;
  align-items: center;

  &:first-of-type {
    margin-left: 0;
  }
`
