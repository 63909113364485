import React, { useState } from 'react'
import { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Button from '../../components/common/button/Button'
import { StepFooter, FooterInfo } from './styled'
import AboutCircleIcon from '../../components/icons/AboutCircleIcon'

const StepWizard = ({ t, theme, isLoading, submit }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [state, setState] = useState({
    protocol: '',
    list: [],
    armorAddress: '',
  })
  const [stepError, setStepError] = useState([true, true, true, true])
  const { colors } = theme

  const validation = (value) => {
    if (currentStep === 1 && value !== '') {
      return false
    }
    if (currentStep === 2 && value.length > 0) {
      return false
    }
    if (currentStep === 3 && value !== '') {
      return false
    }
    if (
      currentStep === 4 &&
      value.length > 0 &&
      !value.find((item) => item.amount === '')
    ) {
      return false
    }
    return true
  }

  const handleChange = (event) => {
    const { name, value } = event
    setState((state) => ({ ...state, [name]: value }))
    setStepError((state) => ({
      ...state,
      [currentStep - 1]: validation(value),
    }))
  }

  const next = () => {
    if (currentStep === 5) {
      submit(state)
      return
    }
    setCurrentStep((state) => state + 1)
  }

  const prev = () => {
    setCurrentStep((state) => state - 1)
  }

  return (
    <>
      <Step1 currentStep={currentStep} handleChange={handleChange} />
      <Step2
        currentStep={currentStep}
        handleChange={handleChange}
        isLoading={isLoading}
      />
      <Step3 currentStep={currentStep} handleChange={handleChange} />
      <Step4
        currentStep={currentStep}
        list={state.list}
        handleChange={handleChange}
      />
      <Step5 currentStep={currentStep} state={state} />
      {currentStep === 5 && (
        <FooterInfo>
          <AboutCircleIcon color={colors.disabledText} />
          {t('ProofOfLoss.FooterInfo')}
        </FooterInfo>
      )}
      <StepFooter>
        <Button
          buttonText="Previous"
          isDisabled={currentStep === 1}
          onClick={prev}
          color="primary"
        />
        <Button
          buttonText={currentStep === 5 ? 'Submit' : 'Next'}
          isDisabled={stepError[currentStep - 1]}
          onClick={next}
          color="primary"
        />
      </StepFooter>
    </>
  )
}

export default withTranslation()(withRouter(withTheme(StepWizard)))
