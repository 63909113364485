import styled from 'styled-components'

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  flex-wrap: wrap;

  @media screen and (max-width: 350px) {
    flex-direction: column;
  }
`
export const ContentContainer = styled.div`
  padding: 21px 15px;
`
