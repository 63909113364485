import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Container, Cell, Logo } from './styled'
import tradingList from './mock'

const ArNxmTrade = () => {
  return (
    <Container>
      {tradingList.map(({ name, logo, lpUrl }, index) => (
        <Cell
          title={name}
          key={index}
          onClick={() => window.open(lpUrl, '_blank')}
        >
          <Logo src={require(`../../../../assets/${logo}`)} alt="icon" />
        </Cell>
      ))}
    </Container>
  )
}

export default withTranslation()(withRouter(withTheme(ArNxmTrade)))
