const quotation = (address) =>
  Object.freeze({
    address: address,
    abi: [
      {
        constant: false,
        inputs: [],
        name: 'changeDependentContractAddress',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ name: '_add', type: 'address' }],
        name: 'changeAuthQuoteEngine',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_cid', type: 'uint256' }],
        name: 'getCoverSumAssured',
        outputs: [{ name: 'sa', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'getProductDetails',
        outputs: [
          { name: '_minDays', type: 'uint256' },
          { name: '_pm', type: 'uint256' },
          { name: '_stl', type: 'uint256' },
          { name: '_stlp', type: 'uint256' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'stl',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { name: 'from', type: 'address' },
          { name: 'scAddress', type: 'address' },
          { name: 'coverCurr', type: 'bytes4' },
          { name: 'coverDetails', type: 'uint256[]' },
          { name: 'coverPeriod', type: 'uint16' },
        ],
        name: 'addHoldCover',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'stlp',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_cid', type: 'uint256' }],
        name: 'getCoverDetailsByCoverID2',
        outputs: [
          { name: 'cid', type: 'uint256' },
          { name: 'status', type: 'uint8' },
          { name: 'sumAssured', type: 'uint256' },
          { name: 'coverPeriod', type: 'uint16' },
          { name: 'validUntil', type: 'uint256' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { name: '_add', type: 'address' },
          { name: '_curr', type: 'bytes4' },
          { name: '_amount', type: 'uint256' },
        ],
        name: 'addInTotalSumAssuredSC',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_cid', type: 'uint256' }],
        name: 'getCoverMemberAddress',
        outputs: [{ name: '_add', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_curr', type: 'bytes4' }],
        name: 'getTotalSumAssured',
        outputs: [{ name: 'amount', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_hcid', type: 'uint256' }],
        name: 'getHoldedCoverDetailsByID1',
        outputs: [
          { name: 'hcid', type: 'uint256' },
          { name: 'scAddress', type: 'address' },
          { name: 'coverCurr', type: 'bytes4' },
          { name: 'coverPeriod', type: 'uint16' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_cid', type: 'uint256' }],
        name: 'getCoverStatusNo',
        outputs: [{ name: '', type: 'uint8' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [
          { name: '_add', type: 'address' },
          { name: '_curr', type: 'bytes4' },
        ],
        name: 'getTotalSumAssuredSC',
        outputs: [{ name: 'amount', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_add', type: 'address' }],
        name: 'getUserCoverLength',
        outputs: [{ name: 'len', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ name: '_add', type: 'address' }],
        name: 'setKycAuthAddress',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { name: '_curr', type: 'bytes4' },
          { name: '_amount', type: 'uint256' },
        ],
        name: 'subFromTotalSumAssured',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: 'code', type: 'bytes8' }],
        name: 'getUintParameters',
        outputs: [
          { name: 'codeVal', type: 'bytes8' },
          { name: 'val', type: 'uint256' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'getCoverLength',
        outputs: [{ name: 'len', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { name: 'holdedCoverID', type: 'uint256' },
          { name: 'status', type: 'uint256' },
        ],
        name: 'setHoldedCoverIDStatus',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_cid', type: 'uint256' }],
        name: 'getValidityOfCover',
        outputs: [{ name: 'date', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_cid', type: 'uint256' }],
        name: 'getCoverPeriod',
        outputs: [{ name: 'cp', type: 'uint32' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { name: '_add', type: 'address' },
          { name: '_curr', type: 'bytes4' },
          { name: '_amount', type: 'uint256' },
        ],
        name: 'subFromTotalSumAssuredSC',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ name: '_timestamp', type: 'uint256' }],
        name: 'setTimestampRepeated',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'kycAuthAddress',
        outputs: [{ name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'pm',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_cid', type: 'uint256' }],
        name: 'getCurrencyOfCover',
        outputs: [{ name: 'curr', type: 'bytes4' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { name: '_coverPeriod', type: 'uint16' },
          { name: '_sumAssured', type: 'uint256' },
          { name: '_userAddress', type: 'address' },
          { name: '_currencyCode', type: 'bytes4' },
          { name: '_scAddress', type: 'address' },
          { name: 'premium', type: 'uint256' },
          { name: 'premiumNXM', type: 'uint256' },
        ],
        name: 'addCover',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_cid', type: 'uint256' }],
        name: 'getscAddressOfCover',
        outputs: [
          { name: '', type: 'uint256' },
          { name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '', type: 'uint256' }],
        name: 'timestampRepeated',
        outputs: [{ name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { name: 'code', type: 'bytes8' },
          { name: 'val', type: 'uint256' },
        ],
        name: 'updateUintParameters',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'ms',
        outputs: [{ name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { name: '_curr', type: 'bytes4' },
          { name: '_amount', type: 'uint256' },
        ],
        name: 'addInTotalSumAssured',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [
          { name: '', type: 'address' },
          { name: '', type: 'uint256' },
        ],
        name: 'userHoldedCover',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { name: '_cid', type: 'uint256' },
          { name: '_stat', type: 'uint8' },
        ],
        name: 'changeCoverStatusNo',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '', type: 'uint256' }],
        name: 'coverStatus',
        outputs: [{ name: '', type: 'uint8' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_cid', type: 'uint256' }],
        name: 'getCoverPremiumNXM',
        outputs: [{ name: '_premiumNXM', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '', type: 'uint256' }],
        name: 'holdedCoverIDStatus',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_add', type: 'address' }],
        name: 'getUserHoldedCoverLength',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_hcid', type: 'uint256' }],
        name: 'getHoldedCoverDetailsByID2',
        outputs: [
          { name: 'hcid', type: 'uint256' },
          { name: 'memberAddress', type: 'address' },
          { name: 'coverDetails', type: 'uint256[]' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ name: '_masterAddress', type: 'address' }],
        name: 'changeMasterAddress',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '', type: 'address' }],
        name: 'refundEligible',
        outputs: [{ name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_cid', type: 'uint256' }],
        name: 'getCoverDetailsByCoverID1',
        outputs: [
          { name: 'cid', type: 'uint256' },
          { name: '_memberAddress', type: 'address' },
          { name: '_scAddress', type: 'address' },
          { name: '_currencyCode', type: 'bytes4' },
          { name: '_sumAssured', type: 'uint256' },
          { name: 'premiumNXM', type: 'uint256' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'authQuoteEngine',
        outputs: [{ name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'getAuthQuoteEngine',
        outputs: [{ name: '_add', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'minDays',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [
          { name: '_add', type: 'address' },
          { name: 'index', type: 'uint256' },
        ],
        name: 'getUserHoldedCoverByIndex',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'nxMasterAddress',
        outputs: [{ name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'tokensRetained',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { name: '_add', type: 'address' },
          { name: 'status', type: 'bool' },
        ],
        name: 'setRefundEligible',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ name: '_add', type: 'address' }],
        name: 'getAllCoversOfUser',
        outputs: [{ name: 'allCover', type: 'uint256[]' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { name: '_authQuoteAdd', type: 'address' },
          { name: '_kycAuthAdd', type: 'address' },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: 'cid', type: 'uint256' },
          { indexed: false, name: 'scAdd', type: 'address' },
          { indexed: false, name: 'sumAssured', type: 'uint256' },
          { indexed: false, name: 'expiry', type: 'uint256' },
          { indexed: false, name: 'premium', type: 'uint256' },
          { indexed: false, name: 'premiumNXM', type: 'uint256' },
          { indexed: false, name: 'curr', type: 'bytes4' },
        ],
        name: 'CoverDetailsEvent',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: 'cid', type: 'uint256' },
          { indexed: false, name: 'statusNum', type: 'uint8' },
        ],
        name: 'CoverStatusEvent',
        type: 'event',
      },
    ],
  })

export default quotation
