import styled from 'styled-components'

export const PanelBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  width: 100%;

  @media screen and (max-width: 650px) {
    max-width: 350px;
  }
`

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  max-width: 250px;
  margin: 0 10px;
  @media screen and (max-width: 990px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 650px) {
    margin: 30px 0 0;
    max-width: 350px;
  }
`
