import React from 'react'

const ExternalIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.3335 4.66659C3.15668 4.66659 2.98712 4.73682 2.86209 4.86185C2.73707 4.98687 2.66683 5.15644 2.66683 5.33325V12.6666C2.66683 12.8434 2.73707 13.013 2.86209 13.138C2.98712 13.263 3.15669 13.3333 3.3335 13.3333H10.6668C10.8436 13.3333 11.0132 13.263 11.1382 13.138C11.2633 13.013 11.3335 12.8434 11.3335 12.6666V8.66659C11.3335 8.2984 11.632 7.99992 12.0002 7.99992C12.3684 7.99992 12.6668 8.2984 12.6668 8.66659V12.6666C12.6668 13.197 12.4561 13.7057 12.081 14.0808C11.706 14.4559 11.1973 14.6666 10.6668 14.6666H3.3335C2.80306 14.6666 2.29436 14.4559 1.91928 14.0808C1.54421 13.7057 1.3335 13.197 1.3335 12.6666V5.33325C1.3335 4.80282 1.54421 4.29411 1.91928 3.91904C2.29436 3.54397 2.80306 3.33325 3.3335 3.33325H7.3335C7.70169 3.33325 8.00016 3.63173 8.00016 3.99992C8.00016 4.36811 7.70169 4.66659 7.3335 4.66659H3.3335Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3335 1.99992C9.3335 1.63173 9.63197 1.33325 10.0002 1.33325H14.0002C14.3684 1.33325 14.6668 1.63173 14.6668 1.99992V5.99992C14.6668 6.36811 14.3684 6.66658 14.0002 6.66658C13.632 6.66658 13.3335 6.36811 13.3335 5.99992V2.66659H10.0002C9.63197 2.66659 9.3335 2.36811 9.3335 1.99992Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4714 1.52851C14.7318 1.78886 14.7318 2.21097 14.4714 2.47132L7.13807 9.80466C6.87772 10.065 6.45561 10.065 6.19526 9.80466C5.93491 9.54431 5.93491 9.1222 6.19526 8.86185L13.5286 1.52851C13.7889 1.26816 14.2111 1.26816 14.4714 1.52851Z"
        fill={color}
      />
    </svg>
  )
}

export default ExternalIcon
