const protocolContracts = [
  {
    id: 'aavev2',
    name: 'Aave v2',
    address: '0x7d2768dE32b0b80b7a3454c06BdAc94A69DDc7A9',
    symbol: 'AAVEV2',
    logo: 'aavev2.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'polygon'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'uniswapv2',
    name: 'Uniswap v2',
    address: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    symbol: 'UNISWAPV2',
    logo: 'uniswapv2.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'sushiswap',
    name: 'SushiSwap v1',
    address: '0xc2EdaD668740f1aA35E4D8f227fB8E17dcA888Cd',
    symbol: 'SUSHISWAP',
    logo: 'sushiswap.jpg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc', 'xdai', 'fantom', 'polygon'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'badgerdao',
    name: 'BadgerDAO',
    address: '0x6354E79F21B56C11f48bcD7c451BE456D7102A36',
    symbol: 'BADGERDAO',
    logo: 'badgerdao.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'compoundv2',
    name: 'Compound v2',
    address: '0x3d9819210A31b4961b30EF54bE2aeD79B9c9Cd3B',
    symbol: 'COMPOUNDV2',
    logo: 'compoundv2.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'makerdao',
    name: 'MakerDAO MCD',
    address: '0x35D1b3F3D7966A1DFe207aa4514C12a259A0492B',
    symbol: 'MAKERDAO',
    logo: 'makerdao.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'curve',
    name: 'Curve All Pools(incl staking)',
    address: '0x79a8C46DeA5aDa233ABaFFD40F3A0A2B1e5A4F27',
    symbol: 'CURVE',
    logo: 'curve.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'polygon'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'iearn',
    name: 'Yearn Finance (all vaults)',
    address: '0x9D25057e62939D3408406975aD75Ffe834DA4cDd',
    symbol: 'IEARN',
    logo: 'iearn.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'fantom'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'balancer',
    name: 'Balancer v1',
    address: '0x9424B1412450D0f8Fc2255FAf6046b98213B76Bd',
    symbol: 'BALANCER',
    logo: 'balancer.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'ren',
    name: 'RenVM',
    address: '0xe80d347DF1209a76DD9d2319d62912ba98C54DDD',
    symbol: 'REN',
    logo: 'ren.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'alpha-homora',
    name: 'Alpha Homora v1',
    address: '0x67B66C99D3Eb37Fa76Aa3Ed1ff33E8e39F0b9c7A',
    symbol: 'ALPHA-HOMORA',
    logo: 'alpha-homora.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'tokensetsv2',
    name: 'Set Protocol v2',
    address: '0xa4c8d221d8BB851f83aadd0223a8900A6921A349',
    symbol: 'TOKENSETSV2',
    logo: 'tokensets.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'cream',
    name: 'CREAM v1',
    address: '0x3d5BC3c8d13dcB8bF317092d84783c2697AE9258',
    symbol: 'CREAM',
    logo: 'cream.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc', 'polygon'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'hegic',
    name: 'Hegic',
    address: '0x878F15ffC8b894A1BA7647c7176E4C01f74e140b',
    symbol: 'HEGIC',
    logo: 'hegic.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },

  {
    id: 'synthetix',
    name: 'Synthetix',
    address: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
    symbol: 'SYNTHETIX',
    logo: 'synthetix.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'notional',
    name: 'Notional Finance v1',
    address: '0x9abd0b8868546105F6F48298eaDC1D9c82f7f683',
    symbol: 'NOTIONALFINANCE',
    logo: 'notional.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'coinbase',
    name: 'Coinbase',
    address: '0xc57d000000000000000000000000000000000008',
    symbol: 'COINBASE',
    logo: 'coinbase_mini.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'binance',
    name: 'Binance',
    address: '0xc57d000000000000000000000000000000000007',
    symbol: 'BINANCE',
    logo: 'binance.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'gemini',
    name: 'Gemini',
    address: '0xc57d000000000000000000000000000000000010',
    symbol: 'GEMINI',
    logo: 'gemini.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'kraken',
    name: 'Kraken',
    address: '0xc57d000000000000000000000000000000000009',
    symbol: 'KRAKEN',
    logo: 'kraken.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'celsius',
    name: 'Celsius',
    address: '0xc57D000000000000000000000000000000000001',
    symbol: 'CELSIUS',
    logo: 'celsius.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'blockfi',
    name: 'BlockFi',
    address: '0xC57D000000000000000000000000000000000002',
    symbol: 'BLOCKFI',
    logo: 'blockFi.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'nexo',
    name: 'Nexo',
    address: '0xC57d000000000000000000000000000000000003',
    symbol: 'NEXO',
    logo: 'nexo.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'inlock',
    name: 'inLock',
    address: '0xc57d000000000000000000000000000000000004',
    symbol: 'INLOCK',
    logo: 'inlock.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'ledn',
    name: 'Ledn',
    address: '0xC57D000000000000000000000000000000000005',
    symbol: 'LEDN',
    logo: 'ledn.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'hodlnaut',
    name: 'Hodlnaut',
    address: '0xC57d000000000000000000000000000000000006',
    symbol: 'HODLNAUT',
    logo: 'hodlnaut.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'perpetual',
    name: 'Perpetual Protocol',
    address: '0xA51156F3F1e39d1036Ca4ba4974107A1C1815d1e',
    symbol: 'PERPETUAL',
    logo: 'perpetual.jpg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'xdai'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'aave',
    name: 'Aave v1',
    address: '0xc1D2819CE78f3E15Ee69c6738eB1B400A26e632A',
    symbol: 'AAVE',
    logo: 'aave.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'truefi',
    name: 'TrueFi',
    address: '0x7a9701453249e84fd0D5AfE5951e9cBe9ed2E90f',
    symbol: 'TRUEFI',
    logo: 'truefi.jpg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'keeperdao',
    name: 'Keeper DAO',
    address: '0xfA5047c9c78B8877af97BDcb85Db743fD7313d4a',
    symbol: 'KEEPERDAO',
    logo: 'keeperdao.jpg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'eth2',
    name: 'Eth 2.0(deposit contract)',
    address: '0x00000000219ab540356cBB839Cbe05303d7705Fa',
    symbol: 'ETH2',
    logo: 'eth2.jpg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'pooltogetherv3',
    name: 'Pool Together v3',
    address: '0xCB876f60399897db24058b2d58D0B9f713175eeF',
    symbol: 'POOLTOGETHERV3',
    logo: 'pooltogether.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'yield',
    name: 'Yield Protocol',
    address: '0xB94199866Fe06B535d019C11247D3f921460b91A',
    symbol: 'YIELD',
    logo: 'yield.jpg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'cofix',
    name: 'CoFix',
    address: '0x26aaD4D82f6c9FA6E34D8c1067429C986A055872',
    symbol: 'COFIX',
    logo: 'cofix.jpg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'dodo',
    name: 'DODO exchange',
    address: '0x3A97247DF274a17C59A3bd12735ea3FcDFb49950',
    symbol: 'DODO',
    logo: 'dodo.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'akropolis',
    name: 'Akropolis Delphi',
    address: '0x4C39b37f5F20a0695BFDC59cf10bd85a6c4B7c30',
    symbol: 'AKROPOLIS',
    logo: 'akropolis.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'nucypher',
    name: 'NuCypher Worklock',
    address: '0xe9778E69a961e64d3cdBB34CF6778281d34667c2',
    symbol: 'NUCYPHER',
    logo: 'nucypher.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'tbtc',
    name: 'tBTC Contracts v1',
    address: '0xe20A5C79b39bC8C363f0f49ADcFa82C2a01ab64a',
    symbol: 'TBTC',
    logo: 'tbtc.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'mooniswap',
    name: 'Mooniswap',
    address: '0x71CD6666064C3A1354a3B4dca5fA1E2D3ee7D303',
    symbol: 'MOONISWAP',
    logo: 'mooniswap.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'idle',
    name: 'Idle v4',
    address: '0x3fE7940616e5Bc47b0775a0dccf6237893353bB4',
    symbol: 'IDLE',
    logo: 'idle.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'dforce',
    name: 'dForce Yield Market',
    address: '0x02285AcaafEB533e03A7306C55EC031297df9224',
    symbol: 'DFORCE',
    logo: 'dforce.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'yam',
    name: 'Yam Finance',
    address: '0x0e2298E3B3390e3b945a5456fBf59eCc3f55DA16',
    symbol: 'YAM',
    logo: 'yam.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'uma',
    name: 'UMA',
    address: '0x3e532e6222afe9Bcf02DCB87216802c75D5113aE',
    symbol: 'UMA',
    logo: 'uma.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'bancor',
    name: 'Bancor v2',
    address: '0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C',
    symbol: 'BANCOR',
    logo: 'bancor.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'kyber',
    name: 'Kyber (Katalyst)',
    address: '0x9AAb3f75489902f3a48495025729a0AF77d4b11e',
    symbol: 'KYBER',
    logo: 'kyber.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'deversifi',
    name: 'Deversifi',
    address: '0x5d22045DAcEAB03B158031eCB7D9d06Fad24609b',
    symbol: 'DEVERSIFI',
    logo: 'deversifi.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'starkware'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'ampleforth',
    name: 'Ampleforth Tokengeyser',
    address: '0xD36132E0c1141B26E62733e018f12Eb38A7b7678',
    symbol: 'AMPLEFORTH',
    logo: 'ampleforth.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'melon',
    name: 'Melon v1',
    address: '0x5f9AE054C7F0489888B1ea46824b4B9618f8A711',
    symbol: 'MELON',
    logo: 'melon.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'mstable',
    name: 'mStable',
    address: '0xAFcE80b19A8cE13DEc0739a1aaB7A028d6845Eb3',
    symbol: 'MSTABLE',
    logo: 'mstable.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'bzx',
    name: 'bZx v1',
    address: '0x8B3d70d628Ebd30D4A2ea82DB95bA2e906c71633',
    symbol: 'BZX',
    logo: 'bzx.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'saturndao',
    name: 'Saturn DAO Token',
    address: '0xAF350211414C5DC176421Ea05423F0cC494261fB',
    symbol: 'SATURNDAO',
    logo: 'saturndao.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'gnosis-multisig',
    name: 'Legacy Gnosis MultiSig',
    address: '0x6e95C8E8557AbC08b46F3c347bA06F8dC012763f',
    symbol: 'GNOSIS-MULTISIG',
    logo: 'gnosis-multisig.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'dxdao',
    name: 'dxDAO',
    address: '0x519b70055af55A007110B4Ff99b0eA33071c720a',
    symbol: 'DXDAO',
    logo: 'dxdao.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'nuo',
    name: 'Nuo',
    address: '0x802275979B020F0ec871c5eC1db6e412b72fF20b',
    symbol: 'NUO',
    logo: 'nuo.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'pooltogether',
    name: 'Pool Together',
    address: '0x932773aE4B661029704e731722CF8129e1B32494',
    symbol: 'POOLTOGETHER',
    logo: 'pooltogether.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'argent',
    name: 'Argent',
    address: '0xB1dD690Cc9AF7BB1a906A9B5A94F94191cc553Ce',
    symbol: 'ARGENT',
    logo: 'argent.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'dydxperpetual',
    name: 'dydx Perpetual',
    address: '0x364508A5cA0538d8119D3BF40A284635686C98c4',
    symbol: 'DYDXP',
    logo: 'dydx.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'starkware'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'ddex',
    name: 'DDEX',
    address: '0x241e82C79452F51fbfc89Fac6d912e021dB1a3B7',
    symbol: 'DDEX',
    logo: 'ddex.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'tornado',
    name: 'Tornado Cash',
    address: '0x12D66f87A04A9E220743712cE6d9bB1B5616B8Fc',
    symbol: 'TORNADO',
    logo: 'tornado.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: '0x',
    name: '0x v3',
    address: '0xB27F1DB0a7e473304A5a06E54bdf035F671400C0',
    symbol: '0X',
    logo: '0x.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'dydx',
    name: 'dydx',
    address: '0x1E0447b19BB6EcFdAe1e4AE1694b0C3659614e4e',
    symbol: 'DYDX',
    logo: 'dydx.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'gnosis-safe',
    name: 'Gnosis Safe',
    address: '0x34CfAC646f301356fAa8B21e94227e3583Fe3F5F',
    symbol: 'GNOSIS-SAFE',
    logo: 'gnosis_safe.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: '1inch',
    name: '1Inch(DEX & Liquidity Pools)',
    address: '0x11111254369792b2Ca5d084aB5eEA397cA8fa48B',
    symbol: '1INCH',
    logo: '1inch.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'opyn',
    name: 'Opyn',
    address: '0xb529964F86fbf99a6aA67f72a27e59fA3fa4FEaC',
    symbol: 'OPYN',
    logo: 'opyn.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'totle',
    name: 'Totle',
    address: '0x77208a6000691E440026bEd1b178EF4661D37426',
    symbol: 'TOTLE',
    logo: 'totle.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'flexa',
    name: 'Flexa Staking',
    address: '0x12f208476F64De6e6f933E55069Ba9596D818e08',
    symbol: 'FLEXA',
    logo: 'flexa.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'tokensetsv1',
    name: 'Set Protocol',
    address: '0x5B67871C3a857dE81A1ca0f9F7945e5670D986Dc',
    symbol: 'TOKENSETSV1',
    logo: 'tokensets.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'paraswap',
    name: 'Paraswap v1',
    address: '0x86969d29F5fd327E1009bA66072BE22DB6017cC6',
    symbol: 'PARASWAP',
    logo: 'paraswap.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'moloch',
    name: 'MolochDAO',
    address: '0x1fd169A4f5c59ACf79d0Fd5d91D1201EF1Bce9f1',
    symbol: 'MOLOCH',
    logo: 'moloch.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'idex',
    name: 'IDEX',
    address: '0x2a0c0DBEcC7E4D658f48E01e3fA353F44050c208',
    symbol: 'IDEX',
    logo: 'idex.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'cover',
    name: 'Cover Protocol',
    address: '0xedfC81Bf63527337cD2193925f9C0cF2D537AccA',
    symbol: 'COVER',
    logo: 'cover.jpg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc', 'xdai', 'fantom', 'polygon'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  // added March 12 2021
  {
    id: 'uniswap',
    name: 'Uniswap v1',
    address: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
    symbol: 'UNISWAP',
    logo: 'uniswap.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'OriginDollar',
    name: 'Origin Dollar',
    address: '0xe75d77b1865ae93c7eaa3040b038d7aa7bc02f70',
    symbol: 'OUSD',
    logo: 'originusd.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'OpynV2',
    name: 'Opyn V2',
    address: '0x7c06792af1632e77cb27a558dc0885338f4bdf8e',
    symbol: 'OpynV2',
    logo: 'opyn.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'Reflexer',
    name: 'Reflexer',
    address: '0xcc88a9d330da1133df3a7bd823b95e52511a6962',
    symbol: 'Reflexer',
    logo: 'reflexer.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'Vesper',
    name: 'Vesper',
    address: '0xa4f1671d3aee73c05b552d57f2d16d3cfcbd0217',
    symbol: 'Vesper',
    logo: 'vesper.jpeg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'Stake DAO',
    name: 'Stake DAO',
    address: '0xB17640796e4c27a39AF51887aff3F8DC0daF9567',
    symbol: 'StakeDAO',
    logo: 'stakedao.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'Liquity',
    name: 'Liquity',
    address: '0xA39739EF8b0231DbFA0DcdA07d7e29faAbCf4bb2',
    symbol: 'Liquity',
    logo: 'liquity.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'BenchmarkProtocol',
    name: 'Benchmark Protocol',
    address: '0x5D9972dD3Ba5602574ABeA6bF9E1713568D49903',
    symbol: 'BenchmarkProtocol',
    logo: 'benchmark-protocol.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'HarvestFinance',
    name: 'Harvest Finance',
    address: '0x284D7200a0Dabb05ee6De698da10d00df164f61d',
    symbol: 'HarvestFinance',
    logo: 'harvest.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'PancakeswapV1',
    name: 'Pancakeswap v1',
    address: '0x0000000000000000000000000000000000000005',
    symbol: 'PancakeswapV1',
    logo: 'pancakeswap.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'Thorchain',
    name: 'Thorchain',
    address: '0x0000000000000000000000000000000000000004',
    symbol: 'Thorchain',
    logo: 'thorchain.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'Venus',
    name: 'Venus',
    address: '0x0000000000000000000000000000000000000003',
    symbol: 'Venus',
    logo: 'venus.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'Bunny',
    name: 'Bunny',
    address: '0x0000000000000000000000000000000000000002',
    symbol: 'Bunny',
    logo: 'bunnypancake.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['bsc'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'Anchor',
    name: 'Anchor',
    address: '0x0000000000000000000000000000000000000001',
    symbol: 'Anchor',
    logo: 'anchor.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'terra'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'FTX',
    name: 'FTX',
    address: '0xC57d000000000000000000000000000000000011',
    symbol: 'FTX',
    logo: 'ftx.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: [],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'UniswapV3',
    name: 'Uniswap v3',
    address: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    symbol: 'UniswapV3',
    logo: 'uniswapv3.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'AlchemixV1',
    name: 'Alchemix v1',
    address: '0xc21D353FF4ee73C572425697f4F5aaD2109fe35b',
    symbol: 'Alchemix',
    logo: 'alchemix.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'ConvexFinanceV1',
    name: 'Convex Finance v1',
    address: '0xF403C135812408BFbE8713b5A23a04b3D48AAE31',
    symbol: 'Convex',
    logo: 'convex.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'BarnbridgeSmartYieldV1',
    name: 'Barnbridge Smart Yield v1',
    address: '0x4B8d90D68F26DEF303Dcb6CFc9b63A1aAEC15840',
    symbol: 'Barnbridge',
    logo: 'barnbridge.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'alpha-homora-v2',
    name: 'Alpha Homora v2',
    address: '0x99c666810bA4Bf9a4C2318CE60Cb2c279Ee2cF56',
    symbol: 'ALPHA-HOMORA',
    logo: 'alpha-homora.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'alpaca-finance',
    name: 'Alpaca Finance',
    address: '0xA625AB01B08ce023B2a342Dbb12a16f2C8489A8F',
    symbol: 'ALPACA',
    logo: 'alpaca.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'visor-finance',
    name: 'Visor Finance',
    address: '0x08FB62c84909dA3Aa5F59E01763E5FDC62De76e9',
    symbol: 'VISOR',
    logo: 'visor.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'goldfinch',
    name: 'Goldfinch',
    address: '0x8481a6EbAf5c7DABc3F7e09e44A89531fd31F822',
    symbol: 'GOLDFINCH',
    logo: 'goldfinch.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'centrifuge',
    name: 'Centrifuge Tinlake',
    address: '0x0CED6166873038Ac0cc688e7E6d19E2cBE251Bf0',
    symbol: 'CFG',
    logo: 'centrifuge.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'rari',
    name: 'Rari Capital',
    address: '0x835482FE0532f169024d5E9410199369aAD5C77E',
    symbol: 'RARI',
    logo: 'rari.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'traderjoe',
    name: 'Trader Joe',
    address: '0x60aE616a2155Ee3d9A68541Ba4544862310933d4',
    symbol: 'TRADERJOE',
    logo: 'trader-joe.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['avalanche'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'pooltogetherv4',
    name: 'Pool Together v4',
    address: '0xd89a09084555a7D0ABe7B111b1f78DFEdDd638Be',
    symbol: 'POOLTOGETHERV4',
    logo: 'pooltogether.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'polygon'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'reth-theta',
    name: 'Ribbon Finance v2',
    address: '0x25751853Eab4D0eB3652B5eB6ecB102A2789644B',
    symbol: 'rETH-THETA',
    logo: 'ribbon.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },

  {
    id: 'oldao',
    name: 'OlympusDAO',
    address: '0x575409F8d77c12B05feD8B455815f0e54797381c',
    symbol: 'OLDAO',
    logo: 'olympusdao.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'arbitrum', 'avalanche'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'notional-finance-v2',
    name: 'Notional Finance v2',
    address: '0x1344A36A1B56144C3Bc62E7757377D288fDE0369',
    symbol: 'NOTIONALFINANCEV2',
    logo: 'notional.png',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'plp',
    name: 'Popsicle Finance',
    address: '0xae7b92c8b14e7bdb523408ae0a6ffbf3f589add9',
    symbol: 'PLP',
    logo: 'popsicle.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc', 'fantom', 'avalanche', 'arbitrum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'premia-finance',
    name: 'Premia Finance',
    address: '0x48D49466CB2EFbF05FaA5fa5E69f2984eDC8d1D7',
    symbol: 'PREMIAFINANCE',
    logo: 'premia.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'abracadabra',
    name: 'Abracadabra',
    address: '0xd96f48665a1410C0cd669A88898ecA36B9Fc2cce',
    symbol: 'ABRACADABRA',
    logo: 'abracadabra.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['ethereum', 'bsc', 'fantom', 'avalanche', 'arbitrum'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
  {
    id: 'pangolin',
    name: 'Pangolin',
    address: '0xefa94DE7a4656D787667C749f7E1223D71E9FD88',
    symbol: 'PANGOLIN',
    logo: 'pangolin.svg',
    description: '',
    decimals: 18,
    balance: 0,
    supportedChains: ['avalanche'],
    capacity: {
      capacityETH: 0,
      capacityDAI: 0,
      netStakedNXM: 0,
    },
  },
]

export default protocolContracts
