import React from 'react'
import { withTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'
import ExternalIcon from '../../components/icons/ExternalIcon'

import {
  ModalTitle,
  TableWrapper,
  TableContainerStyled,
  TableStyledWrapper,
  TableStyled,
  TableTitle,
  Row,
  Cell,
  Input,
  InputInfo,
} from './styled'

const Step4 = ({ theme, t, currentStep, list, handleChange }) => {
  const { colors } = theme

  const handleChangeAmount = (e, i) => {
    const { value } = e.target
    if (value.match(/^[0-9]*\.?[0-9]*$/) && !isNaN(+value)) {
      let newArr = [...list]
      newArr[i].amount = value
      handleChange({ name: 'list', value: newArr })
    }
  }

  return currentStep === 4 ? (
    <>
      <ModalTitle bold>{t('ProofOfLoss.Step.4')}</ModalTitle>
      {list.length > 0 && (
        <TableWrapper>
          <TableContainerStyled component={Paper}>
            <TableStyledWrapper>
              <TableStyled aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableTitle align="left">#</TableTitle>
                    <TableTitle align="left">Address</TableTitle>
                    <TableTitle align="left">Amount</TableTitle>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map(({ address, amount }, i) => (
                    <Row key={i}>
                      <Cell>{i + 1}</Cell>
                      <Cell
                        address="true"
                        onClick={() =>
                          window.open(
                            `https://etherscan.io/address/${address}`,
                            '_blank'
                          )
                        }
                      >
                        {address} <ExternalIcon color={colors.active} />
                      </Cell>
                      <Cell>
                        <Input
                          value={amount}
                          onChange={(e) => handleChangeAmount(e, i)}
                          placeholder="0"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputInfo position="end">ETH</InputInfo>
                            ),
                          }}
                        />
                      </Cell>
                    </Row>
                  ))}
                </TableBody>
              </TableStyled>
            </TableStyledWrapper>
          </TableContainerStyled>
        </TableWrapper>
      )}
    </>
  ) : null
}

export default withTranslation()(withTheme(Step4))
