import styled from 'styled-components'

export const ActionContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 5px;
  flex-wrap: wrap;

  @media screen and (max-width: 350px) {
    flex-direction: column;
    align-items: center;
  }
`
export const MaxButton = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.active};
  cursor: pointer;
  padding: 3px;
  margin: 10px 0 0 auto;
  max-width: fit-content;
`
