import React from 'react'

const AboutInfoIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99984 2C4.68613 2 1.99984 4.68629 1.99984 8C1.99984 11.3137 4.68613 14 7.99984 14C11.3135 14 13.9998 11.3137 13.9998 8C13.9998 4.68629 11.3135 2 7.99984 2ZM0.666504 8C0.666504 3.94991 3.94975 0.666668 7.99984 0.666668C12.0499 0.666668 15.3332 3.94991 15.3332 8C15.3332 12.0501 12.0499 15.3333 7.99984 15.3333C3.94975 15.3333 0.666504 12.0501 0.666504 8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17199 5.34955C7.86165 5.29631 7.54247 5.35464 7.27101 5.51418C6.99954 5.67372 6.79329 5.92419 6.6888 6.22123C6.56662 6.56856 6.18601 6.75107 5.83869 6.62889C5.49136 6.50671 5.30884 6.1261 5.43103 5.77877C5.64001 5.1847 6.05249 4.68376 6.59543 4.36467C7.13836 4.04558 7.77671 3.92894 8.3974 4.03541C9.0181 4.14187 9.58108 4.46457 9.98665 4.94635C10.3921 5.42804 10.6141 6.03766 10.6132 6.66728C10.6129 7.68751 9.85654 8.36126 9.31638 8.72137C9.02596 8.91498 8.74028 9.05735 8.52984 9.15088C8.42367 9.19806 8.33427 9.23387 8.26985 9.25841C8.23758 9.2707 8.21142 9.28023 8.19238 9.287L8.16923 9.29511L8.16185 9.29763L8.15926 9.2985L8.15823 9.29885C8.15803 9.29891 8.1574 9.29912 7.94658 8.66667L8.1574 9.29912C7.8081 9.41556 7.43056 9.22678 7.31413 8.87749C7.19777 8.52842 7.38622 8.15115 7.73506 8.03445L7.73421 8.03473C7.73428 8.03471 7.73434 8.0347 7.73506 8.03445L7.7457 8.03071C7.75596 8.02707 7.77277 8.02097 7.79519 8.01243C7.84014 7.9953 7.90699 7.96861 7.98832 7.93246C8.15288 7.85933 8.3672 7.75169 8.57678 7.61197C9.03651 7.30548 9.27991 6.97943 9.27991 6.66667L9.27991 6.66568C9.28038 6.3508 9.1694 6.04591 8.96662 5.80502C8.76383 5.56413 8.48234 5.40278 8.17199 5.34955Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3335 11.3333C7.3335 10.9651 7.63197 10.6667 8.00016 10.6667H8.00683C8.37502 10.6667 8.6735 10.9651 8.6735 11.3333C8.6735 11.7015 8.37502 12 8.00683 12H8.00016C7.63197 12 7.3335 11.7015 7.3335 11.3333Z"
        fill={color}
      />
    </svg>
  )
}

export default AboutInfoIcon
