const formData = require('form-data');
const Mailgun = require('mailgun.js');
const mailgun = new Mailgun(formData);

export default class MailgunService {
  constructor(apiKey) {
    this.apiKey = apiKey
    this.client = mailgun.client({
      username: 'api',
      key: apiKey,
    });
  }

  send(to, subject, text, html) {
    this.client.messages.create('mg.armor.vip', {
      from: "Affected User <affected@mg.armor.vip>",
      to: [to],
      subject: subject,
      text: text,
      html: html
    })
      .then(msg => console.log(msg)) // logs response data
      .catch(err => console.log(err)); // logs any error
  }
}
