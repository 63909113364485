import styled from 'styled-components'

export const Panel = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 27px;
  background-color: ${(p) => p.theme.colors.defaultArrow};
  padding: 25px 15px;
  border-radius: 16px;
  align-items: stretch;
  @media screen and (max-width: 990px) {
    flex-wrap: wrap;
    padding: 0 15px 15px;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`
