import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
`
export const Cell = styled.div`
  background: ${(p) => p.theme.colors.defaultArrow};
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin: 20px 40px 0 40px;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  padding: 2px;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    margin: 20px 10px 0;
  }
`
export const Logo = styled.img`
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
`
