import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'

export const TableStyled = styled(Table)`
  @media screen and (max-width: 850px) {
    width: 840px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    width: 810px;
  }
`
export const CheckboxTitle = styled(TableCell)`
  padding: 2px 0px 6px 6px;
  @media screen and (max-width: 600px) {
    padding: 2px 0px 6px 6px;
  }
`
export const CropSpan = styled.span`
  max-width: 108px;
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0 !important;
  display: block !important;
`
export const AvailableCell = styled.div`
  display: flex;
  align-items: center;
  & span {
    display: flex;
    margin-left: 5px;
  }
  & svg {
    height: 16px;
    width: 16px;
  }
`
export const ActionCell = styled(TableCell)`
  padding: 9px 7px;
  font-family: 'Open Sans', sans-serif;
  @media screen and (max-width: 600px) {
    padding: 9px 5px;
  }
`
export const CoverButton = styled(Button)`
  border: 1px solid
    ${(p) => (p.delete ? p.theme.colors.error : p.theme.colors.buttonActiveBg)};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px;
  min-width: unset;
  margin-left: ${(p) => (p.delete ? '9px' : '0')};
  color: ${(p) =>
    p.delete ? p.theme.colors.error : p.theme.colors.buttonActiveBg};
  & svg {
    width: 15px;
    height: 15px;
  }
`
export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const HeaderActions = styled.div`
  & button {
    margin: 0 5px;

    &:last-of-type {
      margin-right: 0;
    }

    @media screen and (max-width: 650px) {
      margin-top: 15px;
      &:last-of-type {
        margin-left: 0;
      }
    }
  }
  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & button {
      margin-top: 15px;
      &:last-of-type {
        margin-left: 0;
      }
    }
  }
`
