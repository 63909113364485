import React, { useState } from 'react'
import { withTheme } from 'styled-components'
import { withTranslation } from 'react-i18next'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'
import ExternalIcon from '../../components/icons/ExternalIcon'
import TrashIcon from '../../components/icons/TrashIcon'
import {
  ModalTitle,
  ActionContainer,
  MainInfo,
  Input,
  ActiveBtn,
  ActiveBtnText,
  TableWrapper,
  TableContainerStyled,
  TableStyledWrapper,
  TableStyled,
  TableTitle,
  Row,
  Cell,
  CoverButton,
  RightSpace,
} from './styled'
import { isAddress } from 'web3-utils'

const Step2 = ({ t, theme, currentStep, handleChange, isLoading }) => {
  const [address, setAddress] = useState('')
  const [addressError, setAddressError] = useState(false)
  const [list, setList] = useState([])
  const { colors } = theme

  const handleChangeAddress = (e) => {
    const { value } = e.target
    setAddressError(false)
    setAddress(value)
  }

  const isAffectedAddressAlreadyAdded = (_affectedAddress) => {
    const _affectedAddressIndex = list.findIndex(
      (a) => a.address.toLowerCase() === _affectedAddress.toLowerCase()
    )
    return _affectedAddressIndex >= 0
  }

  const handleAddToList = () => {
    setAddressError(false)
    if (
      !address ||
      !isAddress(address) ||
      isAffectedAddressAlreadyAdded(address)
    ) {
      setAddressError(true)
      return
    }

    const defList = {
      address: address,
      amount: '',
    }
    const newData = [...list, defList]
    setList(newData)
    handleChange({ name: 'list', value: newData })
    resetValues()
  }

  const resetValues = () => {
    setAddress('')
  }

  const removeSelectedAddress = (_address) => {
    const newArr = list.filter(
      (x) => x.address.toLowerCase() !== _address.toLowerCase()
    )
    setList(newArr)
    handleChange({ name: 'list', value: newArr })
  }

  return currentStep === 2 ? (
    <>
      <ModalTitle bold>{t('ProofOfLoss.Step.2')}</ModalTitle>
      {list.length > 0 && (
        <TableWrapper>
          <TableContainerStyled component={Paper}>
            <TableStyledWrapper>
              <TableStyled aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableTitle align="left">#</TableTitle>
                    <TableTitle align="left">Address</TableTitle>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map(({ address, amount }, i) => (
                    <Row key={i}>
                      <Cell>{i + 1}</Cell>
                      <Cell
                        address="true"
                        onClick={() =>
                          window.open(
                            `https://etherscan.io/address/${address}`,
                            '_blank'
                          )
                        }
                      >
                        {address} <ExternalIcon color={colors.active} />
                      </Cell>
                      <Cell>
                        <CoverButton
                          color="primary"
                          onClick={() => removeSelectedAddress(address)}
                          delete="true"
                        >
                          <TrashIcon color={colors.error} />
                        </CoverButton>
                      </Cell>
                    </Row>
                  ))}
                </TableBody>
              </TableStyled>
            </TableStyledWrapper>
          </TableContainerStyled>
        </TableWrapper>
      )}
      <MainInfo>
        <ActionContainer>
          <Input
            address="true"
            value={address}
            onChange={handleChangeAddress}
            disabled={isLoading}
            error={addressError || isAffectedAddressAlreadyAdded(address)}
            placeholder="0x0000000000000000000000000000000000000000"
            variant="outlined"
            helperText={
              <>
                {isAffectedAddressAlreadyAdded(address)
                  ? 'Address is already added'
                  : addressError && 'Address is not valid'}
              </>
            }
          />
          <RightSpace />
          <ActiveBtn
            addbutton="true"
            variant="contained"
            color="primary"
            disabled={
              isLoading ||
              !address ||
              isAffectedAddressAlreadyAdded(address) ||
              addressError
            }
            onClick={handleAddToList}
          >
            <ActiveBtnText>Add</ActiveBtnText>
          </ActiveBtn>
        </ActionContainer>
      </MainInfo>
    </>
  ) : null
}

export default withTranslation()(withTheme(Step2))
