import React from 'react'

const UpdateIcon = ({ color }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 314.154 314.154"
      xmlSpace="preserve"
    >
      <path
        d="M307.475,141.686c-6.893-4.598-16.207-2.736-20.802,4.157l-0.446,0.669c-5.426-74.107-67.45-132.745-142.918-132.745
	C64.288,13.768,0,78.056,0,157.077s64.288,143.309,143.309,143.309c11.046,0,20-8.954,20-20s-8.954-20-20-20
	C86.344,260.385,40,214.041,40,157.077S86.344,53.768,143.309,53.768c52.798,0,96.465,39.815,102.571,90.996
	c-4.813-5.991-13.486-7.422-20-3.078c-6.893,4.596-8.754,13.91-4.158,20.802l29.702,44.541c3.505,5.256,9.208,8.394,15.255,8.394
	c6.047,0,11.749-3.139,15.252-8.394l29.702-44.541C316.228,155.595,314.367,146.282,307.475,141.686z"
        fill={color}
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default UpdateIcon
