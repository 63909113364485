import React from 'react'

const ArmorIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <circle cx="12" cy="12.5" r="12" fill="#1890FF" />
        <path
          d="M12.2171 14.7052L12.2173 14.705L15.6028 12.0494C15.647 12.0147 15.6728 11.9615 15.6728 11.9052V10.7332C15.6728 10.6382 15.5642 10.5847 15.4898 10.6431L12.0014 13.3794L8.51003 10.6408C8.43558 10.5824 8.32703 10.6359 8.32703 10.7309V11.9052C8.32703 11.9616 8.35283 12.0148 8.39697 12.0494L11.7828 14.7053L11.783 14.7054C11.8443 14.7531 11.9219 14.7769 11.9999 14.7769C12.0774 14.7769 12.1568 14.7533 12.2171 14.7052Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3457 7.48478L17.3455 7.48472L12.2428 5.73419C12.1749 5.71087 12.087 5.69999 12.0014 5.69999C11.9158 5.69999 11.8278 5.71087 11.76 5.73419L6.65751 7.48462L6.65742 7.48465C6.58428 7.50951 6.52035 7.56334 6.47494 7.62764C6.42949 7.69198 6.3999 7.77058 6.3999 7.84837V15.1405C6.3999 15.2159 6.42385 15.3 6.46025 15.3751C6.49667 15.4502 6.54775 15.5209 6.6062 15.5678L6.6064 15.5679L11.7828 19.6284L11.783 19.6285C11.8443 19.6762 11.9219 19.7 11.9999 19.7C12.0774 19.7 12.1568 19.6764 12.2171 19.6282L12.2173 19.6281L17.3931 15.5681L17.3932 15.568C17.4519 15.5218 17.503 15.4513 17.5395 15.3762C17.5759 15.301 17.5998 15.2166 17.5998 15.1405V7.84886C17.6015 7.77081 17.5725 7.69245 17.5275 7.62831C17.4824 7.56402 17.4185 7.51037 17.3457 7.48478ZM16.431 8.41921V14.828L12.0014 18.3025L7.57178 14.828V8.41921L12.0014 6.89894L16.431 8.41921Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArmorIcon
