import styled from 'styled-components'
import Button from '@material-ui/core/Button'

export const ButtonText = styled.h5`
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: ${(p) => p.theme.colors.secondary};
  display: flex;
  align-items: center;
  & svg {
    margin-left: 8px;
    height: 20px;
    width: 20px;
  }
`
export const CurrentRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px 0;
  }
`
export const ActionButton = styled(Button)`
  border-radius: 6px;
  padding: 3px 10px;
  min-height: 28px;
  background: ${(p) => p.theme.colors.buttonActiveBg};
  box-shadow: none;
  &.Mui-disabled {
    background: ${(p) => p.theme.colors.strongDefault};
  }
  &:hover {
    background: ${(p) => p.theme.colors.buttonActiveBg};
  }
`
export const Wrapper = styled.div`
  width: 100%;
  background: ${(p) => p.theme.colors.defaultArrow};
  border-radius: 16px;
  margin-top: 23px;
  overflow: hidden;
`
export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`
export const Info = styled.h6`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${(p) =>
    p.active ? p.theme.colors.activeSearch : p.theme.colors.secondary};
  margin-top: 10px;
  position: relative;
  z-index: 1;
`
