import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Container from '../../components/common/container/Container'
import { Title } from '../../components/common/Title'
import Store from '../../stores/store'
import cnf from '../../config/cnf'

import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  ERROR,
} from '../../constants'
import { removeEmitterListeners, turnOnEmitterListeners } from '../../helpers'
import { Panel } from '../../components/common/Panel'
import { Description } from './styled'
import SwapArmorToVArmor from './components/SwapArmorToVArmor'
import StatsPanel from './components/StatsPanel'
import SwapVArmorToArmor from './components/SwapVArmorToArmor'
import { VArmorEvents } from '../../stores/contracts/vArmor/vArmorEvents'
import { ArmorTokenEvents } from '../../stores/contracts/armorToken/armorTokenEvents'
import { PanelBox, PanelContainer } from './components/PanelBox'
import { fromWei } from 'web3-utils'
import moment from 'moment'
const store = Store.store
const emitter = Store.emitter
const dispatcher = Store.dispatcher

const Govern = ({ theme, network, t }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [account, setAccount] = useState(null)
  const [armorBalance, setArmorBalance] = useState('0')
  const [vArmorBalance, setVArmorBalance] = useState('0')
  const [vArmorDelegate, setVArmorDelegate] = useState(false)
  const [managedAssets, setManagedAssets] = useState('0')
  const [conversions, setConversions] = useState({
    armorToVArmor: '0',
    vArmorToArmor: '0',
  })
  const [withdrawRequest, setWithdrawRequest] = useState({
    amount: '0',
    delay: '0',
    time: '0',
  })

  useEffect(() => {
    setIsLoading(true)
    const _account = store.getStore('account')
    setAccount(_account)

    dispatcher.dispatch({
      type: VArmorEvents.GetConversions,
      content: {},
    })
    dispatchUpdates()

    const connectionConnected = async () => {
      const _account = store.getStore('account')
      setAccount(_account)
      if (_account && _account.address) {
      }
    }

    const connectionDisconnected = () => setAccount(null)

    const errorReturned = (error) => setIsLoading(false)

    let events = [
      [ERROR, errorReturned],
      [CONNECTION_CONNECTED, connectionConnected],
      [CONNECTION_DISCONNECTED, connectionDisconnected],
      [VArmorEvents.ConversionsReturned, conversionsReturned],
      [VArmorEvents.BalanceReturned, vArmorBalanceReturned],
      [VArmorEvents.WithdrawRequestsReturned, withdrawRequestReturned],
      [VArmorEvents.RequestWithdrawalCompleted, dispatchUpdates],
      [VArmorEvents.DelegateReturned, delegateReturned],
      [VArmorEvents.FinalizeWithdrawCompleted, dispatchUpdates],
      [ArmorTokenEvents.VArmorManagedAssetsReturned, managedAssetsReturned],
      [ArmorTokenEvents.BalanceReturned, armorTokenBalanceReturned],
    ]
    turnOnEmitterListeners(emitter, events)

    return () => {
      removeEmitterListeners(emitter, events)
    }
  }, [network, account])

  const dispatchUpdates = () => {
    dispatcher.dispatch({
      type: ArmorTokenEvents.GetBalance,
      content: {},
    })
    dispatcher.dispatch({
      type: VArmorEvents.GetBalance,
      content: {},
    })
    dispatcher.dispatch({
      type: ArmorTokenEvents.GetVArmorManagedAssets,
      content: {
        address: cnf.VARMOR_ADDRESS,
      },
    })
    dispatcher.dispatch({
      type: VArmorEvents.GetWithdrawRequests,
      content: {},
    })
    dispatcher.dispatch({
      type: VArmorEvents.GetDelegate,
      content: {},
    })
  }

  const hasPendingWithdrawal = () => {
    return typeof withdrawRequest === 'object' && withdrawRequest.time !== '0'
  }

  const isReadyToWithdraw = () => {
    if (withdrawRequest.time === '0') return false

    return moment.unix(withdrawRequest.time).utc().unix() < moment.utc().unix()
  }

  const dateTimeOfWithdraw = () =>
    moment.unix(withdrawRequest.time).utc().format('MMM Do [at] HH:mm [UTC]')

  const conversionsReturned = () => {
    const _conversions = store.getStore('VArmor_Conversions')
    setConversions(_conversions)
  }

  const vArmorBalanceReturned = () => {
    const _vArmorBalance = store.getStore('VArmorEvents_Balance')
    setVArmorBalance(_vArmorBalance)
  }

  const managedAssetsReturned = () => {
    const _managedAssets = store.getStore('ArmorToken_ManagedAssetsBalance')
    setManagedAssets(_managedAssets)
  }

  const armorTokenBalanceReturned = () => {
    const _armorBalance = store.getStore('ArmorToken_Balance')
    setArmorBalance(_armorBalance)
  }

  const withdrawRequestReturned = () => {
    const _request = store.getStore('VArmor_WithdrawRequests')
    setWithdrawRequest(_request)
    setIsLoading(false)
  }

  const delegateReturned = () => {
    const _isDelegate = store.getStore('VArmorEvents_delegate')
    setVArmorDelegate(_isDelegate)
  }

  return (
    <Container noaccount={!account}>
      <Title>vArmor</Title>
      <Description>
        Stake your ARMOR tokens to receive yield-bearing vARMOR governance
        tokens.
        <br />
        You can use your vArmor to{' '}
        <a
          href="https://www.withtally.com/governance/armor"
          target="_blank"
          rel="noopener noreferrer"
        >
          vote on DAO proposals here
        </a>
        .
      </Description>
      <Panel>
        <SwapArmorToVArmor
          theme={theme}
          armorBalance={armorBalance}
          conversions={conversions}
          onDepositCompleted={() => dispatchUpdates()}
        />
        {(!hasPendingWithdrawal() || isReadyToWithdraw()) && (
          <SwapVArmorToArmor
            theme={theme}
            vArmorBalance={vArmorBalance}
            conversions={conversions}
            withdrawRequest={withdrawRequest}
            vArmorDelegate={vArmorDelegate}
          />
        )}

        {!isLoading && hasPendingWithdrawal() && !isReadyToWithdraw() && (
          <>
            <PanelContainer>
              <PanelBox>
                <span style={{ color: 'white' }}>
                  You currently have a pending withdraw request initiated on{' '}
                  <b>{dateTimeOfWithdraw()}</b> for{' '}
                  <b>{fromWei(withdrawRequest.amount)}</b> Armor
                </span>
              </PanelBox>
            </PanelContainer>
          </>
        )}
      </Panel>

      <StatsPanel conversions={conversions} managedAssets={managedAssets} />
    </Container>
  )
}

export default withTranslation()(withRouter(Govern))
