const arShieldController = (address) =>
  Object.freeze({
    address: address,
    abi: [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousGovernor',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newGovernor',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'referral',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'shield',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'refFee',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'bool',
            name: 'mint',
            type: 'bool',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256',
          },
        ],
        name: 'ShieldAction',
        type: 'event',
      },
      {
        inputs: [],
        name: 'beneficiary',
        outputs: [
          {
            internalType: 'address payable',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'bonus',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address payable',
            name: '_beneficiary',
            type: 'address',
          },
        ],
        name: 'changeBeneficiary',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_newBonus',
            type: 'uint256',
          },
        ],
        name: 'changeBonus',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_depositAmt',
            type: 'uint256',
          },
        ],
        name: 'changeDepositAmt',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_refFee',
            type: 'uint256',
          },
        ],
        name: 'changeRefFee',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_armorToken',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_token',
            type: 'address',
          },
          {
            internalType: 'address payable',
            name: '_beneficiary',
            type: 'address',
          },
        ],
        name: 'claimTokens',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'string',
            name: '_name',
            type: 'string',
          },
          {
            internalType: 'string',
            name: '_symbol',
            type: 'string',
          },
          {
            internalType: 'address',
            name: '_oracle',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_pToken',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_uTokenLink',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_masterCopy',
            type: 'address',
          },
          {
            internalType: 'uint256[]',
            name: '_fees',
            type: 'uint256[]',
          },
          {
            internalType: 'address[]',
            name: '_covBases',
            type: 'address[]',
          },
        ],
        name: 'createShield',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_shield',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_idx',
            type: 'uint256',
          },
        ],
        name: 'deleteShield',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'depositAmt',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_user',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_referral',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_shield',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_pToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_amount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_refFee',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: '_mint',
            type: 'bool',
          },
        ],
        name: 'emitAction',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_user',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_start',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_end',
            type: 'uint256',
          },
        ],
        name: 'getBalances',
        outputs: [
          {
            internalType: 'address[]',
            name: 'tokens',
            type: 'address[]',
          },
          {
            internalType: 'uint256[]',
            name: 'balances',
            type: 'uint256[]',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getShields',
        outputs: [
          {
            internalType: 'address[]',
            name: 'shields',
            type: 'address[]',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getTokens',
        outputs: [
          {
            internalType: 'address[]',
            name: 'tokens',
            type: 'address[]',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'governor',
        outputs: [
          {
            internalType: 'address payable',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_bonus',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_refFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_depositAmt',
            type: 'uint256',
          },
        ],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'isGov',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'receiveOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'refFee',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: 'shieldMapping',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address payable',
            name: 'newGovernor',
            type: 'address',
          },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        stateMutability: 'payable',
        type: 'receive',
      },
    ],
  })

export default arShieldController
