import React from 'react'
import { withTheme } from 'styled-components'
import Slide from '@material-ui/core/Slide'
import { Overlay, ModalFront, Wrapper } from './styled'

const StickyModal = ({ children, isModalOpened, width = '460' }) => {
  return (
    <>
      {isModalOpened && (
        <Wrapper>
          <Overlay />
          <Slide direction="up" in={isModalOpened}>
            <ModalFront width={width}>{children}</ModalFront>
          </Slide>
        </Wrapper>
      )}
    </>
  )
}

export default withTheme(StickyModal)
