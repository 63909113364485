export default [
  {
    name: 'Uniswap V2',
    logo: 'uniswapv2.png',
    lpUrl:
      'https://app.uniswap.org/#/swap?inputCurrency=0x1337def18c680af1f9f45cbcab6309562975b1dd&outputCurrency=ETH',
  },
  {
    name: 'SushiSwap',
    logo: 'sushiswap.jpg',
    lpUrl:
      'https://app.sushi.com/swap?inputCurrency=0x1337def18c680af1f9f45cbcab6309562975b1dd&outputCurrency=ETH',
  },
  {
    name: '1Inch (DEX & Liquidity Pools)',
    logo: '1inch.png',
    lpUrl: 'https://app.1inch.io/#/1/swap/arNXM/ETH',
  },
  {
    name: 'Balancer',
    logo: 'balancer.svg',
    lpUrl:
      'https://pools.balancer.exchange/#/pool/0xdb942c0851774bd817e6f4813f1fa64cce6fe25f/',
  },
]
