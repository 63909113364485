import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { ModalTitle } from './styled'
import { ActionContainer, Input, MainInfo } from './styled'
import { isAddress } from 'web3-utils'

const Step3 = ({ t, currentStep, handleChange }) => {
  const [address, setAddress] = useState('')
  const [addressError, setAddressError] = useState(false)

  const handleChangeAddress = (e) => {
    const { value } = e.target
    setAddress(value)
    if (!isAddress(value) && value !== '') {
      setAddressError(true)
      handleChange({ name: 'armorAddress', value: '' })
      return
    }
    setAddressError(false)
    handleChange({ name: 'armorAddress', value: value })
  }

  return currentStep === 3 ? (
    <>
      <ModalTitle bold>{t('ProofOfLoss.Step.3')}</ModalTitle>
      <MainInfo>
        <ActionContainer>
          <Input
            address="true"
            value={address}
            onChange={handleChangeAddress}
            error={addressError}
            placeholder="0x0000000000000000000000000000000000000000"
            variant="outlined"
            helperText={<>{addressError && 'Address is not valid'}</>}
          />
        </ActionContainer>
      </MainInfo>
    </>
  ) : null
}

export default withTranslation()(Step3)
