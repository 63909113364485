import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'

export const ActionWrapper = styled.div`
  @media screen and (max-width: 650px) {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    & button {
      margin-right: 0;
      margin-top: 15px;
    }
  }
  @media screen and (max-width: 350px) {
    & button {
      font-size: 10px;
    }
  }
`
export const CoveredIconWrapper = styled.span`
  max-width: 24px;
  width: 100%;
  height: 24px;
  margin-right: 12px;
  & svg {
    max-width: 24px;
    min-width: 24px;
    width: 100%;
    height: 24px;
  }
`
export const TableStyled = styled(Table)`
  @media screen and (max-width: 850px) {
    width: 860px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    width: 830px;
  }
`
export const CropSpan = styled.span`
  max-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0 !important;
  display: block !important;
`
export const ActionCell = styled(TableCell)`
  padding: 0 7px;
  font-family: 'Open Sans', sans-serif;
  & svg {
    height: 24px;
    width: 24px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 5px;
  }
`
export const CoverButton = styled(Button)`
  border: 1px solid
    ${(p) => (p.edit ? p.theme.colors.buttonActiveBg : p.theme.colors.error)};
  box-sizing: border-box;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  padding: 5px;
  min-width: unset;
  color: ${(p) =>
    p.edit ? p.theme.colors.buttonActiveBg : p.theme.colors.error};
  margin-right: ${(p) => (p.edit ? '9px' : '0')};
  &.MuiButton-root.Mui-disabled {
    color: ${(p) =>
      p.edit ? p.theme.colors.buttonActiveBg : p.theme.colors.error};
    opacity: 0.2;
  }
  & svg {
    width: 15px;
    height: 15px;
  }
`
export const SpanValue = styled.span`
  font-weight: normal;
  margin-left: 5px;
`
export const FooterInfo = styled.div`
  background: ${(p) => p.theme.colors.transparentBg};
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 10px 16px;
  & svg {
    min-width: 16px;
    max-width: 16px;
    width: 100%;
    height: 16px;
  }
`
export const FooterInfoText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  margin-left: 6px;
  color: ${(p) => p.theme.colors.disabledText};
`
export const CoveredCell = styled.div`
  display: flex;
  align-items: center;
`
export const CheckboxTitle = styled(TableCell)`
  padding: 2px 0px 2px 6px;
  @media screen and (max-width: 600px) {
    padding: 2px 0px 2px 6px;
  }
`
export const FlexTableTitle = styled.div`
  display: flex;
  align-items: center;
`
export const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
