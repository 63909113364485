import balanceManager from './balance-manager'
import claimManager from './claim-manager'
import planManager from './plan-manager'
import rewardManager from './reward-manager'
import stakeManager from './stake-manager'
import armorToken from './armor-token'
import arNFT from './arnft'
import ibco from './ibco'
import erc20 from './erc20'
import yInsure from './yInsure'
import quotation from './quotation'
import arNFTv1 from './arNFTv1'
import arNFTv2 from './arNFTv2'
import rarible from './rarible'
import arNXMVault from './arNXMVault'
import wNXM from './wNXM'
import arNXMToken from './arNXMToken'
import lpFarm from './lp-farm'
import lpToken from './lp-token'
import NXM from './NXM'
import armorMaster from './armor-master'
import utilizationFarm from './utilization-farm'
import UniAbi from './uni-abi'
import BalAbi from './bal-abi'
import nexusMutualStaking from './nexus-mutual-staking'
import referralRewards from './referral-rewards'
import chains from './chains'
import cnf from './cnf'
import arShields from './arsheilds'
import arShieldsCoverageBase from './arshields-coverage-base'
import arShieldController from './arshield-controller'
import rewardManagerV2 from './reward-manager-v2'
import vArmor from './varmor'

const config = {
  isProd: process.env.NODE_ENV === 'production',
  isDev: process.env.NODE_ENV === 'development',
  web3RequestsConcurrency: 5,
  infuraProvider: cnf.PROVIDER,
  nexusMutualAPI: cnf.NEXUS_MUTUAL_API,
  nexusMutualKey: cnf.NEXUS_MUTUAL_KEY,
  backendUrl: cnf.BACKEND_URL,
  nexusMutualConstants: {
    lowRiskCostLimit: 50000,
    stakedRiskCostLow: 0.02,
    stakedRiskCostHigh: 1,
    surplusMargin: 30,
  },
  chains,
  erc20ABI: [
    {
      constant: false,
      inputs: [
        { name: '_spender', type: 'address' },
        { name: '_value', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ name: 'success', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_to', type: 'address' },
        { name: '_value', type: 'uint256' },
      ],
      name: 'showMeTheMoney',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_to', type: 'address' },
        { name: '_value', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ name: 'success', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_from', type: 'address' },
        { name: '_to', type: 'address' },
        { name: '_value', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [{ name: 'success', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: '_from', type: 'address' },
        { indexed: true, name: '_to', type: 'address' },
        { indexed: false, name: '_value', type: 'uint256' },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: '_owner', type: 'address' },
        { indexed: true, name: '_spender', type: 'address' },
        { indexed: false, name: '_value', type: 'uint256' },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      constant: true,
      inputs: [
        { name: '_owner', type: 'address' },
        { name: '_spender', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ name: 'remaining', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'decimals',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'name',
      outputs: [{ name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [{ name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'totalSupply',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],

  yInsureAddress: cnf.YINSURE_ADDRESS,
  yInsureABI: [
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_distributorFeePercentage',
          type: 'uint256',
        },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'approved',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'receiver',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'bytes4',
          name: 'currency',
          type: 'bytes4',
        },
      ],
      name: 'ClaimRedeemed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    { payable: true, stateMutability: 'payable', type: 'fallback' },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'baseURI',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        {
          internalType: 'address',
          name: 'coveredContractAddress',
          type: 'address',
        },
        { internalType: 'bytes4', name: 'coverCurrency', type: 'bytes4' },
        { internalType: 'uint256[]', name: 'coverDetails', type: 'uint256[]' },
        { internalType: 'uint16', name: 'coverPeriod', type: 'uint16' },
        { internalType: 'uint8', name: '_v', type: 'uint8' },
        { internalType: 'bytes32', name: '_r', type: 'bytes32' },
        { internalType: 'bytes32', name: '_s', type: 'bytes32' },
      ],
      name: 'buyCover',
      outputs: [],
      payable: true,
      stateMutability: 'payable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'distributorFeePercentage',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'getApproved',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'getCoverStatus',
      outputs: [
        { internalType: 'uint8', name: 'coverStatus', type: 'uint8' },
        { internalType: 'bool', name: 'payoutCompleted', type: 'bool' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getMemberRoles',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'operator', type: 'address' },
      ],
      name: 'isApprovedForAll',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'isOwner',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'nxMaster',
      outputs: [
        { internalType: 'contract INXMMaster', name: '', type: 'address' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_spender', type: 'address' },
        { internalType: 'uint256', name: '_value', type: 'uint256' },
      ],
      name: 'nxmTokenApprove',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'ownerOf',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'redeemClaim',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        { internalType: 'bytes', name: '_data', type: 'bytes' },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
      name: 'sellNXMTokens',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'bool', name: 'approved', type: 'bool' },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'submitClaim',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_newMembership', type: 'address' },
      ],
      name: 'switchMembership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
      name: 'tokenByIndex',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'uint256', name: 'index', type: 'uint256' },
      ],
      name: 'tokenOfOwnerByIndex',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'tokenURI',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'tokens',
      outputs: [
        {
          internalType: 'uint256',
          name: 'expirationTimestamp',
          type: 'uint256',
        },
        { internalType: 'bytes4', name: 'coverCurrency', type: 'bytes4' },
        { internalType: 'uint256', name: 'coverAmount', type: 'uint256' },
        { internalType: 'uint256', name: 'coverPrice', type: 'uint256' },
        { internalType: 'uint256', name: 'coverPriceNXM', type: 'uint256' },
        { internalType: 'uint256', name: 'expireTime', type: 'uint256' },
        { internalType: 'uint256', name: 'generationTime', type: 'uint256' },
        { internalType: 'uint256', name: 'coverId', type: 'uint256' },
        { internalType: 'bool', name: 'claimInProgress', type: 'bool' },
        { internalType: 'uint256', name: 'claimId', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        {
          internalType: 'address payable',
          name: '_recipient',
          type: 'address',
        },
        { internalType: 'uint256', name: '_amount', type: 'uint256' },
      ],
      name: 'withdrawEther',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        {
          internalType: 'address payable',
          name: '_recipient',
          type: 'address',
        },
        { internalType: 'uint256', name: '_amount', type: 'uint256' },
        { internalType: 'bytes4', name: '_currency', type: 'bytes4' },
      ],
      name: 'withdrawTokens',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      name: 'withdrawableTokens',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],

  quotationAddress: cnf.QUOTATION_ADDRESS,
  quotationABI: [
    {
      constant: false,
      inputs: [],
      name: 'changeDependentContractAddress',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: '_add', type: 'address' }],
      name: 'changeAuthQuoteEngine',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_cid', type: 'uint256' }],
      name: 'getCoverSumAssured',
      outputs: [{ name: 'sa', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getProductDetails',
      outputs: [
        { name: '_minDays', type: 'uint256' },
        { name: '_pm', type: 'uint256' },
        { name: '_stl', type: 'uint256' },
        { name: '_stlp', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'stl',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: 'from', type: 'address' },
        { name: 'scAddress', type: 'address' },
        { name: 'coverCurr', type: 'bytes4' },
        { name: 'coverDetails', type: 'uint256[]' },
        { name: 'coverPeriod', type: 'uint16' },
      ],
      name: 'addHoldCover',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'stlp',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_cid', type: 'uint256' }],
      name: 'getCoverDetailsByCoverID2',
      outputs: [
        { name: 'cid', type: 'uint256' },
        { name: 'status', type: 'uint8' },
        { name: 'sumAssured', type: 'uint256' },
        { name: 'coverPeriod', type: 'uint16' },
        { name: 'validUntil', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_add', type: 'address' },
        { name: '_curr', type: 'bytes4' },
        { name: '_amount', type: 'uint256' },
      ],
      name: 'addInTotalSumAssuredSC',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_cid', type: 'uint256' }],
      name: 'getCoverMemberAddress',
      outputs: [{ name: '_add', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_curr', type: 'bytes4' }],
      name: 'getTotalSumAssured',
      outputs: [{ name: 'amount', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_hcid', type: 'uint256' }],
      name: 'getHoldedCoverDetailsByID1',
      outputs: [
        { name: 'hcid', type: 'uint256' },
        { name: 'scAddress', type: 'address' },
        { name: 'coverCurr', type: 'bytes4' },
        { name: 'coverPeriod', type: 'uint16' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_cid', type: 'uint256' }],
      name: 'getCoverStatusNo',
      outputs: [{ name: '', type: 'uint8' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { name: '_add', type: 'address' },
        { name: '_curr', type: 'bytes4' },
      ],
      name: 'getTotalSumAssuredSC',
      outputs: [{ name: 'amount', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_add', type: 'address' }],
      name: 'getUserCoverLength',
      outputs: [{ name: 'len', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: '_add', type: 'address' }],
      name: 'setKycAuthAddress',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_curr', type: 'bytes4' },
        { name: '_amount', type: 'uint256' },
      ],
      name: 'subFromTotalSumAssured',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: 'code', type: 'bytes8' }],
      name: 'getUintParameters',
      outputs: [
        { name: 'codeVal', type: 'bytes8' },
        { name: 'val', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getCoverLength',
      outputs: [{ name: 'len', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: 'holdedCoverID', type: 'uint256' },
        { name: 'status', type: 'uint256' },
      ],
      name: 'setHoldedCoverIDStatus',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_cid', type: 'uint256' }],
      name: 'getValidityOfCover',
      outputs: [{ name: 'date', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_cid', type: 'uint256' }],
      name: 'getCoverPeriod',
      outputs: [{ name: 'cp', type: 'uint32' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_add', type: 'address' },
        { name: '_curr', type: 'bytes4' },
        { name: '_amount', type: 'uint256' },
      ],
      name: 'subFromTotalSumAssuredSC',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: '_timestamp', type: 'uint256' }],
      name: 'setTimestampRepeated',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'kycAuthAddress',
      outputs: [{ name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'pm',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_cid', type: 'uint256' }],
      name: 'getCurrencyOfCover',
      outputs: [{ name: 'curr', type: 'bytes4' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_coverPeriod', type: 'uint16' },
        { name: '_sumAssured', type: 'uint256' },
        { name: '_userAddress', type: 'address' },
        { name: '_currencyCode', type: 'bytes4' },
        { name: '_scAddress', type: 'address' },
        { name: 'premium', type: 'uint256' },
        { name: 'premiumNXM', type: 'uint256' },
      ],
      name: 'addCover',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_cid', type: 'uint256' }],
      name: 'getscAddressOfCover',
      outputs: [
        { name: '', type: 'uint256' },
        { name: '', type: 'address' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '', type: 'uint256' }],
      name: 'timestampRepeated',
      outputs: [{ name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: 'code', type: 'bytes8' },
        { name: 'val', type: 'uint256' },
      ],
      name: 'updateUintParameters',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'ms',
      outputs: [{ name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_curr', type: 'bytes4' },
        { name: '_amount', type: 'uint256' },
      ],
      name: 'addInTotalSumAssured',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { name: '', type: 'address' },
        { name: '', type: 'uint256' },
      ],
      name: 'userHoldedCover',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_cid', type: 'uint256' },
        { name: '_stat', type: 'uint8' },
      ],
      name: 'changeCoverStatusNo',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '', type: 'uint256' }],
      name: 'coverStatus',
      outputs: [{ name: '', type: 'uint8' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_cid', type: 'uint256' }],
      name: 'getCoverPremiumNXM',
      outputs: [{ name: '_premiumNXM', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '', type: 'uint256' }],
      name: 'holdedCoverIDStatus',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_add', type: 'address' }],
      name: 'getUserHoldedCoverLength',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_hcid', type: 'uint256' }],
      name: 'getHoldedCoverDetailsByID2',
      outputs: [
        { name: 'hcid', type: 'uint256' },
        { name: 'memberAddress', type: 'address' },
        { name: 'coverDetails', type: 'uint256[]' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: '_masterAddress', type: 'address' }],
      name: 'changeMasterAddress',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '', type: 'address' }],
      name: 'refundEligible',
      outputs: [{ name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_cid', type: 'uint256' }],
      name: 'getCoverDetailsByCoverID1',
      outputs: [
        { name: 'cid', type: 'uint256' },
        { name: '_memberAddress', type: 'address' },
        { name: '_scAddress', type: 'address' },
        { name: '_currencyCode', type: 'bytes4' },
        { name: '_sumAssured', type: 'uint256' },
        { name: 'premiumNXM', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'authQuoteEngine',
      outputs: [{ name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getAuthQuoteEngine',
      outputs: [{ name: '_add', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'minDays',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { name: '_add', type: 'address' },
        { name: 'index', type: 'uint256' },
      ],
      name: 'getUserHoldedCoverByIndex',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'nxMasterAddress',
      outputs: [{ name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'tokensRetained',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_add', type: 'address' },
        { name: 'status', type: 'bool' },
      ],
      name: 'setRefundEligible',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_add', type: 'address' }],
      name: 'getAllCoversOfUser',
      outputs: [{ name: 'allCover', type: 'uint256[]' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { name: '_authQuoteAdd', type: 'address' },
        { name: '_kycAuthAdd', type: 'address' },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: 'cid', type: 'uint256' },
        { indexed: false, name: 'scAdd', type: 'address' },
        { indexed: false, name: 'sumAssured', type: 'uint256' },
        { indexed: false, name: 'expiry', type: 'uint256' },
        { indexed: false, name: 'premium', type: 'uint256' },
        { indexed: false, name: 'premiumNXM', type: 'uint256' },
        { indexed: false, name: 'curr', type: 'bytes4' },
      ],
      name: 'CoverDetailsEvent',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: 'cid', type: 'uint256' },
        { indexed: false, name: 'statusNum', type: 'uint8' },
      ],
      name: 'CoverStatusEvent',
      type: 'event',
    },
  ],

  claimAddress: '0x58676340f458b36997608672Be6548c92Ce50714',
  claimABI: [
    {
      constant: false,
      inputs: [],
      name: 'changeDependentContractAddress',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'pauseAllPendingClaimsVoting',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_claimId', type: 'uint256' }],
      name: 'getClaimbyIndex',
      outputs: [
        { name: 'claimId', type: 'uint256' },
        { name: 'status', type: 'uint256' },
        { name: 'finalVerdict', type: 'int8' },
        { name: 'claimOwner', type: 'address' },
        { name: 'coverId', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: 'index', type: 'uint256' }],
      name: 'getUserClaimByIndex',
      outputs: [
        { name: 'status', type: 'uint256' },
        { name: 'coverId', type: 'uint256' },
        { name: 'claimId', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { name: 'claimId', type: 'uint256' },
        { name: 'member', type: 'uint256' },
      ],
      name: 'getCATokens',
      outputs: [{ name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: 'coverId', type: 'uint256' }],
      name: 'submitClaim',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'submitClaimAfterEPOff',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'startAllPendingClaimsVoting',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'ms',
      outputs: [{ name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'changePendingClaimStart',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: 'claimId', type: 'uint256' },
        { name: 'verdict', type: 'int8' },
      ],
      name: 'submitCAVote',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: '_masterAddress', type: 'address' }],
      name: 'changeMasterAddress',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: 'claimId', type: 'uint256' },
        { name: 'verdict', type: 'int8' },
      ],
      name: 'submitMemberVote',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: 'claimId', type: 'uint256' }],
      name: 'checkVoteClosing',
      outputs: [{ name: 'close', type: 'int8' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'nxMasterAddress',
      outputs: [{ name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: 'claimId', type: 'uint256' },
        { name: 'stat', type: 'uint256' },
      ],
      name: 'setClaimStatus',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: 'index', type: 'uint256' }],
      name: 'getClaimFromNewStart',
      outputs: [
        { name: 'coverId', type: 'uint256' },
        { name: 'claimId', type: 'uint256' },
        { name: 'voteCA', type: 'int8' },
        { name: 'voteMV', type: 'int8' },
        { name: 'statusnumber', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],

  arNftV2Address: cnf.ARNFT_ADDRESS,
  arNftV2ABI: [
    {
      inputs: [
        { internalType: 'address', name: '_nxMaster', type: 'address' },
        { internalType: 'address', name: '_ynft', type: 'address' },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'approved',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'coverId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'buyer',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'coveredContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bytes4',
          name: 'currency',
          type: 'bytes4',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'coverAmount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'coverPrice',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'startTime',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint16',
          name: 'coverPeriod',
          type: 'uint16',
        },
      ],
      name: 'BuyCover',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'receiver',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'bytes4',
          name: 'currency',
          type: 'bytes4',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'ClaimRedeemed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'coverId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'claimId',
          type: 'uint256',
        },
      ],
      name: 'ClaimSubmitted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'yInsureTokenId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'coverId',
          type: 'uint256',
        },
      ],
      name: 'SwappedYInsure',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    { payable: true, stateMutability: 'payable', type: 'fallback' },
    {
      constant: false,
      inputs: [],
      name: 'activateSwap',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_tokenAddress', type: 'address' },
      ],
      name: 'approveToken',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'baseURI',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256[]', name: '_tokenIds', type: 'uint256[]' },
      ],
      name: 'batchSwapYnft',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        {
          internalType: 'address',
          name: '_coveredContractAddress',
          type: 'address',
        },
        { internalType: 'bytes4', name: '_coverCurrency', type: 'bytes4' },
        { internalType: 'uint256[]', name: '_coverDetails', type: 'uint256[]' },
        { internalType: 'uint16', name: '_coverPeriod', type: 'uint16' },
        { internalType: 'uint8', name: '_v', type: 'uint8' },
        { internalType: 'bytes32', name: '_r', type: 'bytes32' },
        { internalType: 'bytes32', name: '_s', type: 'bytes32' },
      ],
      name: 'buyCover',
      outputs: [],
      payable: true,
      stateMutability: 'payable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'claimIds',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'coverPrices',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'getApproved',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'getCoverStatus',
      outputs: [
        { internalType: 'uint8', name: 'coverStatus', type: 'uint8' },
        { internalType: 'bool', name: 'payoutCompleted', type: 'bool' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getMemberRoles',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'getToken',
      outputs: [
        { internalType: 'uint256', name: 'cid', type: 'uint256' },
        { internalType: 'uint8', name: 'status', type: 'uint8' },
        { internalType: 'uint256', name: 'sumAssured', type: 'uint256' },
        { internalType: 'uint16', name: 'coverPeriod', type: 'uint16' },
        { internalType: 'uint256', name: 'validUntil', type: 'uint256' },
        { internalType: 'address', name: 'scAddress', type: 'address' },
        { internalType: 'bytes4', name: 'currencyCode', type: 'bytes4' },
        { internalType: 'uint256', name: 'premiumNXM', type: 'uint256' },
        { internalType: 'uint256', name: 'coverPrice', type: 'uint256' },
        { internalType: 'uint256', name: 'claimId', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'operator', type: 'address' },
      ],
      name: 'isApprovedForAll',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'isOwner',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'nxMaster',
      outputs: [
        { internalType: 'contract INXMMaster', name: '', type: 'address' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_spender', type: 'address' },
        { internalType: 'uint256', name: '_value', type: 'uint256' },
      ],
      name: 'nxmTokenApprove',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'ownerOf',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'redeemClaim',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        { internalType: 'bytes', name: '_data', type: 'bytes' },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'bool', name: 'approved', type: 'bool' },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'submitClaim',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'swapActivated',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'swapIds',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: '_ynftTokenId', type: 'uint256' },
      ],
      name: 'swapYnft',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_newMembership', type: 'address' },
      ],
      name: 'switchMembership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'uint256', name: 'index', type: 'uint256' },
      ],
      name: 'tokenOfOwnerByIndex',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'tokenURI',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'ynft',
      outputs: [
        { internalType: 'contract IyInsure', name: '', type: 'address' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],

  arNftV1Address: cnf.ARNFT_V1_ADDRESS,
  arNftV1ABI: [
    {
      inputs: [
        { internalType: 'address', name: '_nxMaster', type: 'address' },
        { internalType: 'address', name: '_ynft', type: 'address' },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'approved',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'coverId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'buyer',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'coveredContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bytes4',
          name: 'currency',
          type: 'bytes4',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'coverAmount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'coverPrice',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'startTime',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint16',
          name: 'coverPeriod',
          type: 'uint16',
        },
      ],
      name: 'BuyCover',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'receiver',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'bytes4',
          name: 'currency',
          type: 'bytes4',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'ClaimRedeemed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'coverId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'claimId',
          type: 'uint256',
        },
      ],
      name: 'ClaimSubmitted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'yInsureTokenId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'coverId',
          type: 'uint256',
        },
      ],
      name: 'SwappedYInsure',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    { payable: true, stateMutability: 'payable', type: 'fallback' },
    {
      constant: false,
      inputs: [],
      name: 'activateSwap',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_tokenAddress', type: 'address' },
      ],
      name: 'approveToken',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'baseURI',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256[]', name: '_tokenIds', type: 'uint256[]' },
      ],
      name: 'batchSwapYnft',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        {
          internalType: 'address',
          name: '_coveredContractAddress',
          type: 'address',
        },
        { internalType: 'bytes4', name: '_coverCurrency', type: 'bytes4' },
        { internalType: 'uint256[]', name: '_coverDetails', type: 'uint256[]' },
        { internalType: 'uint16', name: '_coverPeriod', type: 'uint16' },
        { internalType: 'uint8', name: '_v', type: 'uint8' },
        { internalType: 'bytes32', name: '_r', type: 'bytes32' },
        { internalType: 'bytes32', name: '_s', type: 'bytes32' },
      ],
      name: 'buyCover',
      outputs: [],
      payable: true,
      stateMutability: 'payable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'claimIds',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'coverPrices',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'getApproved',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'getCoverStatus',
      outputs: [
        { internalType: 'uint8', name: 'coverStatus', type: 'uint8' },
        { internalType: 'bool', name: 'payoutCompleted', type: 'bool' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getMemberRoles',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'getToken',
      outputs: [
        { internalType: 'uint256', name: 'cid', type: 'uint256' },
        { internalType: 'uint8', name: 'status', type: 'uint8' },
        { internalType: 'uint256', name: 'sumAssured', type: 'uint256' },
        { internalType: 'uint16', name: 'coverPeriod', type: 'uint16' },
        { internalType: 'uint256', name: 'validUntil', type: 'uint256' },
        { internalType: 'address', name: 'scAddress', type: 'address' },
        { internalType: 'bytes4', name: 'currencyCode', type: 'bytes4' },
        { internalType: 'uint256', name: 'premiumNXM', type: 'uint256' },
        { internalType: 'uint256', name: 'coverPrice', type: 'uint256' },
        { internalType: 'uint256', name: 'claimId', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'operator', type: 'address' },
      ],
      name: 'isApprovedForAll',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'isOwner',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'nxMaster',
      outputs: [
        { internalType: 'contract INXMMaster', name: '', type: 'address' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_spender', type: 'address' },
        { internalType: 'uint256', name: '_value', type: 'uint256' },
      ],
      name: 'nxmTokenApprove',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'ownerOf',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'redeemClaim',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        { internalType: 'bytes', name: '_data', type: 'bytes' },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'bool', name: 'approved', type: 'bool' },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'submitClaim',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'swapActivated',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'swapIds',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: '_ynftTokenId', type: 'uint256' },
      ],
      name: 'swapYnft',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_newMembership', type: 'address' },
      ],
      name: 'switchMembership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'uint256', name: 'index', type: 'uint256' },
      ],
      name: 'tokenOfOwnerByIndex',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'tokenURI',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'ynft',
      outputs: [
        { internalType: 'contract IyInsure', name: '', type: 'address' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],

  raribleAddress: cnf.RARIBLE_ADDRESS,
  raribleABI: [
    {
      inputs: [
        {
          internalType: 'contract TransferProxy',
          name: '_transferProxy',
          type: 'address',
        },
        {
          internalType: 'contract ERC721SaleNonceHolder',
          name: '_nonceHolder',
          type: 'address',
        },
        {
          internalType: 'address payable',
          name: 'beneficiary',
          type: 'address',
        },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'seller',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'buyer',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'price',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'nonce',
          type: 'uint256',
        },
      ],
      name: 'Buy',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'nonce',
          type: 'uint256',
        },
      ],
      name: 'Cancel',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      constant: true,
      inputs: [],
      name: 'beneficiary',
      outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'contract IERC721', name: 'token', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        { internalType: 'uint256', name: 'price', type: 'uint256' },
        { internalType: 'uint256', name: 'sellerFee', type: 'uint256' },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct AbstractSale.Sig',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'buy',
      outputs: [],
      payable: true,
      stateMutability: 'payable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'buyerFee',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'contract IERC721', name: 'token', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'cancel',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'isOwner',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'nonceHolder',
      outputs: [
        {
          internalType: 'contract ERC721SaleNonceHolder',
          name: '',
          type: 'address',
        },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC721Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        {
          internalType: 'address payable',
          name: '_beneficiary',
          type: 'address',
        },
      ],
      name: 'setBeneficiary',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: '_buyerFee', type: 'uint256' }],
      name: 'setBuyerFee',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'transferProxy',
      outputs: [
        { internalType: 'contract TransferProxy', name: '', type: 'address' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],

  arNFT: arNFT(cnf.ARNFT_ADDRESS),
  armorToken: armorToken(cnf.ARMOR_TOKEN_ADDRESS),
  balanceManager: balanceManager(cnf.BALANCE_MANAGER_ADDRESS),
  claimManager: claimManager(cnf.CLAIM_MANAGER_ADDRESS),
  planManager: planManager(cnf.PLAN_MANAGER_ADDRESS),
  rewardManager: rewardManager(cnf.REWARD_MANAGER_ADDRESS),
  rewardManagerV2: rewardManagerV2(cnf.REWARD_MANAGER_V2_ADDRESS),
  stakeManager: stakeManager(cnf.STAKE_MANAGER_ADDRESS),

  ibco: ibco(cnf.IBCO_ADDRESS),
  erc20: erc20(cnf.ERC20_ADDRESS),
  yInsure: yInsure(cnf.YINSURE_ADDRESS),
  quotation: quotation(cnf.QUOTATION_ADDRESS),
  arNFTv1: arNFTv1(cnf.ARNFT_V1_ADDRESS),
  arNFTv2: arNFTv2(cnf.ARNFT_V2_ADDRESS),
  rarible: rarible(cnf.RARIBLE_ADDRESS),
  arNXMVault: arNXMVault(cnf.ARNXM_VAULT_ADDRESS),
  wNXM: wNXM(cnf.WNXM_TOKEN_ADDRESS),
  arNXMToken: arNXMToken(cnf.ARNXM_TOKEN_ADDRESS),
  NXM: NXM(cnf.NXM_TOKEN_ADDRESS),
  referralRewards: referralRewards(cnf.REFERRAL_REWARDS_ADDRESS),
  nexusMutualStaking: nexusMutualStaking(cnf.NEXUS_MUTUAL_STAKING_ADDRESS),
  armorMaster: armorMaster(cnf.ARMOR_MASTER_ADDRESS),
  utilizationFarmBorrowers: utilizationFarm(
    cnf.UTILIZATION_FARM_BORROWERS_ADDRESS
  ),
  utilizationFarmStakers: utilizationFarm(cnf.UTILIZATION_FARM_STAKERS_ADDRESS),
  lpFarm: lpFarm(cnf.ARMOR_DAI_UNI_FARM_ADDRESS),
  lpToken: lpToken(cnf.ARMOR_ETH_UNI_TOKEN_ADDRESS),

  // arshields
  arShields: arShields(cnf.ARSHIELDS_ADDRESS),
  arShieldsCoverageBase: arShieldsCoverageBase(
    cnf.ARSHIELDS_COVERAGE_BASE_ADDRESS
  ),
  arShieldController: arShieldController(cnf.ARSHIELDS_CONTROLLER_ADDRESS),

  // rewards arNXM -> wNXM
  arNXM_wNXM_Sushi_Token: lpToken(cnf.ARNXM_WNXM_SUSHI_TOKEN_ADDRESS),
  arNXM_wNXM_Sushi_Farm: lpFarm(cnf.ARNXM_WNXM_SUSHI_FARM_ADDRESS),
  arNXM_wNXM_Uni_Token: lpToken(cnf.ARNXM_WNXM_UNI_TOKEN_ADDRESS),
  arNXM_wNXM_Uni_Farm: lpFarm(cnf.ARNXM_WNXM_UNI_FARM_ADDRESS),

  // rewards armor -> eth
  armor_ETH_Uni_Farm: lpFarm(cnf.ARMOR_ETH_UNI_FARM_ADDRESS),
  armor_ETH_Uni_Token: lpToken(cnf.ARMOR_ETH_UNI_TOKEN_ADDRESS),
  armor_ETH_Sushi_Farm: lpFarm(cnf.ARMOR_ETH_SUSHI_FARM_ADDRESS),
  armor_ETH_Sushi_Token: lpToken(cnf.ARMOR_ETH_SUSHI_TOKEN_ADDRESS),
  armor_ETH_1inch_Farm: lpFarm(cnf.ARMOR_ETH_1INCH_FARM_ADDRESS),
  armor_ETH_1inch_Token: lpToken(cnf.ARMOR_ETH_1INCH_TOKEN_ADDRESS),
  armor_ETH_Bal_Farm: lpFarm(cnf.ARMOR_ETH_BAL_FARM_ADDRESS),
  armor_ETH_Bal_Token: lpToken(cnf.ARMOR_ETH_BAL_TOKEN_ADDRESS),

  // rewards arNXM -> armor
  arnxm_ARMOR_Uni_Farm: lpFarm(cnf.ARNXM_ARMOR_UNI_FARM_ADDRESS),
  arnxm_ARMOR_Uni_Token: lpToken(cnf.ARNXM_ARMOR_UNI_TOKEN_ADDRESS),

  // rewards armor -> dai
  armor_DAI_Uni_Farm: lpFarm(cnf.ARMOR_DAI_UNI_FARM_ADDRESS),
  armor_DAI_Uni_Token: lpToken(cnf.ARMOR_DAI_UNI_TOKEN_ADDRESS),
  armor_DAI_Sushi_Farm: lpFarm(cnf.ARMOR_DAI_SUSHI_FARM_ADDRESS),
  armor_DAI_Sushi_Token: lpToken(cnf.ARMOR_DAI_SUSHI_TOKEN_ADDRESS),
  armor_DAI_1inch_Farm: lpFarm(cnf.ARMOR_DAI_1INCH_FARM_ADDRESS),
  armor_DAI_1inch_Token: lpToken(cnf.ARMOR_DAI_1INCH_TOKEN_ADDRESS),
  armor_DAI_Bal_Farm: lpFarm(cnf.ARMOR_DAI_BAL_FARM_ADDRESS),
  armor_DAI_Bal_Token: lpToken(cnf.ARMOR_DAI_BAL_TOKEN_ADDRESS),

  // rewards armor -> dai
  armor_WBTC_Uni_Farm: lpFarm(cnf.ARMOR_WBTC_UNI_FARM_ADDRESS),
  armor_WBTC_Uni_Token: lpToken(cnf.ARMOR_WBTC_UNI_TOKEN_ADDRESS),
  armor_WBTC_Sushi_Farm: lpFarm(cnf.ARMOR_WBTC_SUSHI_FARM_ADDRESS),
  armor_WBTC_Sushi_Token: lpToken(cnf.ARMOR_WBTC_SUSHI_TOKEN_ADDRESS),
  armor_WBTC_1inch_Farm: lpFarm(cnf.ARMOR_WBTC_1INCH_FARM_ADDRESS),
  armor_WBTC_1inch_Token: lpToken(cnf.ARMOR_WBTC_1INCH_TOKEN_ADDRESS),

  // rewards arNXM -> eth
  arNXM_ETH_Uni_Farm: lpFarm(cnf.ARNXM_ETH_UNI_FARM_ADDRESS),
  arNXM_ETH_Uni_Token: lpToken(cnf.ARNXM_ETH_UNI_TOKEN_ADDRESS),
  arNXM_ETH_Sushi_Farm: lpFarm(cnf.ARNXM_ETH_SUSHI_FARM_ADDRESS),
  arNXM_ETH_Sushi_Token: lpToken(cnf.ARNXM_ETH_SUSHI_TOKEN_ADDRESS),
  arNXM_ETH_1inch_Farm: lpFarm(cnf.ARNXM_ETH_1INCH_FARM_ADDRESS),
  arNXM_ETH_1inch_Token: lpToken(cnf.ARNXM_ETH_1INCH_TOKEN_ADDRESS),
  arNXM_ETH_Bal_Farm: lpFarm(cnf.ARNXM_ETH_BAL_FARM_ADDRESS),
  arNXM_ETH_Bal_Token: lpToken(cnf.ARNXM_ETH_BAL_TOKEN_ADDRESS),
  vArmor: vArmor(cnf.VARMOR_ADDRESS),
  UniAbi,
  BalAbi,
}

export default config
