import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import React from 'react'
import BoxTitle from '../../../components/shared/boxTitle/BoxTitle'
import Text from '../../../components/shared/text/Text'
import Button from '../../../components/shared/button/Button'
import { CancelButton } from '../../protect/modalStyled'
import {
  Content,
  OptionContent,
  OptionText,
  OptionWrapper,
} from './WithdrawConfirmationModal'

const UndelegateModal = ({ theme, t, onCancelModal, onUndelegate }) => {
  const { colors } = theme

  return (
    <Content>
      <BoxTitle text={'You must undelegate your votes before withdrawing'} />
      <OptionWrapper>
        <OptionContent>
          <OptionText>
            <Text
              text={
                <>
                  vArmor to Armor withdrawals are subject to a 7 day delay.
                  After this period you can come back to this page to finalize
                  the withdrawal.
                </>
              }
              color={colors.secondaryDefault}
            />
          </OptionText>
          <Button content="UNDELEGATE" onClick={onUndelegate} />
        </OptionContent>
      </OptionWrapper>
      <CancelButton onClick={onCancelModal}>
        {t('ConfirmCoverModal.Cancel')}
      </CancelButton>
    </Content>
  )
}

export default withTranslation()(withRouter(UndelegateModal))
