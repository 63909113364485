import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import CheckCircleIcon from '../../components/icons/CheckCircleIcon'
import { isAddress } from 'web3-utils'
import SignPoL from './SignPoL'
import TxPoL from './TxPoL'
import { CONNECTION_CONNECTED, CONNECTION_DISCONNECTED } from '../../constants'
import { removeEmitterListeners, turnOnEmitterListeners } from '../../helpers'
import Store from '../../stores/store'
import Tooltip from '@material-ui/core/Tooltip'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { parse as queryParse } from 'qs'
import {
  Wrapper,
  Container,
  Title,
  Description,
  Box,
  ModalTitle,
  ActionContainer,
  Input,
  ActiveBtnText,
  ContentContainer,
  MainInfo,
  ActiveBtn,
  AnalyzingBox,
  CenterContent,
  AnalizingText,
  SubTitle,
  ToggleButton,
  ToggleButtonGroup,
  Dot,
  StepWrapper,
  Check,
  Text,
} from './styled'

const emitter = Store.emitter
const store = Store.store

const ALLOWED_PROOF_TYPES = [
  {
    type: 'SIGN',
    tooltip: `You'll sign a message from the specified address as proof.`,
  },
  {
    type: 'TX',
    tooltip: `You'll make a 0 amount transaction to a specified address.`,
  },
]

const POL_SUBMIT_STEPS = ['Add affected address', 'Submit proof', 'Finish']

const ProofOfLossSubmit = ({ t, theme, history }) => {
  const [account, setAccount] = useState(null)
  const [affectedAddress, setAffectedAddress] = useState('')
  const [affectedAddressError, setAffectedAddressError] = useState(false)
  const [proofType, setProofType] = useState(ALLOWED_PROOF_TYPES[0].type) // SIGN or TX
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitStage, setIsSubmitStage] = useState(false)
  const [loaderMessage, setLoaderMessage] = useState('')
  const [activeStep, setActiveStep] = useState(0)

  const { colors } = theme

  useEffect(() => {
    const _account = store.getStore('account')
    setAccount(_account)

    parseQueryString()

    let events = [
      [CONNECTION_CONNECTED, connectionConnected],
      [CONNECTION_DISCONNECTED, connectionDisconnected],
    ]
    turnOnEmitterListeners(emitter, events)

    return () => {
      removeEmitterListeners(emitter, events)
    }
  }, [])

  const connectionConnected = () => {
    const _account = store.getStore('account')
    setAccount(_account)
  }

  const connectionDisconnected = () => {
    store.setStore({ account: null, web3context: null })
    setAccount(null)
  }

  const parseQueryString = () => {
    const { affectedAddress: _affectedAddress, proofType: _proofType } =
      queryParse(window.location.search, {
        ignoreQueryPrefix: true,
      })

    if (_affectedAddress) {
      setAffectedAddress(_affectedAddress)
    }

    if (_proofType) {
      if (
        ALLOWED_PROOF_TYPES.find((t) => t.type === _proofType.toUpperCase())
      ) {
        setProofType(_proofType.toUpperCase())
      }
    }
  }

  const handleChangeAffectedAddress = (e) => {
    setAffectedAddressError(false)
    const { value } = e.target
    setAffectedAddress(value)
  }

  const handleContinue = () => {
    setAffectedAddressError(false)
    if (!affectedAddress || !isAddress(affectedAddress)) {
      setAffectedAddressError(true)
      return
    }
    setActiveStep(1) // submit proof step
    setIsSubmitStage(true)
  }

  const handleGoBack = () => {
    setActiveStep(0) // initial step
    setIsSubmitStage(false)
  }

  const handleChangeProofType = (_, _proofType = '') => {
    if (
      _proofType &&
      ALLOWED_PROOF_TYPES.find((t) => t.type === _proofType.toUpperCase())
    ) {
      setProofType(_proofType)
    }
  }

  const handleRedirectOutOfThere = () => {
    history.push('/')
  }

  const handleStartFlowOver = () => {
    setAffectedAddress('')
    setAffectedAddressError(false)
    setProofType(ALLOWED_PROOF_TYPES[0].type)
    setIsLoading(false)
    setIsSubmitted(false)
    setIsSubmitStage(false)
    setLoaderMessage('')
    setActiveStep(0) // initial step
  }

  const handleSubmitProofTX = (_affectedAddress, txHash) => {
    setLoaderMessage('Submitting...')
    // TODO: handle check tx hash and submit proof (TX proof type)
    setTimeout(() => {
      setLoaderMessage('')
      setActiveStep(2) // finish step
      setIsSubmitted(true)
    }, 2000)
  }

  const handleSubmitProofSIGN = (_affectedAddress, txHash) => {
    setLoaderMessage('Submitting...')
    // TODO: handle check tx hash and submit proof (SIGN proof type)
    setTimeout(() => {
      setLoaderMessage('')
      setActiveStep(2) // finish step
      setIsSubmitted(true)
    }, 2000)
  }

  const isInitialStage = () => !isSubmitStage && !isSubmitted
  const isProofStage = () => isSubmitStage && !isSubmitted
  const isFinishStage = () => isSubmitted

  const QontoStepIcon = (props) => {
    const { active, completed } = props

    return (
      <div>
        {completed ? (
          <Check>
            <CheckCircleIcon color={colors.activeSearch} />
          </Check>
        ) : (
          <Dot active={active} />
        )}
      </div>
    )
  }

  return (
    <Wrapper>
      <Container>
        <Title>{t('ProofOfLoss.Title')}</Title>
        <Description>
          <p>
            When submitting a claim you have to prove your losses. Input the
            affected address below. You will then be asked to prove you control
            the address using one of the following methods:
          </p>
          <br />
          <p>
            <b>SIGN</b> - You'll sign a message from the specified address as
            proof.
          </p>
          <p>
            <b>TX</b> - You'll make a 0 amount transaction to a specified
            address.
          </p>
          <br />
          <p>
            <b>Note:</b> If your affected address is different from your Nexus
            Mutual member address, the proof will still be considered valid, as
            long as you can prove ownership.
          </p>
        </Description>
        {loaderMessage.length > 0 ? (
          <AnalyzingBox>
            <CenterContent>
              <CheckCircleIcon color={colors.activeSearch} />
              <AnalizingText>{loaderMessage}</AnalizingText>
            </CenterContent>
          </AnalyzingBox>
        ) : (
          <>
            <Box>
              <ContentContainer>
                <StepWrapper alternativeLabel activeStep={activeStep}>
                  {POL_SUBMIT_STEPS.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </StepWrapper>
                <MainInfo>
                  {isInitialStage() && (
                    <>
                      <SubTitle>
                        Please enter affected address, select proof type and
                        click "Continue"
                      </SubTitle>
                      <ActionContainer>
                        <Input
                          autoFocus
                          value={affectedAddress}
                          onChange={handleChangeAffectedAddress}
                          disabled={isLoading}
                          error={affectedAddressError}
                          placeholder="0x0000000000000000000000000000000000000000"
                          variant="outlined"
                          helperText={
                            <>
                              {affectedAddressError && 'Address is not valid'}
                            </>
                          }
                        />
                        <ToggleButtonGroup
                          value={proofType}
                          exclusive
                          onChange={handleChangeProofType}
                        >
                          {ALLOWED_PROOF_TYPES.map((pt) => {
                            return (
                              <ToggleButton
                                key={pt.type}
                                value={pt.type}
                                disabled={isLoading}
                              >
                                <Tooltip
                                  arrow
                                  enterTouchDelay={50}
                                  title={pt.tooltip}
                                >
                                  <span>{pt.type}</span>
                                </Tooltip>
                              </ToggleButton>
                            )
                          })}
                        </ToggleButtonGroup>
                      </ActionContainer>
                      <ActionContainer>
                        <ActiveBtn
                          variant="contained"
                          color="primary"
                          disabled={
                            !affectedAddress ||
                            affectedAddressError ||
                            isLoading
                          }
                          onClick={handleContinue}
                        >
                          <ActiveBtnText>Continue</ActiveBtnText>
                        </ActiveBtn>
                      </ActionContainer>
                    </>
                  )}

                  {isProofStage() && (
                    <div>
                      {proofType === 'SIGN' && (
                        <SignPoL
                          account={account}
                          affectedAddress={affectedAddress}
                          submitProof={handleSubmitProofSIGN}
                          goBack={handleGoBack}
                        />
                      )}

                      {proofType === 'TX' && (
                        <TxPoL
                          account={account}
                          affectedAddress={affectedAddress}
                          submitProof={handleSubmitProofTX}
                          goBack={handleGoBack}
                        />
                      )}
                    </div>
                  )}

                  {isFinishStage() && (
                    <div>
                      <ModalTitle>Fine fellow!</ModalTitle>
                      <Text>
                        London is the capical of the Great Britain. Lorem ipsum
                        dolor. Blah-blah... blah! London is the capical of the
                        Great Britain. Lorem ipsum dolor. Blah-blah... blah!
                      </Text>
                      <ActionContainer>
                        <ActiveBtn
                          variant="contained"
                          color="primary"
                          disabled={isLoading}
                          onClick={handleRedirectOutOfThere}
                          secondary="true"
                        >
                          <ActiveBtnText secondary="true">Finish</ActiveBtnText>
                        </ActiveBtn>

                        <ActiveBtn
                          variant="contained"
                          color="primary"
                          disabled={isLoading}
                          onClick={handleStartFlowOver}
                        >
                          <ActiveBtnText>Submit another</ActiveBtnText>
                        </ActiveBtn>
                      </ActionContainer>
                    </div>
                  )}
                </MainInfo>
              </ContentContainer>
            </Box>
          </>
        )}
      </Container>
    </Wrapper>
  )
}

export default withTranslation()(withRouter(withTheme(ProofOfLossSubmit)))
