import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import ExternalIcon from '../../components/icons/ExternalIcon'
import CheckCircleIcon from '../../components/icons/CheckCircleIcon'
import {
  ActiveBtn,
  ActiveBtnText,
  SubTitle,
  Link,
  ActionContainer,
  ConnectDot,
  ConnectWrapper,
  SignWrapper,
  ConnectTitle,
  ConnectIcon,
  ConnectDotAnimate,
  ActionWrapper,
} from './styled'

const SignPoL = ({ account, affectedAddress, submitProof, theme, goBack }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSigned, setIsSigned] = useState(false)
  const [txHash, setTxHash] = useState(null)
  const { colors } = theme

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const isOwner = () => {
    return (
      account &&
      account.address &&
      account.address.toLowerCase() === affectedAddress.toLowerCase()
    )
  }

  const handleSign = async () => {
    // TODO: create and sign the message
    const _txHash = '0xDUMMY'
    setTxHash(_txHash)
    setIsSigned(true)
  }

  const handleSubmitProof = async () => {
    await submitProof(affectedAddress, txHash)
  }

  const handleGoBack = () => {
    goBack()
  }

  return (
    <ActionWrapper>
      <SubTitle>Prove ownership by signing the verification message</SubTitle>

      <SignWrapper>
        <ConnectWrapper connected={account && account.address && isOwner()}>
          <ConnectTitle>Connect to this wallet address:</ConnectTitle>

          <Link
            href={`https://etherscan.io/address/${affectedAddress}`}
            target="_blank"
          >
            {affectedAddress} <ExternalIcon color={colors.active} />
          </Link>
          {account && account.address && isOwner() ? (
            <ConnectIcon>
              <CheckCircleIcon color={colors.activeSearch} />
            </ConnectIcon>
          ) : (
            <ConnectDot>
              <ConnectDotAnimate />
            </ConnectDot>
          )}
        </ConnectWrapper>
        {account && account.address && isOwner() && (
          <ConnectWrapper
            connected={account && account.address && isOwner() && isSigned}
          >
            <ConnectTitle>
              <span onClick={handleSign}>Click here</span> to sign the
              verification message.
            </ConnectTitle>
            {account && account.address && isOwner() && isSigned ? (
              <ConnectIcon>
                <CheckCircleIcon color={colors.activeSearch} />
              </ConnectIcon>
            ) : (
              <ConnectDot>
                <ConnectDotAnimate />
              </ConnectDot>
            )}
          </ConnectWrapper>
        )}
      </SignWrapper>
      <ActionContainer>
        <ActiveBtn
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={handleGoBack}
          secondary="true"
        >
          <ActiveBtnText secondary="true">Back</ActiveBtnText>
        </ActiveBtn>
        <ActiveBtn
          variant="contained"
          color="primary"
          disabled={!isSigned || isLoading}
          onClick={handleSubmitProof}
        >
          <ActiveBtnText>Submit</ActiveBtnText>
        </ActiveBtn>
      </ActionContainer>
    </ActionWrapper>
  )
}

export default withTranslation()(withRouter(withTheme(SignPoL)))
