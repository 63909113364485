export const CONNECTION_CONNECTED = 'CONNECTION_CONNECTED'
export const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED'
export const REFERRER_SET = 'REFERRER_SET'
export const ERROR = 'ERROR'

export const SNACKBAR_ERROR = 'SNACKBAR_ERROR'
export const SNACKBAR_TRANSACTION_RECEIPT = 'SNACKBAR_TRANSACTION_RECEIPT'
export const SNACKBAR_TRANSACTION_CONFIRMED = 'SNACKBAR_TRANSACTION_CONFIRMED'
export const SNACKBAR_MESSAGE = 'SNACKBAR_MESSAGE'

export const GET_ACCOUNT_BALANCES = 'GET_ACCOUNT_BALANCES'
export const ACCOUNT_BALANCES_RETURNED = 'ACCOUNT_BALANCES_RETURNED'

export const FARMING_APYS_RETURNED = 'FARMING_APYS_RETURNED'

export const GET_CONTRACT_BALANCES = 'GET_CONTRACT_BALANCES'
export const CONTRACT_BALANCES_RETURNED = 'CONTRACT_BALANCES_RETURNED'

export const GET_QUOTE = 'GET_QUOTE'
export const QUOTE_RETURNED = 'QUOTE_RETURNED'

export const APPLY = 'APPLY'
export const APPLY_RETURNED = 'APPLY_RETURNED'

export const GET_COVER = 'GET_COVER'
export const COVER_RETURNED = 'COVER_RETURNED'

export const CLAIM = 'CLAIM'
export const CLAIM_RETURNED = 'CLAIM_RETURNED'

export const REDEEM = 'REDEEM'
export const REDEEM_RETURNED = 'REDEEM_RETURNED'

export const CHANGE_NFT_CONTRACT = 'CHANGE_NFT_CONTRACT'

export const GET_REWARD_USER_STAKED_PRICE = 'GET_REWARD_USER_STAKED_PRICE'
export const REWARD_USER_STAKED_PRICE_RETURNED =
  'REWARD_USER_STAKED_PRICE_RETURNED'

export const SET_GAS_PRICE_TYPE = 'SET_GAS_PRICE_TYPE'
export const GAS_PRICE_TYPE_RETURNED = 'GAS_PRICE_TYPE_RETURNED'

export const GET_PROTOCOL_STAKE_STATS = 'GET_PROTOCOL_STAKE_STATS'
export const PROTOCOL_STAKE_STATS_RETURNED = 'PROTOCOL_STAKE_STATS_RETURNED'

export const GET_ETH_PRICE = 'GET_ETH_PRICE'
export const ETH_PRICE_RETURNED = 'ETH_PRICE_RETURNED'

export const GET_ARMOR_PRICE = 'GET_ARMOR_PRICE'
export const ARMOR_PRICE_RETURNED = 'ARMOR_PRICE_RETURNED'

export const GET_AVAILABLE_COVERAGE = 'GET_AVAILABLE_COVERAGE'
export const AVAILABLE_COVERAGE_RETURNED = 'AVAILABLE_COVERAGE_RETURNED'

export const DAYS_FOR_CLAIM = '35'

export const GUIDE_ENABLED = 'GUIDE_ENABLED'
export const GUIDE_DISABLED = 'GUIDE_DISABLED'

export const GET_MANUAL_INPUTS = 'GET_MANUAL_INPUTS'
export const MANUAL_INPUTS_RETURNED = 'MANUAL_INPUTS_RETURNED'

export const UPDATE_MANUAL_INPUTS = 'UPDATE_MANUAL_INPUTS'
export const UPDATE_MANUAL_INPUTS_COMPLETED = 'UPDATE_MANUAL_INPUTS_COMPLETED'

export const GET_REFERRAL_CODE = 'GET_REFERRAL_CODE'
export const REFERRAL_CODE_RETURNED = 'REFERRAL_CODE_RETURNED'

export const GET_BLOG = 'GET_BLOG'
export const BLOG_RETURNED = 'BLOG_RETURNED'
