import React from 'react'

const LogoIcon = ({ color, width = '100%', height = '100%' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4654 19.2968L12.4658 19.2965L19.7206 13.6058C19.8151 13.5316 19.8704 13.4176 19.8704 13.2969V10.7855C19.8704 10.5819 19.6378 10.4673 19.4783 10.5925L12.0031 16.4558L4.52171 10.5875C4.36217 10.4624 4.12955 10.5769 4.12955 10.7806V13.297C4.12955 13.4177 4.18486 13.5317 4.27943 13.6059L11.5348 19.2971L11.5352 19.2974C11.6666 19.3994 11.8329 19.4505 11.9999 19.4505C12.166 19.4505 12.3362 19.4 12.4654 19.2968Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4553 3.82453L23.4549 3.82441L12.5204 0.0732686C12.3751 0.0233014 12.1866 0 12.0031 0C11.8197 0 11.6312 0.0233014 11.4859 0.0732686L0.552019 3.82419L0.551826 3.82426C0.3951 3.87754 0.258111 3.99289 0.160787 4.13067C0.0634013 4.26855 0 4.43698 0 4.60366V20.2298C0 20.3913 0.0513107 20.5714 0.129317 20.7323C0.207361 20.8933 0.316812 21.0448 0.442074 21.1453L0.442493 21.1456L11.5348 29.8465L11.5352 29.8468C11.6666 29.9489 11.8329 30 11.9999 30C12.166 30 12.3362 29.9494 12.4654 29.8463L12.4658 29.846L23.5569 21.1459L23.5571 21.1458C23.6828 21.0468 23.7925 20.8958 23.8705 20.7347C23.9486 20.5736 23.9999 20.3928 23.9999 20.2298V4.60472C24.0033 4.43745 23.9414 4.26954 23.8449 4.13211C23.7481 3.99433 23.6112 3.87939 23.4553 3.82453ZM21.4951 5.82689V19.56L12.0031 27.0053L2.51117 19.56V5.82689L12.0031 2.56917L21.4951 5.82689Z"
        fill={color}
      />
    </svg>
  )
}

export default LogoIcon
