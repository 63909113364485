import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ExternalIcon from '../../components/icons/ExternalIcon'
import Tooltip from '@material-ui/core/Tooltip'
import {
  ActiveBtn,
  ActiveBtnText,
  SubTitle,
  CopyButton,
  Link,
  Text,
  CopyTextWrapper,
  Input,
  ActionContainer,
  SkeletonContainer,
  ConnectWrapper,
  ActionWrapper,
} from './styled'

const TOOLTIP_DISAPPEAR_DELAY = 1000

const TxPoL = ({ affectedAddress, submitProof, theme, goBack }) => {
  const [targetAddress, setTargetAddress] = useState('')
  const [txHash, setTxHash] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isAffectedAddress, setIsAffectedAddress] = useState(false)
  const [isTargetAddress, setIsTargetAddress] = useState(false)
  const { colors } = theme

  useEffect(() => {
    // TODO: get targetAddress
    setTimeout(() => {
      setTargetAddress('0x0000000000000000000000000000000000000000')
      setIsLoading(false)
    }, 2000)
  }, [])

  const handleChangeTxHash = (e) => {
    const { value } = e.target
    setTxHash(value)
  }

  const handleSubmitProof = async () => {
    await submitProof(affectedAddress, txHash)
  }

  const handleGoBack = () => {
    goBack()
  }

  const onCopied = (setTargetElement) => {
    setTargetElement(true)
    setTimeout(() => {
      setTargetElement(false)
    }, TOOLTIP_DISAPPEAR_DELAY)
  }

  return (
    <ActionWrapper>
      <SubTitle>Prove ownership by making the 0 ETH transaction</SubTitle>
      <ConnectWrapper>
        <Text first="true">Send 0 ETH from this address:</Text>

        <CopyTextWrapper>
          <Link
            href={`https://etherscan.io/address/${affectedAddress}`}
            target="_blank"
          >
            {affectedAddress} <ExternalIcon color={colors.active} />
          </Link>
          <CopyToClipboard
            text={affectedAddress}
            onCopy={() => onCopied(setIsAffectedAddress)}
          >
            <Tooltip
              arrow
              interactive
              placement="top"
              enterTouchDelay={50}
              title="Copied"
              open={isAffectedAddress}
            >
              <CopyButton color="primary">Copy</CopyButton>
            </Tooltip>
          </CopyToClipboard>
        </CopyTextWrapper>

        <Text>to this address:</Text>
        <CopyTextWrapper>
          {targetAddress ? (
            <Link
              href={`https://etherscan.io/address/${targetAddress}`}
              target="_blank"
            >
              {targetAddress} <ExternalIcon color={colors.active} />
            </Link>
          ) : (
            <SkeletonContainer />
          )}
          {targetAddress && (
            <CopyToClipboard
              text={targetAddress}
              onCopy={() => onCopied(setIsTargetAddress)}
            >
              <Tooltip
                arrow
                interactive
                placement="top"
                enterTouchDelay={50}
                title="Copied"
                open={isTargetAddress}
              >
                <CopyButton color="primary">Copy</CopyButton>
              </Tooltip>
            </CopyToClipboard>
          )}
        </CopyTextWrapper>
      </ConnectWrapper>

      <ConnectWrapper>
        <Text first="true">Then copy the tx hash and paste it here:</Text>
        <Input
          hash="true"
          variant="outlined"
          disabled={isLoading}
          value={txHash}
          onChange={handleChangeTxHash}
          placeholder={
            '0x0000000000000000000000000000000000000000000000000000000000000000'
          }
        />
      </ConnectWrapper>

      <ActionContainer>
        <ActiveBtn
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={handleGoBack}
          secondary="true"
        >
          <ActiveBtnText secondary="true">Back</ActiveBtnText>
        </ActiveBtn>
        <ActiveBtn
          variant="contained"
          color="primary"
          disabled={isLoading || txHash.length === 0}
          onClick={handleSubmitProof}
        >
          <ActiveBtnText>Submit</ActiveBtnText>
        </ActiveBtn>
      </ActionContainer>
    </ActionWrapper>
  )
}

export default withTranslation()(withRouter(withTheme(TxPoL)))
