import styled, { css } from 'styled-components'
import { CoverContainer } from '../../components/common/CoverContainer'
import InputAdornment from '@material-ui/core/InputAdornment'
import { InputField } from '../../components/common/Input'
import { ButtonStyled } from '../../components/common/Button'
import { BorderGradientContainer } from '../../components/common/BorderGradientContainer'
import Button from '@material-ui/core/Button'
import _ToggleButton from '@material-ui/lab/ToggleButton'
import _ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Skeleton from '@material-ui/lab/Skeleton'
import Stepper from '@material-ui/core/Stepper'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  left: 0;
  top: 0;
  margin-left: -235px;

  @media screen and (max-width: 1150px) {
    margin-left: 0;
  }
`
export const Container = styled.div`
  flex: 1;
  max-width: 725px;
  width: 100%;
  padding: 16px 15px 48px;
  margin: 0 auto;

  @media screen and (max-width: 1150px) {
    padding-top: 80px;
  }
`
export const Title = styled.h2`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: ${(p) => p.theme.colors.defaultLightActive};
`
export const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  max-width: 575px;
  width: 100%;
  margin: 38px auto 0;
  font-size: 12px;
  line-height: 16px;
  text-align: ${(p) => (p.stage ? 'left' : 'center')};
  color: ${(p) => p.theme.colors.secondary};
  @media screen and (max-width: 548px) {
    margin: 30px auto 0;
  }
`
export const Box = styled(CoverContainer)`
  margin-top: 70px;
  @media screen and (max-width: 548px) {
    margin-top: 50px;
  }
`
export const ModalTitle = styled.h4`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: ${(p) => p.theme.colors.primaryDefault};
`
export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  @media screen and (max-width: 600px) {
    margin-top: 0;
    flex-direction: column;
  }
`
export const Input = styled(InputField)`
  max-width: ${(p) => (p.hash ? '100%' : '325px')};
  width: 100%;
  max-height: 28px;
  margin-right: ${(p) => (p.hash ? '0' : '15px')};
  margin-top: ${(p) => (p.hash ? '15px' : '0')};

  & .MuiOutlinedInput-input {
    padding: 7px 14px;
  }
  & .MuiOutlinedInput-root {
    box-shadow: ${(p) =>
      p.error ? `0px 0px 14px ${p.theme.colors.error}` : 'none'};
    background: ${(p) => p.theme.colors._default};
    max-height: 28px;
    border: ${(p) =>
      p.error ? `1px solid ${p.theme.colors.error}` : '1px solid transparent'};
  }
  & .MuiFormHelperText-root {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: ${(p) => p.theme.colors.error};
    margin-top: 5px;
  }

  @media screen and (max-width: 600px) {
    margin-top: 15px;
    margin-right: 0;
  }
`
export const SubTitle = styled.h4`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${(p) => p.theme.colors.lightSecondary};
`
export const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: ${(p) => (p.address ? 'bold' : 'normal')};
  line-height: 20px;
  margin-top: ${(p) => (p.address ? '0' : p.first ? '0' : '15px')};
  color: ${(p) =>
    p.address ? p.theme.colors.active : p.theme.colors.secondary};
  display: flex;
  align-items: center;

  & span {
    color: ${(p) => p.theme.colors.active};
    font-weight: bold;
    text-decoration: underline;
    margin-right: 5px;
    cursor: pointer;
  }
`
export const ConnectTitle = styled(Text)`
  margin-top: 0;
  flex-wrap: wrap;
`
export const InputInfo = styled(InputAdornment)`
  & p {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;
    color: ${(p) => p.theme.colors.primary};
  }
`
export const ActiveBtn = styled(ButtonStyled)`
  padding: 6px 10px;
  max-height: 28px;
  height: unset;
  box-shadow: none;
  max-width: fit-content;
  min-width: unset;
  width: 100%;
  display: block;
  margin: 0 10px;
  &.MuiButton-contained.Mui-disabled {
    /* background: ${(p) => p.theme.colors.disabledText}; */
    background: ${(p) => p.theme.colors.strongDefault};
    & h4 {
      color: ${(p) => p.theme.colors.secondary};
    }
  }
  ${(p) =>
    p.secondary
      ? css`
          background: transparent;
          border: 1px solid ${(p) => p.theme.colors.active};
          &.MuiButton-contained.Mui-disabled {
            background: transparent;
            & h4 {
              color: ${(p) => p.theme.colors.active};
            }
          }
          &:hover {
            background: transparent;
            border: 1px solid ${(p) => p.theme.colors.active};
          }
        `
      : css`
          background: ${(p) => p.theme.colors.buttonActiveBg};
          &:hover {
            background: ${(p) => p.theme.colors.buttonActiveBg};
          }
        `}

  @media screen and (max-width: 600px) {
    margin-top: 15px;
    max-width: 160px;
  }
`
export const ActiveBtnText = styled.h4`
  color: ${(p) =>
    p.secondary ? p.theme.colors.active : p.theme.colors.secondary};
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
`
export const CancelButton = styled(Button)`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  cursor: pointer;
  margin: 20px auto 0;
  max-width: fit-content;
  padding: 6px 10px;
  color: ${(p) => p.theme.colors.primaryLightTrue};
  border: 1px solid ${(p) => p.theme.colors.primaryLightTrue};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`
export const ContentContainer = styled.div`
  padding: 21px 15px 15px;
`
export const MainInfo = styled.div`
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  padding: 22px 0 10px;
`
export const FooterInfo = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.disabledText};
  & svg {
    max-width: 24px;
    height: 24px;
    width: 100%;
    margin-right: 10px;
  }
`
export const AnalyzingBox = styled(BorderGradientContainer)`
  & svg {
    max-width: 24px;
    width: 100%;
    height: auto;
    min-width: 24px;
  }
  position: relative;
  overflow: hidden;

  &::after {
    -webkit-animation: analyzing 2s ease-in-out infinite both;
    animation: analyzing 2s ease-in-out infinite both;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @-webkit-keyframes analyzing {
      0% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 0;
      }
      50% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 1;
      }
      100% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 0;
      }
    }
    @keyframes analyzing {
      0% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 0;
      }
      50% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 1;
      }
      100% {
        background: ${(p) => p.theme.colors.finishedPulseAnimation};
        opacity: 0;
      }
    }
  }
`
export const AnalizingText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.secondary};
  margin-left: 8px;
`
export const CenterContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
export const ToggleButton = styled(_ToggleButton)`
  padding: 11px;
  max-height: 30px;
  width: 50px;
  color: ${(p) => p.theme.colors._default};
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0;
  border: none;

  &.Mui-selected {
    color: ${(p) => p.theme.colors.modalBg};
    font-weight: bold;
    background-color: ${(p) => p.theme.colors.activeSearch};
    &:hover {
      background-color: ${(p) => p.theme.colors.activeSearch};
    }
  }

  @media screen and (max-width: 600px) {
    width: 80px;
  }
`
export const ToggleButtonGroup = styled(_ToggleButtonGroup)`
  border: 1px solid ${(p) => p.theme.colors.activeSearch};
  max-height: 28px;
  overflow: hidden;

  & .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    border-left: none;
  }

  @media screen and (max-width: 600px) {
    margin-top: 15px;
  }
`
export const TableWrapper = styled.div`
  max-width: 650px;
  width: 100%;
  margin: 20px auto 0;
`
export const TableContainerStyled = styled(TableContainer)`
  overflow-x: unset;
  border-radius: unset;
  box-shadow: none;
  background: transparent;
  & td {
    border: none;
  }
  & th {
    border: none;
  }

  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }
`
export const TableStyledWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
export const TableStyled = styled(Table)`
  @media screen and (max-width: 600px) {
    width: 600px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const TableTitle = styled(TableCell)`
  font-size: 14px;
  line-height: 19px;
  color: ${(p) => p.theme.colors.secondary};
  padding: 15px;
  font-family: 'Open Sans', sans-serif;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 17px;
    padding: 8px 10px;
  }
`
export const Row = styled(TableRow)`
  min-height: 51px;
  background: transparent;

  @media screen and (max-width: 600px) {
    min-height: unset;
  }
`
export const Cell = styled(TableCell)`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  padding: 8px 15px;
  color: ${(p) =>
    p.address ? p.theme.colors.active : p.theme.colors.secondary};
  cursor: ${(p) => (p.address ? 'pointer' : 'text')};

  @media screen and (max-width: 600px) {
    padding: 6px 14px;
  }
  @media screen and (max-width: 448px) {
    padding: 0 14px;
  }
`
export const CoverButton = styled(Button)`
  border: 1px solid
    ${(p) => (p.delete ? p.theme.colors.error : p.theme.colors.buttonActiveBg)};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px;
  min-width: unset;
  margin-left: ${(p) => (p.delete ? '10px' : '0')};
  & svg {
    width: 15px;
    height: 15px;
  }
`
export const Checkmark = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
  border: 1px solid ${(p) => p.theme.colors.active};
  box-sizing: border-box;
  border-radius: 3px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8px;
    width: 8px;
    background: ${(p) => p.theme.colors.active};
    border-radius: 1px;
    opacity: ${(p) => (p.active ? '1' : '0')};
  }
`
export const Checkbox = styled.div`
  height: 35px;
  width: 35px;
  position: relative;
`
export const CopyButton = styled(Button)`
  border: 1px solid ${(p) => p.theme.colors.active};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px;
  max-height: 30px;
  color: ${(p) => p.theme.colors.active};
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  min-width: unset;
  margin-left: 10px;
`
export const Link = styled.a`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: ${(p) => p.theme.colors.active};
  text-decoration: none;
`
export const CopyTextWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const ActionWrapper = styled.div``

export const SkeletonContainer = styled(Skeleton)`
  width: 335px;
  height: 28px;

  text-decoration: none !important;
`
export const ConnectDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 15px;
`
export const ConnectDotAnimate = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: blur(1px);
  /* background: ${(p) => p.theme.colors.startedPulseAnimation}; */

  &::after {
    -webkit-animation: pulse 2s ease-in-out infinite both;
    animation: pulse 2s ease-in-out infinite both;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @-webkit-keyframes pulse {
      0% {
        background: ${(p) => p.theme.colors.primaryLightTrue};
        opacity: 0;
      }
      50% {
        background: ${(p) => p.theme.colors.primaryLightTrue};
        opacity: 1;
      }
      100% {
        background: ${(p) => p.theme.colors.primaryLightTrue};
        opacity: 0;
      }
    }
    @keyframes pulse {
      0% {
        background: ${(p) => p.theme.colors.primaryLightTrue};
        opacity: 0;
      }
      50% {
        background: ${(p) => p.theme.colors.primaryLightTrue};
        opacity: 1;
      }
      100% {
        background: ${(p) => p.theme.colors.primaryLightTrue};
        opacity: 0;
      }
    }
  }
`
export const ConnectWrapper = styled.div`
  border: 1px solid
    ${(p) =>
      p.connected
        ? p.theme.colors.strongDefault
        : p.theme.colors.primaryLightTrue};
  border-radius: 16px;
  margin-top: 18px;
  padding: 20px 30px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`
export const SignWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const ConnectIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  & svg {
    height: 20px;
    width: 20px;
  }
`
export const Dot = styled.div`
  background: ${(p) =>
    p.active ? p.theme.colors.primaryLightTrue : p.theme.colors.disabledText};
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin: 5px 0 10px;
`
export const StepWrapper = styled(Stepper)`
  background: transparent;
  padding: 0 0 13px;

  & .MuiStepLabel-label {
    margin-top: 0;
    font-size: 12px;
    color: ${(p) => p.theme.colors.disabledText};
    font-weight: normal;
  }
  & .MuiStepLabel-label.MuiStepLabel-active {
    color: ${(p) => p.theme.colors.primaryDefault};
    font-weight: bold;
  }

  @media screen and (max-width: 548px) {
    & .MuiStepLabel-label {
      font-size: 11px;
    }
  }
`
export const Check = styled.div`
  & svg {
    height: 24px;
    width: 24px;
  }
`
