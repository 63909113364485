import React from 'react'

const DiscordIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7729 18.982C16.7729 18.982 16.156 18.2077 15.6418 17.5414C17.8869 16.8752 18.7438 15.4166 18.7438 15.4166C18.0411 15.9028 17.3728 16.2449 16.7729 16.479C15.916 16.8572 15.0934 17.0913 14.2879 17.2533C12.6427 17.5774 11.1345 17.4874 9.84919 17.2353C8.87232 17.0372 8.03256 16.7671 7.32991 16.461C6.93573 16.2989 6.50728 16.1009 6.07883 15.8488C6.05313 15.8308 6.02742 15.8173 6.00171 15.8038C5.97601 15.7903 5.95029 15.7767 5.92459 15.7587C5.9115 15.7519 5.90091 15.745 5.89186 15.7391C5.87723 15.7296 5.86663 15.7227 5.85604 15.7227C5.54756 15.5427 5.37618 15.4166 5.37618 15.4166C5.37618 15.4166 6.1988 16.8392 8.37532 17.5234C7.86118 18.2077 7.22708 19 7.22708 19C3.43959 18.874 2 16.2809 2 16.2809C2 10.5367 4.46787 5.87288 4.46787 5.87288C6.93573 3.94613 9.26649 4.00015 9.26649 4.00015L9.43787 4.21624C6.35304 5.13459 4.94773 6.55715 4.94773 6.55715C4.94773 6.55715 5.32476 6.34106 5.95887 6.05295C7.79263 5.20662 9.24936 4.99054 9.84919 4.91851L9.88762 4.91168C9.97406 4.89622 10.0508 4.8825 10.1405 4.8825C11.1859 4.73844 12.3685 4.70243 13.6024 4.84648C15.2305 5.04456 16.9786 5.54876 18.7609 6.55715C18.7609 6.55715 17.407 5.20662 14.4936 4.28827L14.7335 4.00015C14.7335 4.00015 17.0814 3.94613 19.5321 5.87288C19.5321 5.87288 22 10.5367 22 16.2809C22 16.2809 21.9989 16.282 21.9965 16.2855L21.9879 16.299C21.8421 16.5289 20.3612 18.8626 16.7729 18.982ZM13.3111 12.6255C13.3111 11.5271 14.0823 10.6448 15.0591 10.6448C16.0189 10.6448 16.8072 11.5271 16.8072 12.6255C16.8072 13.724 16.036 14.6063 15.0591 14.6063C14.0994 14.6063 13.3111 13.724 13.3111 12.6255ZM7.05572 12.6255C7.05572 11.5271 7.82692 10.6448 8.80379 10.6448C9.78065 10.6448 10.569 11.5271 10.5519 12.6255C10.5519 13.724 9.78065 14.6063 8.80379 14.6063C7.84406 14.6063 7.05572 13.724 7.05572 12.6255Z"
        fill={color}
      />
    </svg>
  )
}

export default DiscordIcon
