import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import React from 'react'
import BoxTitle from '../../../components/shared/boxTitle/BoxTitle'
import Text from '../../../components/shared/text/Text'
import Button from '../../../components/shared/button/Button'
import { commas } from '../../../helpers'
import { CancelButton } from '../../protect/modalStyled'
import moment from 'moment'
import { fromWei } from 'web3-utils'

const WithdrawConfirmationModal = ({
  theme,
  t,
  withdrawRequest,
  amountToWithdraw,
  onCancelModal,
  onWithdraw,
}) => {
  const { colors } = theme

  const hasPendingWithdrawal = () => {
    return parseFloat(withdrawRequest.amount) > 0
  }

  const isReadyToWithdraw = () => {
    if (withdrawRequest.time === '0') return false

    return moment.unix(withdrawRequest.time).utc().unix() < moment.utc().unix()
  }

  const dateTimeOfWithdraw = () =>
    moment.unix(withdrawRequest.time).utc().format('MMM Do [at] HH:mm [UTC]')

  return (
    <Content>
      <BoxTitle text={'You are about to withdraw:'} />
      {(!hasPendingWithdrawal() || isReadyToWithdraw()) && (
        <ValueContent>
          <Text
            text={`${
              !hasPendingWithdrawal()
                ? commas(2).format(amountToWithdraw)
                : commas(2).format(
                    parseFloat(fromWei(withdrawRequest.amount, 'ether'))
                  )
            } Armor`}
            size="lg"
            isBold={true}
            color={colors.primaryLightTrue}
          />
        </ValueContent>
      )}

      <OptionWrapper>
        <OptionContent>
          <OptionText>
            {hasPendingWithdrawal() && !isReadyToWithdraw() && (
              <div>
                <div>
                  <Text
                    text={`Only one withdrawal may be pending at a time.`}
                    isBold={false}
                    color={colors.disabledText}
                  />
                </div>
                <div style={{ marginTop: 15 }}>
                  <Text
                    text={`You currently have a pending withdraw request initiated on ${dateTimeOfWithdraw()} for ${fromWei(
                      withdrawRequest.amount,
                      'ether'
                    )} Armor`}
                    isBold={false}
                    color={colors.disabledText}
                  />
                </div>
              </div>
            )}

            {!hasPendingWithdrawal() && (
              <Text
                text={
                  <>
                    vArmor to Armor withdrawals are subject to a 7 day delay.
                    After this period you can come back to this page to finalize
                    the withdrawal.
                  </>
                }
                color={colors.secondaryDefault}
              />
            )}
          </OptionText>

          {!hasPendingWithdrawal() && (
            <Button
              content="WITHDRAW WITH 7 DAY DELAY"
              disabled={hasPendingWithdrawal()}
              onClick={onWithdraw}
            />
          )}
        </OptionContent>
      </OptionWrapper>
      <CancelButton onClick={onCancelModal}>
        {t('ConfirmCoverModal.Cancel')}
      </CancelButton>
    </Content>
  )
}

export const Content = styled.div`
  padding: 21px 10px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`
export const ValueContent = styled.div`
  margin-top: 24px;
`
export const OptionWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  width: 100%;
  margin-top: 15px;

  @media screen and (max-width: 548px) {
    flex-direction: column;
    margin-top: 0;
  }
`
export const Divider = styled.div`
  width: 1px;
  background: ${(p) => p.theme.colors.disabledText};

  @media screen and (max-width: 548px) {
    display: none;
  }
`
export const OptionContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
  width: 100%;

  @media screen and (max-width: 548px) {
    margin-top: 25px;
    max-width: 100%;
  }
`
export const OptionText = styled.div`
  max-width: 350px;
  width: 100%;
  margin: 15px auto 20px;
`
export const Bold = styled.span`
  font-weight: bold;
`

export default withTranslation()(withRouter(WithdrawConfirmationModal))
