import React, { useState } from 'react'
import { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import CheckCircleIcon from '../../components/icons/CheckCircleIcon'
import {
  Wrapper,
  Container,
  Title,
  Description,
  Box,
  ContentContainer,
  AnalyzingBox,
  CenterContent,
  AnalizingText,
} from './styled'
import MailgunService from '../../classes/mailgun'
import StepWizard from './StepWizard'

const ProofOfLoss = ({ t, theme, history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [analizing, setAnalizing] = useState(false)
  const { colors } = theme

  const handleSignUp = async (values) => {
    const { protocol, list, armorAddress } = values
    try {
      setIsLoading(true)

      const mg = new MailgunService(process.env.REACT_APP_MAILGUN_API_KEY)
      mg.send(
        'robert@armor.fi,harry@armor.fi,cojaxcash@outlook.com',
        '[C.R.E.A.M. 10/27] Proof of Loss Submission',
        `Protocol: ${protocol} -- Armor Address: ${armorAddress} -- ${JSON.stringify(
          list
        )}`,
        `<h1>Protocol: ${protocol}<br>Armor Address: ${armorAddress}<br>${JSON.stringify(
          list
        )}</h1>`
      )

      setAnalizing(true)
      setTimeout(() => history.push('/protect'), 5000)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  return (
    <Wrapper>
      <Container>
        <Title>{t('ProofOfLoss.Title')}</Title>
        <Description>
          To submit claims, we must first determine the amount of funds Armor
          users lost in the hack.
        </Description>
        {analizing ? (
          <AnalyzingBox>
            <CenterContent>
              <CheckCircleIcon color={colors.activeSearch} />
              <AnalizingText>{t('ProofOfLoss.AnalizingText')}</AnalizingText>
            </CenterContent>
          </AnalyzingBox>
        ) : (
          <>
            <Box>
              <ContentContainer>
                <StepWizard isLoading={isLoading} submit={handleSignUp} />
              </ContentContainer>
            </Box>
          </>
        )}
      </Container>
    </Wrapper>
  )
}

export default withTranslation()(withRouter(withTheme(ProofOfLoss)))
