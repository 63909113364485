import styled from 'styled-components'

export const Root = styled.div`
  flex: 1;
  max-width: 965px;
  width: 100%;
  margin: 0 auto;
  padding: 17px 15px 40px;
  @media screen and (max-width: 768px) {
    padding-top: 80px;
  }
`
export const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${(p) => p.theme.colors.secondary};
  margin-top: 38px;
  & a {
    color: ${(p) => p.theme.colors.secondary};
    text-decoration: underline;
  }
  @media screen and (max-width: 548px) {
    margin-top: 30px;
  }
`
