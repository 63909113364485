import React, { useEffect, useMemo, useState } from 'react'
import { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Store from '../../../stores/store'
import {
  ButtonText,
  Wrapper,
  CurrentRow,
  ActionButton,
  ActionContainer,
  Info,
} from './styled'
import {
  Column,
  ColumnHead,
  Row,
  NameContainer,
  LogoContainer,
  Logo,
  NameText,
  TextContent,
  InfoText,
} from '../styled'
import {
  eightDigitFormatter,
  removeEmitterListeners,
  threeDigitFormatter,
  turnOnEmitterListeners,
  twoDigitFormatter,
} from '../../../helpers'
import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  ERROR,
} from '../../../constants'
import StakeTokensModal from '../StakeTokensModal'
import UnStakeTokensModal from '../UnStakeTokensModal'
import ActionModal from '../../common/actionModal/ActionModal'

const store = Store.store
const emitter = Store.emitter
const dispatcher = Store.dispatcher

const BaseUtilizationFarming = ({
  theme,
  t,
  network,
  prefix,
  title,
  hideUsageApy = false,
  rewardManagerBalance,
  totalPendingRewards,
  onWithdraw,
}) => {
  const [account, setAccount] = useState(null)
  const [stakedArmor, setStakedArmor] = useState('0')
  const [apy, setApy] = useState('0')
  const [arNftApy, setArNftApy] = useState('0')
  const [rewardsOwed, setRewardsOwed] = useState('0')
  const [tokenBalance, setTokenBalance] = useState(0.0)
  const [isStakeTokensModalOpened, setIsStakeTokensModalOpened] =
    useState(false)
  const [isUnStakeTokensModalOpened, setIsUnStakeTokensModalOpened] =
    useState(false)
  const [modalText, setModalText] = useState('')
  const [isModalOpened, setIsModalOpened] = useState(false)
  const { colors } = theme

  function dispatchUpdateEvents() {
    dispatcher.dispatch({
      type: `${prefix}_Token.GetBalance`,
      content: {},
    })
    dispatcher.dispatch({
      type: `${prefix}_Farm.GetStakedArmor`,
      content: {},
    })
    dispatcher.dispatch({
      type: `${prefix}_Farm.GetRewardsOwed`,
      content: {},
    })
    dispatcher.dispatch({
      type: `${prefix}_Farm.GetLastReward`,
      content: {},
    })
    dispatcher.dispatch({
      type: `${prefix}_Farm.GetArnftApy`,
      content: {},
    })
  }

  const combinedRewardsBalance = useMemo(
    () => parseFloat(totalPendingRewards) + parseFloat(rewardManagerBalance),
    [totalPendingRewards, rewardManagerBalance]
  )

  useEffect(() => {
    const _account = store.getStore('account')
    setAccount(_account)

    if (_account && _account.address) {
      dispatchUpdateEvents()
    }

    let events = [
      [ERROR, errorReturned],
      [CONNECTION_CONNECTED, connectionConnected],
      [CONNECTION_DISCONNECTED, connectionDisconnected],
      [`${prefix}_Token.BalanceReturned`, tokenBalanceReturned],
      [`${prefix}_Farm.StakedArmorReturned`, stakedArmorReturned],
      [`${prefix}_Farm.RewardsOwedReturned`, rewardsOwedReturned],
      [`${prefix}_Farm.LastRewardReturned`, lastRewardReturned],
      [`${prefix}_Farm.ArnftApyReturned`, arnftApyReturned],
      [`${prefix}_Farm.ClaimRewardsCompleted`, claimRewardsCompleted],
    ]

    turnOnEmitterListeners(emitter, events)

    return () => {
      removeEmitterListeners(emitter, events)
    }
  }, [network])

  useEffect(() => {
    if (account && account.address) {
      let interval = null
      interval = setInterval(() => {
        dispatcher.dispatch({
          type: `${prefix}_Farm.GetRewardsOwed`,
          content: {},
        })
      }, 60000)
      return () => clearInterval(interval)
    }
  }, [account, network])

  const tokenBalanceReturned = () => {
    const _total = store.getStore(`${prefix}_Token_Balance`)
    setTokenBalance(_total)
  }

  const stakedArmorReturned = () => {
    const _total = store.getStore(`${prefix}_Farm_StakedArmor`)
    setStakedArmor(_total)
  }

  const rewardsOwedReturned = () => {
    const _total = store.getStore(`${prefix}_Farm_RewardsOwed`)
    setRewardsOwed(_total)
  }

  const arnftApyReturned = () => {
    const _total = store.getStore(`${prefix}_Farm_ArnftApy`)
    setArNftApy(_total)
  }

  const lastRewardReturned = () => {
    try {
      const { apy } = store.getStore(`${prefix}_Farm_LastReward`)
      setApy(apy)
    } catch (e) {}
  }

  const connectionConnected = async () => {
    const _account = store.getStore('account')
    setAccount(_account)
  }

  const connectionDisconnected = () => setAccount(null)

  const errorReturned = () => {
    setIsModalOpened(false)
  }

  const handleClaimRewards = () => {
    setModalText('Waiting to collect your ARMOR. This may take a few minutes.')
    setIsModalOpened(true)
    dispatcher.dispatch({
      type: `${prefix}_Farm.ClaimRewards`,
      content: {},
    })
  }

  const claimRewardsCompleted = () => {
    setModalText('')
    setIsModalOpened(false)
  }

  const handleStakeTokensModalSubmit = (data) => {
    dispatcher.dispatch({
      type: `${prefix}_Farm.Stake`,
      content: { amount: data.amount },
    })
  }

  const closeStakeTokensModal = () => {
    setIsStakeTokensModalOpened(false)
  }

  const handleUnStakeTokensModalSubmit = (data) => {
    dispatcher.dispatch({
      type: `${prefix}_Farm.Withdraw`,
      content: { amount: data.amount },
    })
  }

  const closeUnStakeTokensModal = () => {
    setIsUnStakeTokensModalOpened(false)
  }

  const showApy = (days) => {
    if (apy == null || Number.isNaN(apy) || apy == 0) {
      return 0
    }
    return twoDigitFormatter.format(apy / days)
  }

  const showArnftApy = (days) => {
    if (arNftApy == null || Number.isNaN(arNftApy) || arNftApy == 0) {
      return 0
    }
    return twoDigitFormatter.format(arNftApy / days)
  }

  return (
    <Wrapper>
      <Row>
        <Column>
          <ColumnHead>
            <NameContainer>
              <LogoContainer>
                <Logo
                  src={require(`../../../assets/armor-circle-logo.svg`)}
                  alt="contract icon"
                />
                <NameText>{title}</NameText>
              </LogoContainer>
            </NameContainer>
          </ColumnHead>

          <CurrentRow>
            <InfoText>
              APY:
              <TextContent>{showApy(1)}%</TextContent>
            </InfoText>
            <InfoText>
              {t('UtilizationFarming.ClaimableRewards')}:
              <TextContent active={parseFloat(rewardsOwed) > 0}>
                {threeDigitFormatter.format(rewardsOwed)}
              </TextContent>
            </InfoText>
          </CurrentRow>
          <ActionContainer>
            <ActionButton
              variant="contained"
              onClick={() => handleClaimRewards()}
            >
              <ButtonText>{t('Rewards.Item.ClaimRewards')}</ButtonText>
            </ActionButton>
          </ActionContainer>
        </Column>
        <Column>
          <ColumnHead>
            <NameContainer>
              <NameText noleft="true">
                {t('UtilizationFarming.UsageRewards')}
              </NameText>
            </NameContainer>
          </ColumnHead>
          <CurrentRow>
            {!hideUsageApy && (
              <InfoText>
                APY:
                <TextContent>{showArnftApy(1)}%</TextContent>
              </InfoText>
            )}
            <Info active={combinedRewardsBalance > 0}>
              {eightDigitFormatter.format(combinedRewardsBalance)} ETH
            </Info>
          </CurrentRow>
          <ActionContainer>
            <ActionButton
              variant="contained"
              onClick={() => onWithdraw(combinedRewardsBalance)}
              disabled={combinedRewardsBalance <= 0}
            >
              <ButtonText color={colors.primaryDefault}>
                {t('Stake.Withdraw')}
              </ButtonText>
            </ActionButton>
          </ActionContainer>
        </Column>
      </Row>

      <ActionModal
        closeModal={false}
        isModalOpened={isModalOpened}
        actionText={modalText}
      />
      <StakeTokensModal
        totalAssets={tokenBalance}
        closeModal={closeStakeTokensModal}
        handleSubmit={handleStakeTokensModalSubmit}
        isModalOpened={isStakeTokensModalOpened}
      />
      <UnStakeTokensModal
        totalAssets={stakedArmor}
        closeModal={closeUnStakeTokensModal}
        handleSubmit={handleUnStakeTokensModalSubmit}
        isModalOpened={isUnStakeTokensModalOpened}
      />
    </Wrapper>
  )
}

export default withTranslation()(withRouter(withTheme(BaseUtilizationFarming)))
